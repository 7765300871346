import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import eq from 'lodash/eq';
import Input from '../Input/Input';
import FileInput from '../FileInput/FileInput';
import RadioButtons from '../RadioButtons/RadioButtons';
import Checkbox from '../Checkbox/CheckboxBlock';
import Button from '../Button/Button';
import Select from '../Select/Select';
import NotificationTypes from './NotificationTypes';
import NotificationsModal from './NotificationsModal';
import Modal from '../Modals/Modal';
import useAnalytics from '../../hooks/useAnalytics';
import CourierNotificationSettings from './CourierNotificationSettings';

const NotificationSettingsForm = ({
  readOnly,
  orgHasBranches,
  orgIsBranch,
  orgIsConnectLowerTier,
  organizationNotificationSettings,
  path,
  method,
  authenticityToken,
  ETAcollection,
  connectEnabled,
  anyConnectWeb,
  userEmail,
  environment,
  isWillCallV2,
  showCourierSettings,
  courierNotificationSettings
}) => {
  const { trackEvent } = useAnalytics(environment);

  const formRef = useRef();
  const [logo, setLogo] = useState(organizationNotificationSettings?.logo?.url);
  const [removeLogo, setRemoveLogo] = useState('0');
  const [showETA, setShowETA] = useState(
    organizationNotificationSettings?.connect_eta_is_shown_to_recipients ??
      false
  );
  const [ETA, setETA] = useState(
    organizationNotificationSettings?.connect_eta_buffer_time_in_minutes ?? 15
  );
  const [showRealTimeDriverLocation, setShowRealTimeDriverLocation] = useState(
    organizationNotificationSettings?.show_connect_real_time_driver_location ??
      false
  );
  const [applyToBranches, setApplyToBranches] = useState(
    organizationNotificationSettings?.apply_to_branches ?? false
  );
  const [savingStatus, setSavingStatus] = useState('pending'); // ['pending', 'confirm', 'inProgress']
  const [
    showEtaWasUncheckedDuringPageVisit,
    setShowEtaWasUncheckedDuringPageVisit
  ] = useState(false);
  const [etaUpdatedChecked, setEtaUpdatedChecked] = useState(false);
  const onChangeEtaUpdated = newVal => {
    setEtaUpdatedChecked(newVal);
  };
  const [selectedNotificationType, setSelectedNotificationType] = useState(
    null
  );
  const [notificationTypes, setNotificationTypes] = useState([
    ...organizationNotificationSettings.notification_types
  ]);

  const handleEditTemplate = notificationType => {
    setSelectedNotificationType(notificationType);
  };

  const prefix = 'organization_notification_settings';

  const handleSubmit = event => {
    if (applyToBranches || orgIsBranch) {
      event.preventDefault();
      setSavingStatus('confirm');
      return;
    }

    setSavingStatus('inProgress');
  };

  const handleCancelSave = () => {
    setSavingStatus('pending');
  };

  const handleSave = () => {
    setSavingStatus('inProgress');
    formRef.current?.submit();
  };

  return (
    <div>
      <form
        ref={formRef}
        action={path}
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="_method" value={method} />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
        <Input
          id="recipient_facing_name"
          name={`${prefix}[recipient_facing_name]`}
          label="Organization Name"
          value={organizationNotificationSettings?.recipient_facing_name}
          disabled={readOnly}
          placeholder="Enter name here"
          className="max-w-sm"
        />
        <Input
          id="recipient_contact_phone_number"
          name={`${prefix}[recipient_contact_phone_number]`}
          label="Phone Number"
          value={
            organizationNotificationSettings?.recipient_contact_phone_number
          }
          disabled={readOnly}
          placeholder="Enter phone number here"
          className="max-w-sm js-mask-phone"
          type="tel"
        />
        <Input
          id="recipient_facing_web_address"
          name={`${prefix}[recipient_facing_web_address]`}
          value={organizationNotificationSettings?.recipient_facing_web_address}
          disabled={readOnly}
          placeholder="Enter your website address here"
          className="max-w-sm"
          label="Website Address"
          helpText="ex: https://www.example.com"
        />
        <div>
          <FileInput
            accept="image/png,image/jpeg"
            id="organization_notification_settings_logo"
            name={`${prefix}[logo]`}
            value={logo}
            onChange={file => {
              setRemoveLogo(file ? '0' : '1');
              setLogo(file);
            }}
            label="Logo"
            placeholderImage="uploadLogo"
            helpText={
              orgIsConnectLowerTier && (
                <p className="mb-10">
                  Your logo will only show for recipient notifications sent
                  through Dispatch Marketplace. To add logos on recipient
                  notifications for deliveries performed by your vehicles,
                  please{' '}
                  <a
                    href="/connect/settings"
                    onClick={() =>
                      trackEvent('Upgrade to Professional Tier clicked')
                    }
                  >
                    upgrade to Professional Tier.
                  </a>
                </p>
              )
            }
          />
          <input
            value={removeLogo}
            type="hidden"
            name={`${prefix}[remove_logo]`}
          />
          <RadioButtons
            name={`${prefix}[logo_alignment]`}
            value={organizationNotificationSettings.logo_alignment || 'left'}
            fieldSetClassName="flex flex-col mt-10"
            options={[
              {
                name: 'Left-aligned',
                id: 'logo_alignment_left',
                value: 'left'
              },
              {
                name: 'Center-aligned',
                id: 'logo_alignment_center',
                value: 'center'
              }
            ]}
          />
        </div>

        {connectEnabled && anyConnectWeb && (
          <>
            <hr />
            <div className="alert alert-info border-0 text-black">
              Customizations set below will not apply to orders sent to
              Marketplace.
            </div>
            <h4 id="eta-and-driver-location" className="mt-12">
              ETA and Driver Location
            </h4>
            <div>
              <input
                value="0"
                type="hidden"
                name={`${prefix}[connect_eta_is_shown_to_recipients]`}
              />
              <Checkbox
                id="organization_notification_settings_connect_eta_is_shown_to_recipients"
                name={`${prefix}[connect_eta_is_shown_to_recipients]`}
                label="Show estimated time of arrival (ETA)"
                checked={showETA}
                onChange={() => {
                  trackEvent('Show estimated time of arrival (ETA) clicked');
                  setShowETA(showETA => !showETA);
                  setShowEtaWasUncheckedDuringPageVisit(true);
                }}
                disabled={readOnly}
                value="1"
                helpText={
                  <p>
                    Shows delivery ETA in notifications and the public status
                    page.{' '}
                    <a
                      href="https://support.dispatchit.com/email-and-sms-notifications"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      What is the public status page?
                    </a>
                  </p>
                }
              />
              {showEtaWasUncheckedDuringPageVisit &&
                !showETA &&
                etaUpdatedChecked && (
                  <div className="bg-orange-100 mt-6 ml-10 p-6">
                    Unchecking this setting will disable the &ldquo;ETA
                    Updated&ldquo; notification.
                  </div>
                )}
              <div className="ml-10">
                <label
                  htmlFor="organization_notification_settings_connect_eta_buffer_time_in_minutes"
                  className="h4 text-base mt-8"
                >
                  Estimated time of arrival buffer time
                </label>
                <div className="max-w-sm">
                  <Select
                    id="organization_notification_settings_connect_eta_buffer_time_in_minutes"
                    name={`${prefix}[connect_eta_buffer_time_in_minutes]`}
                    value={ETA}
                    onChange={({ target: { value } }) => {
                      trackEvent(
                        'Estimated time of arrival buffer time clicked'
                      );
                      setETA(value);
                    }}
                    options={ETAcollection.map(eta => ({
                      id: eta[0],
                      name: eta[1],
                      disabled: false
                    }))}
                    disabled={readOnly || !showETA}
                  />
                </div>
                <p className="help-block mt-4">
                  Add buffer time to your estimated time of arrival to create a
                  delivery window that will be shown to your customers. Ex. If
                  estimated time of arrival is 2:30, with a 15 minute buffer
                  time, customer will see estimated time of arrival as 2:30 -
                  2:45.
                </p>
              </div>
              <div className="mt-8">
                <input
                  value="0"
                  type="hidden"
                  name={`${prefix}[show_connect_real_time_driver_location]`}
                />
                <Checkbox
                  id="organization_notification_settings_show_connect_real_time_driver_location"
                  name={`${prefix}[show_connect_real_time_driver_location]`}
                  label="Show real time driver location"
                  checked={showRealTimeDriverLocation}
                  onChange={() => {
                    trackEvent('Show real time driver location clicked');
                    setShowRealTimeDriverLocation(
                      showRealTimeDriverLocation => !showRealTimeDriverLocation
                    );
                  }}
                  disabled={readOnly}
                  value="1"
                  helpText={
                    <p>
                      Shows real time driver location on a map in the public
                      status page
                    </p>
                  }
                />
              </div>
            </div>
            <NotificationTypes
              orgIsConnectLowerTier={orgIsConnectLowerTier}
              showETA={showETA}
              prefix={prefix}
              onChangeEtaUpdated={onChangeEtaUpdated}
              notificationTypes={notificationTypes}
              setNotificationTypes={setNotificationTypes}
              deliveryFormat={organizationNotificationSettings.delivery_format}
              onEditTemplate={handleEditTemplate}
              environment={environment}
              isWillCallV2={isWillCallV2}
            />
            <div>
              <h5 className="uppercase text-xs mb-5 mt-10 text-gray-500">
                CUSTOMER NOTIFICATIONS
              </h5>
              <p className="text-base text-black mb-6">
                Click the button below to go to the Address Book to select which
                notifications are sent to each individual customer.
              </p>
              <Button
                style="default"
                href="/connect/address_book"
                onClick={() => trackEvent('Customize by Customer clicked')}
              >
                Customize by Customer
              </Button>
            </div>
          </>
        )}
        <hr />
        {orgHasBranches && (
          <div className="border-orange border-2 rounded p-6">
            <h4>Branch Notifications</h4>
            <p className="text-base text-black mb-6">
              This account can manage notifications for its branches. If
              &ldquo;Apply to all branches&rdquo; below is unchecked, these will{' '}
              <b>only apply to new branches</b>. To apply settings to existing
              branches, select &ldquo;Apply to all branches&rdquo;.
            </p>
            <div
              id="apply-to-saved-customers-wrapper"
              className="bg-gray-100 py-7 px-6"
            >
              <input
                value="0"
                type="hidden"
                name={`${prefix}[apply_to_branches]`}
              />
              <Checkbox
                id="organization_notification_settings_apply_to_branches"
                name={`${prefix}[apply_to_branches]`}
                label="Apply to all branches"
                checked={applyToBranches}
                onChange={() =>
                  setApplyToBranches(applyToBranches => !applyToBranches)
                }
                disabled={readOnly}
                value="1"
                helpText={
                  <p>
                    If selected, all existing branches will inherit notification
                    settings. This option does not reset customer specific
                    customizations.
                  </p>
                }
              />
            </div>
          </div>
        )}
        <NotificationsModal
          onClose={() => setSelectedNotificationType(null)}
          notificationSettings={organizationNotificationSettings}
          selectedNotificationType={selectedNotificationType}
          path={path}
          authenticityToken={authenticityToken}
          notificationTypes={notificationTypes}
          setNotificationTypes={setNotificationTypes}
          formValues={formRef?.current}
          userEmail={userEmail}
          logo={logo}
        />
        <Modal isOpen={eq(savingStatus, 'confirm')} onClose={handleCancelSave}>
          <div className="p-6">
            <h4 className="mb-10">
              {orgIsBranch
                ? 'Confirm Custom Notifications'
                : 'Apply to All Branches?'}
            </h4>
            <p className="text-base text-black mb-6">
              {orgIsBranch ? (
                <>
                  Notifications settings you are about to save will differ from
                  your Parent Organization.
                </>
              ) : (
                <>
                  If you continue, the settings will be applied to{' '}
                  <b>all existing branches</b>. Customer specific customizations
                  will not be reset.
                </>
              )}
            </p>
            <div className="flex justify-end">
              <button
                className="btn btn-default btn-secondary mr-6"
                onClick={handleCancelSave}
              >
                Cancel
              </button>
              <button
                className="btn btn-default btn-primary"
                onClick={handleSave}
              >
                Continue and Save
              </button>
            </div>
          </div>
        </Modal>
        {showCourierSettings && (
          <CourierNotificationSettings settings={courierNotificationSettings} />
        )}
        {!readOnly && (
          <button
            type="submit"
            className="btn btn-default btn-primary pl-12 pr-12 mt-8"
            disabled={eq(savingStatus, 'inProgress')}
            onClick={() =>
              trackEvent('Save All clicked (Org notification settings)')
            }
          >
            Save All
          </button>
        )}
      </form>
    </div>
  );
};

NotificationSettingsForm.propTypes = {
  readOnly: PropTypes.bool,
  orgHasBranches: PropTypes.bool,
  orgIsBranch: PropTypes.bool,
  orgIsConnectLowerTier: PropTypes.bool,
  organizationNotificationSettings: PropTypes.object,
  path: PropTypes.string,
  method: PropTypes.string,
  authenticityToken: PropTypes.string,
  ETAcollection: PropTypes.array,
  connectEnabled: PropTypes.bool,
  anyConnectWeb: PropTypes.bool,
  userEmail: PropTypes.string,
  environment: PropTypes.string,
  isWillCallV2: PropTypes.bool,
  showCourierSettings: PropTypes.bool,
  courierNotificationSettings: PropTypes.object
};

export default NotificationSettingsForm;
