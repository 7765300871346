import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../src/components/Icon';
import FormRow from '../src/components/FormRow/FormRow';
import Select from '../src/components/Select/Select';
import RadioButtons from '../src/components/RadioButtons/RadioButtons';

const REASON_REQUIRED_ERROR = 'Please select a cancellation reason';

const COMMENT_REQUIRED_REASONS = ['issue_with_driver', 'other'];
const COMMENT_REQUIRED_ERROR = 'Comments are required';

const OrderCancellationForm = ({
  activeStatuses,
  authenticityToken,
  cancellationReasons,
  order
}) => {
  const otherReason = cancellationReasons.find(
    reason => reason.reason === 'other'
  );
  const cancellationReasonsWithoutOther = cancellationReasons.filter(
    reason => reason.reason !== otherReason?.reason
  );
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [cancelStatus, setCancelStatus] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [comment, setComment] = useState('');
  const commentRef = useRef();

  const isCommentRequired = () =>
    COMMENT_REQUIRED_REASONS.some(reason => selectedReason === reason);

  const selectedReasonError = () => {
    if (!selectedReason) {
      return REASON_REQUIRED_ERROR;
    } else {
      return '';
    }
  };

  const commentError = () => {
    if (isCommentRequired() && !comment) {
      return COMMENT_REQUIRED_ERROR;
    } else {
      return '';
    }
  };

  const isFormValid = event => {
    if (selectedReasonError() || commentError()) return false;

    for (const el of event.target) {
      if (el.validity.valid === false) return false;
    }

    return true;
  };

  const handleSelectedReasonChange = e => {
    if (
      COMMENT_REQUIRED_REASONS.includes(e.target.value) &&
      commentRef.current
    ) {
      commentRef.current.focus();
    }

    setSelectedReason(e.target.value);
  };

  const handleCommentChange = e => {
    setComment(e.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    event.target.checkValidity();
    setIsSubmitted(true);

    if (!isFormValid(event)) {
      return;
    }

    const confirmed = window.confirm('Are you sure?');
    if (!confirmed) {
      return;
    }

    setLoading(true);
    setResponseError('');

    try {
      await fetch(`/orders/${order.id}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticityToken
        },
        body: JSON.stringify({
          order_cancellation: {
            cancel_status: cancelStatus,
            reason: selectedReason,
            comment
          }
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // Redirect to the order page
            window.location.href = `/orders/${order.id}`;
          } else {
            setResponseError(data.error_message);
            setLoading(false);
          }
        });
    } catch (error) {
      setResponseError('An error occurred. Please try again.');
      setLoading(false);
    }
  };

  const renderReasons = reasons => (
    <RadioButtons
      name="reason"
      value={selectedReason}
      onChange={handleSelectedReasonChange}
      options={reasons.map(reason => ({
        id: reason.reason,
        value: reason.reason,
        name: (
          <>
            {reason.name}
            {reason.description && (
              <>
                <br />
                <span className="help-block mt-[-2px] ml-10">
                  {reason.description}
                </span>
              </>
            )}
          </>
        ),
        className: 'block mb-6'
      }))}
    />
  );

  if (order.status === 'cancelled') {
    return (
      <div className="panel panel-danger">
        <div className="panel-body">
          <p className="text-danger">This order has already been cancelled.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="panel panel-danger">
      <div className="panel-body">
        {responseError && (
          <div className="alert alert-danger" role="alert">
            {responseError}
          </div>
        )}
        {order.editable && (
          <div>
            <div className="inline-block">
              <div
                id="edit-order-banner"
                className="flex items-center bg-[#F9F8F9] mb-8 p-4 font-semibold rounded"
              >
                <div className="text-navy mr-6">
                  <Icon name="clock" size={24} />
                </div>
                <div>
                  You can still edit the information on the order, such as
                  vehicle, addresses, or delivery time.{' '}
                  <a className="text-blue" href={`/orders/${order.id}/edit`}>
                    Edit Order
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit} noValidate>
          {isSubmitted && selectedReasonError() && (
            <div>
              <div className="inline-block bg-[#FFF3E6] mb-8 p-4 text-red font-semibold rounded">
                {selectedReasonError()}
              </div>
            </div>
          )}
          {order.status === 'delivered' && (
            <FormRow>
              <Select
                id="cancelStatus"
                name="cancelStatus"
                label="Set Correct Status"
                value={cancelStatus}
                onChange={e => setCancelStatus(e.target.value)}
                options={activeStatuses.map(status => ({
                  id: status[1],
                  name: status[0]
                }))}
                placeholder="Please select a status"
                helpText="Since this order has been delivered, please set status to when order should have been canceled. This will allow for correct cancellation fee to be applied."
                customErrorMessage="Status is required"
                required
              />
            </FormRow>
          )}
          <FormRow>
            <label className="mb-4" htmlFor="reasons">
              <abbr title="This field is required.">*</abbr> Cancellation Reason
            </label>
            <div className="md:flex mb-6">
              <div className="w-full md:w-1/2">
                {renderReasons(
                  [
                    ...cancellationReasonsWithoutOther.slice(
                      0,
                      cancellationReasonsWithoutOther.length / 2
                    ),
                    otherReason
                  ].filter(reason => Boolean(reason))
                )}
              </div>
              <div className="w-full md:w-1/2">
                {renderReasons(
                  cancellationReasonsWithoutOther.slice(
                    cancellationReasonsWithoutOther.length / 2
                  )
                )}
              </div>
            </div>
            <div className={isSubmitted && commentError() ? 'has-error' : ''}>
              <textarea
                ref={commentRef}
                className="form-control"
                id="comment"
                name="comment"
                value={comment}
                placeholder={`Comments${
                  isCommentRequired() ? '' : ' (Optional)'
                }`}
                onChange={handleCommentChange}
                rows={3}
              />
            </div>
            {isSubmitted && commentError() && (
              <div className="help-block text-red mb-0">{commentError()}</div>
            )}
          </FormRow>
          <FormRow>
            <div className="text-red font-semibold mb-4">
              Canceling an order cannot be undone
            </div>
            <button className="btn btn-danger" type="submit" disabled={loading}>
              Cancel Order
            </button>
          </FormRow>
        </form>
      </div>
    </div>
  );
};

OrderCancellationForm.propTypes = {
  activeStatuses: PropTypes.array.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  cancellationReasons: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired
  }).isRequired
};

export default OrderCancellationForm;
