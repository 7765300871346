import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useClickAway from '../src/hooks/useClickAway';
import Button from '../src/components/Button/Button';
import CloseThin from '../src/components/Icon/CloseThin';
import Textarea from '../src/components/Textarea/Textarea';
import Toast from '../src/components/Toast/Toast';
import FeedbackSelection from '../src/pages/CustomerReview/FeedbackSelection';
import RatingSelection from '../src/pages/CustomerReview/RatingSelection';
import {
  FEEDBACK_ORDER_CREATED,
  SUBHEADER_TEXTS
} from '../src/pages/CustomerReview/reviewFeedbackOptions';

const UserReviewsWidget = ({ token }) => {
  const formRef = useRef();
  const [isWidgetOpen, setIsWidgetOpen] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);
  const [rating, setRating] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [comment, setComment] = useState('');
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [toast, setToast] = useState(null);

  useEffect(() => {
    setFeedbackOptions([...(FEEDBACK_ORDER_CREATED[rating] || [])]);
    setFeedback([]);
    setComment('');
  }, [rating]);

  useClickAway(formRef, () => {
    setIsFormOpen(false);
  });

  const removePromptSurveyParam = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('prompt_survey');
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${params.toString()}`
    );
  };

  const getSubHeaderText = rating => SUBHEADER_TEXTS[rating] || '';

  const setSuccessToast = () => {
    setToast({
      id: 'review-submit-success',
      type: 'success',
      message: {
        content: 'Thank You From The Dispatch Team'
      }
    });
  };

  const setErrorToast = () => {
    setToast({
      id: 'review-submit-error',
      type: 'error',
      message: {
        content:
          'There was an error while submitting your feedback. Pleast try again later.'
      }
    });
  };

  const handleCloseWidget = () => {
    setIsWidgetOpen(false);
    removePromptSurveyParam();
  };

  const handleRatingChange = selectedRating => {
    if (rating === selectedRating) {
      setIsFormOpen(!isFormOpen);
      return;
    }

    setIsFormOpen(true);
    setRating(selectedRating);
  };

  const handleFeedbackChange = selectedFeedback => {
    setFeedback(selectedFeedback);
  };

  const handleCommentChange = event => {
    setComment(event.target.value);
  };

  const handleSubmitReview = () => {
    setIsSubmittingReview(true);

    const payload = {
      customer_review: {
        rating,
        comment,
        reasons: feedback
      },
      token
    };

    fetch('/customer_reviews', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(
            `Failed to create review. Server responded with ${response.status}`
          );
        }

        setSuccessToast();
        handleCloseWidget();
      })
      .catch(() => setErrorToast())
      .finally(() => setIsSubmittingReview(false));
  };

  const handleCloseToast = () => {
    setToast(null);
  };

  return (
    <>
      {isWidgetOpen && (
        <div
          className="bg-white bottom-9 fixed right-20 w-[343px] z-[1000]"
          style={{ boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.15)' }}
        >
          <div className="bg-navy font-bold p-5 relative text-center text-xl text-white">
            <button
              className="absolute right-3 top-3"
              onClick={handleCloseWidget}
            >
              <CloseThin fill="white" size={12} />
            </button>
            How would you rate your experience with Dispatch?
          </div>
          {!isFormOpen ? (
            <div className="pb-5 pt-6 px-2">
              <RatingSelection
                rating={rating}
                handleRatingChange={handleRatingChange}
              />
            </div>
          ) : (
            <div ref={formRef} className="pb-5 pt-6 px-2">
              <RatingSelection
                rating={rating}
                handleRatingChange={handleRatingChange}
              />
              <FeedbackSelection
                subHeaderText={getSubHeaderText(rating)}
                feedbackOptions={feedbackOptions}
                selectedFeedbacks={feedback}
                setSelectedFeedbacks={handleFeedbackChange}
              />
              <div className="m-auto mb-16 mt-8 w-[320px]">
                <Textarea
                  id="review-comment"
                  label="Addtional comments"
                  name="review-comment"
                  value={comment}
                  onChange={handleCommentChange}
                  rows={3}
                />
              </div>
              <Button
                size="full"
                style="primary"
                disabled={isSubmittingReview}
                onClick={handleSubmitReview}
              >
                Done
              </Button>
            </div>
          )}
        </div>
      )}
      {toast && <Toast toasts={[toast]} onClose={handleCloseToast} />}
    </>
  );
};

UserReviewsWidget.propTypes = {
  token: PropTypes.string.isRequired
};

export default UserReviewsWidget;
