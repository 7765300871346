import React from 'react';
import PropTypes from 'prop-types';
import { default as ConnectGoogleMap } from '../src/connect/components/GoogleMap/GoogleMap';

const colors = [
  '#003f5c',
  '#d45087',
  '#59025c',
  '#f95d6a',
  '#ff7c43',
  '#9fd300',
  '#2f4b7c',
  '#920056',
  '#d9361d',
  '#cda200',
  '#665191',
  '#ffa600',
  '#a05195',
  '#df6e00',
  '#be0041',
  '#0dff2b'
];

export default function DriverOrders({
  waypointsFromProps,
  googleApiKey,
  driverLocationFromProps
}) {
  // eslint-disable-next-line react/prop-types
  var waypoints = [];
  if (waypointsFromProps) {
    waypoints = waypointsFromProps.map(waypoint => {
      return { ...waypoint };
    });
  }

  var pins = waypoints.map(waypoint => {
    const { location_ids } = waypoint;
    var current_pin_obj = {
      key: Array.isArray(location_ids) ? location_ids[0] : location_ids,
      type: waypoint.stop_type,
      color: colors[0],
      lat: waypoint.lat,
      lng: waypoint.lng
    };

    if (waypoints.length === 1) {
      current_pin_obj.type = 'vehicle';
    }

    return current_pin_obj;
  });

  let directionsArr;
  // if multiple waypoints exist, the driver location needs to be fed to the ConnectGoogleMap component
  // as a seperate directions obj with suppressPolylines = true
  if (waypoints.length > 1) {
    directionsArr = [
      {
        key: 'driver_route',
        color: colors[0],
        suppressPolylines: false,
        pins: pins.slice(0, -1)
      },
      {
        key: 'driver_location',
        color: colors[0],
        suppressPolylines: true,
        pins: [
          {
            key: '',
            type: 'vehicle',
            color: colors[0],
            lat: driverLocationFromProps?.latitude,
            lng: driverLocationFromProps?.longitude
          }
        ]
      }
    ];
  } else {
    directionsArr = [
      {
        key: 'driver_route',
        color: colors[0],
        suppressPolylines: false,
        pins: pins
      }
    ];
  }

  return (
    <div>
      <div className="flex">
        <div className="container">
          <div className="embed-responsive embed-responsive-16by9">
            <div className="embed-responsive-item relative">
              <ConnectGoogleMap
                {...(driverLocationFromProps && {
                  center: {
                    lat: parseFloat(driverLocationFromProps.latitude),
                    lng: parseFloat(driverLocationFromProps.longitude)
                  }
                })}
                googleApiKey={googleApiKey}
                isForDriverLocationOnly={waypoints.length === 1 ? true : false}
                showTraffic={false}
                directions={directionsArr}
                driverOrders
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DriverOrders.propTypes = {
  googleApiKey: PropTypes.string,
  waypointsFromProps: PropTypes.arrayOf(PropTypes.object),
  driverLocationFromProps: PropTypes.object
};
