import React from 'react';
import PropTypes from 'prop-types';
import { getServiceTypeName } from '../src/utils';
import { getVehicleTypeFromCode } from '../src/utils';

const MarketplacePricingTable = props => {
  const { customerMultiplier, driverMultiplier, pricingData } = props;

  const getMultipiedValue = (key, value) => {
    if (key.includes('total')) {
      return Number(value) * customerMultiplier;
    }
    if (key.includes('driver')) {
      return Number(value) * driverMultiplier;
    }
  };

  const getTranslatedFeeKey = key => {
    switch (key) {
      case 'total_min_fare':
        return 'Customer Min Fare';
      case 'total_cost_per_mile':
        return 'Customer Per Mile';
      case 'driver_min_fare':
        return 'Driver Min Fare';
      case 'driver_cost_per_mile':
        return 'Driver Per Mile';
    }
  };

  return (
    <div style={{ maxWidth: '100px' }}>
      <table className="table">
        <thead>
          <tr>
            <th style={{ borderBottom: '3px solid #ddd' }}></th>
            {Object.keys(pricingData).map((key, index) => {
              return (
                <th
                  className="text-center"
                  colSpan={Object.keys(pricingData[key])?.length}
                  style={{
                    borderLeft: '1px solid #ddd',
                    borderBottom: '3px solid #ddd'
                  }}
                  key={index}
                >
                  {getServiceTypeName(key)}
                </th>
              );
            })}
          </tr>
          <tr>
            <th
              width="140px"
              className="text-right"
              style={{ borderBottom: '3px solid #ddd', paddingRight: '12px' }}
            >
              Costs affected by multipliers
            </th>
            {Object.keys(pricingData).map(sla => {
              return Object.keys(pricingData[sla]).map((key, index) => {
                return (
                  <th
                    key={index}
                    className="text-center"
                    style={
                      key === 'car'
                        ? {
                            borderLeft: '1px solid #ddd',
                            borderBottom: '3px solid #ddd'
                          }
                        : { borderBottom: '3px solid #ddd' }
                    }
                  >
                    {getVehicleTypeFromCode(key)}
                  </th>
                );
              });
            })}
          </tr>
        </thead>
        <tbody>
          {[
            'total_min_fare',
            'driver_min_fare',
            'total_cost_per_mile',
            'driver_cost_per_mile'
          ].map((key, index) => {
            return (
              <tr key={index}>
                <td
                  className="text-right"
                  style={{
                    borderBottom: '1px solid #ddd',
                    paddingRight: '12px',
                    minWidth: '160px'
                  }}
                >
                  {getTranslatedFeeKey(key)}
                  <br />
                  <span className="text-muted">base</span>
                </td>
                {Object.keys(pricingData).map(sla => {
                  return Object.keys(pricingData[sla]).map(vehicle => {
                    return (
                      <td
                        className="text-right"
                        key={vehicle}
                        style={
                          vehicle === 'car'
                            ? {
                                borderLeft: '1px solid #ddd',
                                borderBottom: '1px solid #ddd',
                                minWidth: '70px'
                              }
                            : {
                                borderBottom: '1px solid #ddd',
                                minWidth: '70px'
                              }
                        }
                      >
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(
                          customerMultiplier && driverMultiplier
                            ? getMultipiedValue(
                                key,
                                pricingData[sla][vehicle][key]
                              )
                            : Number(pricingData[sla][vehicle][key])
                        )}
                        <br />
                        <span className="text-muted">
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                          }).format(Number(pricingData[sla][vehicle][key]))}
                        </span>
                      </td>
                    );
                  });
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

MarketplacePricingTable.propTypes = {
  customerMultiplier: PropTypes.number,
  driverMultiplier: PropTypes.number,
  pricingData: PropTypes.object
};

export default MarketplacePricingTable;
