// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

const amount = 4000;
const description = 'Driver certification fee';

/* global StripeCheckout */

class DriverOnboardingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        'We need to collect money for your Dispatch Driver Certification',
      displayDisclaimer: true,
      displayRequestForPayment: true
    };

    this.setupPaymentHandler();
  }

  setupPaymentHandler = () => {
    this.paymentHandler = StripeCheckout.configure({
      key: this.props.stripeKey,
      image: 'https://files.stripe.com/files/f_live_XndFc7sd7sq6JEDmpYgsGjal',
      locale: 'auto',
      token: this.submitPayment,
      closed: this.handleCheckoutClose
    });
  };

  handleCheckoutClose = () => {
    if (!this.state.token) {
      this.setState({ isLoading: false });
    }
  };

  submitPayment = token => {
    this.setState({
      isLoading: true,
      token: token
    });

    fetch('/driver_onboarding/payments', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: token.id,
        amount: amount,
        applicant_uuid: this.props.applicantUuid
      })
    })
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          this.switchViewToProceed();
        } else {
          this.setState({ errors: json.error, isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ errors: 'Something went wrong', isLoading: false });
      });
  };

  switchViewToProceed = () => {
    this.setState({
      errors: undefined,
      isLoading: false,
      displayRequestForPayment: false,
      displayProceed: true,
      message: 'Thank you for your payment.'
    });
  };

  requestPayment = () => {
    this.setState({ isLoading: true });

    this.paymentHandler.open({
      name: 'Dispatch',
      description: description,
      amount: amount,
      email: this.props.email,
      zipCode: true
    });
  };

  renderButton() {
    if (this.state.isLoading) {
      return (
        <a href="#" className="btn btn-primary btn-lg" disabled="true">
          Loading...
        </a>
      );
    }

    if (this.state.displayRequestForPayment) {
      return (
        <a
          href="#"
          className="btn btn-primary btn-lg"
          onClick={this.requestPayment}
        >
          Pay $40 for Dispatch Driver Certification
        </a>
      );
    }

    if (this.state.displayProceed) {
      return (
        <a
          href={`https://www.fountain.com/portal/dispatch-llc/applicant_result/${this.props.applicantUuid}`}
          className="btn btn-primary btn-lg"
          disabled={this.state.isLoading}
        >
          Proceed to the Next Step →
        </a>
      );
    }
  }

  render() {
    return (
      <div>
        <h1 className="text-center">Driver Onboarding</h1>

        <h2 className="text-center lead">{this.state.message}</h2>

        <br />

        {this.state.errors && (
          <div className="text-center text-danger">{this.state.errors}</div>
        )}

        <br />

        <div className="text-center">{this.renderButton()}</div>

        <br />
        <br />

        {this.state.displayDisclaimer ? (
          <div>
            <p>
              To become an independent contractor on the Dispatch network, we
              require that you receive your Dispatch Driver Certification. To do
              this you must pass a motor vehicle and nationwide background
              check. We do this because our drivers are entering and interacting
              face to face with customers in their place of business. Our
              requirements to pass are the following:
            </p>
            <ul className="list-disc pl-10">
              <li>You must be 23 or older</li>
              <li>
                No more than 3 minor violations or 1 major violation in the last
                3 years
              </li>
              <li>No felonies in the last 10 years</li>
            </ul>
            <p>
              You will also receive online onboarding training and a new driver
              kit to complete your Dispatch Driver Certification.
            </p>
            <p>
              When you complete 10 jobs in your first 30 days, you will receive
              a $50 New Driver Bonus.
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DriverOnboardingForm;
