export const FEEDBACK_POOR_RATING = 'poor';
export const FEEDBACK_OKAY_RATING = 'okay';
export const FEEDBACK_GREAT_RATING = 'great';

export const FEEDBACK = {
  [`${FEEDBACK_POOR_RATING}`]: [
    'Driver unprofessional',
    'Late pickup',
    'Late delivery',
    'Did not follow instructions',
    'Too early for pickup',
    'Poor communication'
  ],
  [`${FEEDBACK_OKAY_RATING}`]: [
    'Driver not prepared',
    'Unable to find driver',
    'Driver not on time',
    "Vehicle didn't match",
    'Improper unload',
    'Outside business hours'
  ],
  [`${FEEDBACK_GREAT_RATING}`]: [
    'Excellent communication',
    'On time',
    'Followed instructions',
    'Delivered with care',
    'Above & beyond',
    'Respectful of property'
  ]
};

export const FEEDBACK_ORDER_CREATED = {
  [`${FEEDBACK_POOR_RATING}`]: [
    'Pricing confusion',
    'Lack of communication',
    'Issue not resolved',
    'Poor customer support',
    'Order cancelled',
    'Other'
  ],
  [`${FEEDBACK_OKAY_RATING}`]: [
    'Delivery unavailable',
    'Lack of features',
    'Delivery time changed',
    'Service unreliable',
    'Inaccurate tracking',
    'Other'
  ],
  [`${FEEDBACK_GREAT_RATING}`]: [
    'Excellent communication',
    'Great pricing',
    'Order form is easy to use',
    'Other',
    'Quick & Efficient',
    'Great customer support'
  ]
};

export const SUBHEADER_TEXTS = {
  [`${FEEDBACK_POOR_RATING}`]: 'Sorry to hear. What happened?',
  [`${FEEDBACK_OKAY_RATING}`]: "Just Okay. What could've gone better?",
  [`${FEEDBACK_GREAT_RATING}`]: 'Excellent! Tell us more.'
};
