import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import eq from 'lodash/eq';

const SMS_APPENDED_TEXT = `Track here: https://demo.dispatchit.com/connect/delivery/abc. Reply STOP to unsubscribe.`;

const SmsPreview = ({
  customSmsText,
  maxSmsLength,
  defaultSmsText,
  etaEnabled,
  organizationName,
  selectedNotificationType
}) => {
  const showDefaultPreviewSmsText =
    isEmpty(customSmsText) || eq(customSmsText, defaultSmsText);

  const SMS_PREVIEW_TEXT = {
    delivery_created: {
      custom: `ORDER CREATED: ${customSmsText}. ${SMS_APPENDED_TEXT}`,
      default: `ORDER CREATED: Your order from ${organizationName} has been created. ${SMS_APPENDED_TEXT}`
    },
    will_call_ready: {
      custom: `ORDER IS READY: ${customSmsText}. ${SMS_APPENDED_TEXT}`,
      default: `ORDER IS READY: Your order from ${organizationName} is ready for pickup. ${SMS_APPENDED_TEXT}`
    },
    out_for_delivery: {
      custom: `OUT FOR DELIVERY ${
        etaEnabled ? '1:47 PM - 2:02 PM' : ''
      }: ${customSmsText}. ${SMS_APPENDED_TEXT}`,
      default: `OUT FOR DELIVERY: Your delivery from ${organizationName}  is out for delivery ${
        etaEnabled ? 'and will arrive between 1:47 PM - 2:02 PM' : ''
      }. ${SMS_APPENDED_TEXT}`
    },
    eta_updated: {
      custom: `ETA UPDATED ${
        etaEnabled ? 'to 1:47 PM - 2:02 PM' : ''
      }: ${customSmsText}. ${SMS_APPENDED_TEXT}`,
      default: `ETA UPDATED: Your delivery ETA from ${organizationName}  has been updated ${
        etaEnabled ? 'to 1:47 PM - 2:02 PM' : ''
      }. ${SMS_APPENDED_TEXT}`
    },
    delivery_attempted: {
      custom: `DELIVERY ATTEMPTED ${
        etaEnabled ? 'at t 1:31 PM' : ''
      }:  ${customSmsText}, ${SMS_APPENDED_TEXT}`,
      default: `DELIVERY ATTEMPTED: A delivery from ${organizationName} was attempted ${
        etaEnabled ? 'at 1:31 PM' : ''
      }. ${SMS_APPENDED_TEXT}`
    },
    delivery_complete: {
      custom: `COMPLETED ${
        etaEnabled ? 'at 1:31 PM' : ''
      }: ${customSmsText}. ${SMS_APPENDED_TEXT}`,
      default: `COMPLETED: Your order from ${organizationName} was completed ${
        etaEnabled ? 'at 1:31 PM' : ''
      }. ${SMS_APPENDED_TEXT}`
    },
    delivery_cancelled: {
      custom: `CANCELED ${
        etaEnabled ? 'at 1:31 PM' : ''
      }: ${customSmsText}. ${SMS_APPENDED_TEXT}`,
      default: `CANCELED: Your order from ${organizationName} has been canceled ${
        etaEnabled ? 'at 1:31 PM' : ''
      }. ${SMS_APPENDED_TEXT}`
    }
  };

  return (
    <textarea
      name="SMS preview"
      maxLength={maxSmsLength}
      className="w-full h-[170px] border border-navyLight-300 py-4 px-3 focus:ring-0"
      value={
        SMS_PREVIEW_TEXT[selectedNotificationType]?.[
          showDefaultPreviewSmsText ? 'default' : 'custom'
        ]
      }
      disabled
    />
  );
};

SmsPreview.propTypes = {
  etaEnabled: PropTypes.bool,
  organizationName: PropTypes.string,
  selectedNotificationType: PropTypes.string,
  customSmsText: PropTypes.string,
  maxSmsLength: PropTypes.number,
  defaultSmsText: PropTypes.string
};

export default SmsPreview;
