import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

const EnableConnectWizardControls = ({
  backDisabled,
  nextDisabled,
  finishDisabled,
  onBack,
  onNext,
  onFinish
}) => {
  return (
    <div className="cx_l--margin-top-50">
      <div className="cx_l--height-36">
        <button
          className="cx_l--flex cx_l--flex-align-center cx_l--float-left cx_t cx_t--darkBlue cx_t--l cx_b--cursor-pointer cx_t--underline-on-hover"
          onClick={onBack}
          disabled={backDisabled}
        >
          <i className="cx_i cx_i--l cx_i--chevron-left-darkBlue" />
          <span className="cx_l--margin-left-6">Back</span>
        </button>
        {!isNil(onNext) && isNil(onFinish) && (
          <button
            className="cx_l--flex cx_l--flex-align-center cx_l--float-right cx_t cx_t--darkBlue cx_t--l cx_b--cursor-pointer cx_t--underline-on-hover"
            onClick={onNext}
            disabled={nextDisabled}
          >
            <span className="cx_l--margin-right-12">Next</span>
            <i className="cx_i cx_i--l cx_i--chevron-right-darkBlue" />
          </button>
        )}
        {!isNil(onFinish) && (
          <button
            className="cx_button cx_button--alpha cx_l--float-right"
            onClick={onFinish}
            disabled={finishDisabled}
          >
            Finish
          </button>
        )}
      </div>
    </div>
  );
};

EnableConnectWizardControls.defaultProps = {
  backDisabled: false,
  nextDisabled: false,
  finishDisabled: false,
  onNext: null,
  onFinish: null
};

EnableConnectWizardControls.propTypes = {
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  finishDisabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onFinish: PropTypes.func
};

export default EnableConnectWizardControls;
