import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../src/components/Button/Button';
import PricingCsvUpload from './PricingCsvUpload';
import Icon from '../src/components/Icon';

const PricingCsvUploadWrapper = props => {
  const {
    authenticityToken,
    currentRates,
    organizationId,
    customPricingFlag,
    pricingConfigs,
    canUpload,
    allowSelectToday
  } = props;

  const [showUploadBasePricing, setShowUploadBasePricing] = useState(false);
  const [
    showUploadMarketMultipliers,
    setShowUploadMarketMultipliers
  ] = useState(false);

  const [showBasePricingWarnings, setShowBasePricingWarnings] = useState(false);
  const [
    showMarketMultipliersWarnings,
    setShowMarketMultipliersWarnings
  ] = useState(false);

  const handleUploadBasePricing = () => {
    setShowUploadBasePricing(!showUploadBasePricing);
    setShowUploadMarketMultipliers(false);
  };

  const handleUploadMarketMultipliers = () => {
    setShowUploadMarketMultipliers(!showUploadMarketMultipliers);
    setShowUploadBasePricing(false);
  };

  const [activeOption, setActiveOption] = useState('');

  const pricingContractExists = pricingConfigs && pricingConfigs.length > 0;

  const handleOptionClick = option => {
    setActiveOption(option);
  };

  const pricingOptionsConfig = [
    {
      title: 'Contractual Pricing',
      helperText:
        'Define variables, such as mileage and weight and associate a flat rate with each variable.',
      option: 'contractual',
      csvType: 'contractual',
      handleWarnings: setShowBasePricingWarnings,
      isEnabled: canUpload,
      useFileSearch: true
    },
    {
      title: 'Contractual Multipliers',
      helperText:
        "Define regions as tiers and associate multipliers with each tier. This pricing cannot be activated without 'Contractual Pricing', and it will share the same first and last date.",
      option: 'multipliers',
      csvType: 'contractual_multipliers',
      handleWarnings: setShowMarketMultipliersWarnings,
      isEnabled: canUpload && pricingContractExists,
      useFileSearch: true
    }
  ];

  return (
    <div>
      {(showUploadBasePricing && showBasePricingWarnings) ||
      (showUploadMarketMultipliers && showMarketMultipliersWarnings) ? (
        <div
          className="label-light-warning p-5 my-9 flex gap-5 items-center rounded-sm"
          style={{ color: '#B92500' }}
        >
          <Icon name="alertCircle" size={20} fill="none" />
          <div>
            Some of your pricing is above or below 5% of general pricing.
          </div>
        </div>
      ) : (
        ''
      )}
      {customPricingFlag ? (
        <div className="flex flex-wrap mb-6 pt-8">
          {pricingOptionsConfig.map(config => (
            <div key={config.option} className="w-full sm:w-1/2 md:w-1/4 p-2">
              <PricingOption
                title={config.title}
                helperText={config.helperText}
                onClick={() => handleOptionClick(config.option)}
                isActive={activeOption === config.option}
                isEnabled={config.isEnabled}
              >
                <PricingCsvUpload
                  authenticityToken={authenticityToken}
                  csvType={config.csvType}
                  currentRates={config.currentRates}
                  handleWarnings={config.handleWarnings}
                  organizationId={organizationId}
                  isActive={activeOption === config.option}
                  useFileSearch
                  setActiveOption={setActiveOption}
                  pricingConfigs={pricingConfigs}
                  allowSelectToday={allowSelectToday}
                />
              </PricingOption>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div
            className="border-bottom alert-warning"
            id="warnings"
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              marginBottom: '15px'
            }}
          />
          <div>
            <div className="flex gap-3">
              <Button onClick={handleUploadBasePricing}>
                Upload Base Pricing
              </Button>
              <Button onClick={handleUploadMarketMultipliers}>
                Upload Market Multipliers
              </Button>
            </div>
            <div className={showUploadBasePricing ? 'mt-5' : 'hidden mt-5'}>
              <PricingCsvUpload
                authenticityToken={authenticityToken}
                csvType="rates"
                handleWarnings={setShowBasePricingWarnings}
                organizationId={organizationId}
                allowSelectToday={allowSelectToday}
              />
            </div>
            <div
              className={showUploadMarketMultipliers ? 'mt-5' : 'hidden mt-5'}
            >
              <PricingCsvUpload
                authenticityToken={authenticityToken}
                csvType="multipliers"
                currentRates={currentRates}
                handleWarnings={setShowMarketMultipliersWarnings}
                organizationId={organizationId}
                allowSelectToday={allowSelectToday}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

PricingCsvUploadWrapper.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  currentRates: PropTypes.object,
  organizationId: PropTypes.string,
  customPricingFlag: PropTypes.bool,
  pricingConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      effective_at: PropTypes.string.isRequired,
      expires_at: PropTypes.string,
      pricing_change_type: PropTypes.string.isRequired,
      changed_by_user: PropTypes.shape({
        name: PropTypes.string
      }),
      pricing_change_id: PropTypes.string
    })
  ),
  canUpload: PropTypes.bool,
  allowSelectToday: PropTypes.bool
};

export default PricingCsvUploadWrapper;

const PricingOption = ({
  title,
  helperText,
  onClick,
  isActive,
  isEnabled,
  children
}) => {
  const handleKeyDown = event => {
    if (isEnabled && (event.key === 'Enter' || event.key === ' ')) {
      onClick();
    }
  };

  const containerClasses = [
    'p-4',
    'rounded-md',
    'cursor-pointer',
    'transition-all',
    'duration-200',
    'min-h-[200px]',
    isEnabled ? '' : 'bg-gray-200 border border-gray-200',
    isActive ? 'border-4 border-blue-500' : 'border border-gray-200'
  ].join(' ');

  const titleClasses = [
    'p-3',
    'text-base',
    isEnabled ? 'text-blue-500' : 'text-gray-800'
  ].join(' ');

  return (
    <div
      className={containerClasses}
      role="button"
      tabIndex={0}
      onClick={isEnabled ? onClick : undefined}
      onKeyDown={handleKeyDown}
      aria-pressed={isActive}
      aria-disabled={isEnabled}
    >
      <div>
        <h4 className={titleClasses}>{title}</h4>
        <p className={'p-3 leading-snug text-sm text-gray-800'}>{helperText}</p>
      </div>
      {isActive && isEnabled && (
        <div className="border-t p-3 pt-5">{children}</div>
      )}
    </div>
  );
};

PricingOption.propTypes = {
  title: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool,
  children: PropTypes.node
};

PricingOption.defaultProps = {
  isEnabled: true
};
