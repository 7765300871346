import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import eq from 'lodash/eq';
import truncate from 'lodash/truncate';
import includes from 'lodash/includes';
import EnableConnectWizardControls from './EnableConnectWizardControls';

const EnableConnectWizardStep3 = ({
  users,
  backDisabled,
  nextDisabled,
  onBack,
  onNext
}) => {
  const roles = [
    'connect_web_admin',
    'connect_web',
    'connect_web_read_only',
    'connect_driver'
  ];

  return (
    <>
      <table className="cx_table cx_table--alpha cx_table--border-collapse">
        <colgroup>
          <col style={{ width: '14%' }} />
          <col style={{ width: '22%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '18%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '16%' }} />
          <col style={{ width: '8%' }} />
        </colgroup>
        <thead className="cx_table__head">
          <tr className="cx_table__row">
            <th className="cx_table__heading cx_l--padding-left-16"></th>
            <th className="cx_table__heading">Email</th>
            <th className="cx_table__heading">Name</th>
            <th className="cx_table__heading cx_t--text-align-center">
              Web App Administrator
            </th>
            <th className="cx_table__heading cx_t--text-align-center">
              Web App User
            </th>
            <th className="cx_table__heading cx_t--text-align-center">
              Web App Read Only
            </th>
            <th className="cx_table__heading cx_t--text-align-center">
              Driver
            </th>
          </tr>
        </thead>
        <tbody className="cx_table__body cx_c--border-lightGray">
          {map(users, (user, index) => (
            <tr
              key={user.id}
              className={`cx_table__row cx_table__row--border-none ${
                eq(index % 2, 0) ? 'cx_c--background-color-lightestGray' : ''
              }`}
            >
              <td className="cx_table__column">
                <a
                  className="cx_button cx_button--beta cx_button--i cx_i cx_i--masquerade cx_l--width-90"
                  data-method="post"
                  href={`/admin/masquerade/${user.id}?connect_settings=true`}
                >
                  Masquerade
                </a>
              </td>
              <td className="cx_table__column">
                <span className="cx_t cx_t--xs">
                  {truncate(user.email, { length: 35 })}
                </span>
              </td>
              <td className="cx_table__column">
                <span className="cx_t cx_t--xs">
                  {truncate(user.given_name, { length: 15 })}{' '}
                  {truncate(user.family_name, { length: 15 })}
                </span>
              </td>
              {map(roles, role => (
                <td
                  key={role}
                  className="cx_table__column cx_t--text-align-center cx_l--padding-right-0"
                >
                  <div className="cx_field cx_field--checkbox cx_field--checkbox-l cx_l--width-auto">
                    <input
                      className="cx_field__input"
                      id={`user-${user.id}-${role}`}
                      name={`user-${user.id}-${role}`}
                      type="checkbox"
                      checked={includes(user.connect_roles, role)}
                      disabled={true}
                    />
                    <label
                      className="cx_field__label"
                      htmlFor={`user-${user.id}-${role}`}
                    >
                      {' '}
                    </label>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <EnableConnectWizardControls
        backDisabled={backDisabled}
        finishDisabled={nextDisabled}
        onBack={onBack}
        onFinish={onNext}
      />
    </>
  );
};

EnableConnectWizardStep3.defaultProps = {
  users: null,
  backDisabled: false,
  nextDisabled: false
};

EnableConnectWizardStep3.propTypes = {
  users: PropTypes.array,
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default EnableConnectWizardStep3;
