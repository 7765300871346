import React, { useEffect, useState } from 'react';
import { PostHogProvider, useFeatureFlagVariantKey } from 'posthog-js/react';
import PropTypes from 'prop-types';
import Spinner from '../src/connect/components/Spinners/Spinner';

const Redirector = ({
  featureFlag,
  enabledUrl,
  disabledUrl,
  fallbackUrl,
  timeoutMs = 2000,
  showSpinner = false,
  spinnerDelayMs = 0
}) => {
  const [displaySpinner, setDisplaySpinner] = useState(false);
  useEffect(() => {
    let spinnerTimeoutId;
    if (showSpinner) {
      spinnerTimeoutId = setTimeout(() => {
        setDisplaySpinner(true);
      }, spinnerDelayMs);
    }
    return () => clearTimeout(spinnerTimeoutId);
  }, [showSpinner, spinnerDelayMs]);

  const variant = useFeatureFlagVariantKey(featureFlag);
  useEffect(() => {
    const handleRedirect = url => {
      if (window.location.href !== url) {
        window.location.href = url;
      }
    };

    const timeoutId = setTimeout(() => {
      handleRedirect(fallbackUrl || disabledUrl);
    }, [timeoutMs]);

    if (variant !== undefined) {
      clearTimeout(timeoutId);
      const redirectUrl = variant === 'test' ? enabledUrl : disabledUrl;
      handleRedirect(redirectUrl);
    }

    return () => clearTimeout(timeoutId);
  }, [variant]);

  return (
    <div>
      {displaySpinner && (
        <Spinner
          size={70}
          containerClassName="cx_l--position-relative cx_l--flex cx_l--flex-justify-center cx_l--margin-bottom-36"
        />
      )}
    </div>
  );
};

const FeatureFlagRedirector = props => {
  return (
    <PostHogProvider client={window.posthog}>
      <Redirector {...props} />
    </PostHogProvider>
  );
};

const redirectorPropTypes = {
  featureFlag: PropTypes.string.isRequired,
  enabledUrl: PropTypes.string.isRequired,
  disabledUrl: PropTypes.string.isRequired,
  fallbackUrl: PropTypes.string,
  timeoutMs: PropTypes.number,
  showSpinner: PropTypes.bool,
  spinnerDelayMs: PropTypes.number
};

Redirector.propTypes = redirectorPropTypes;
FeatureFlagRedirector.propTypes = redirectorPropTypes;

export default FeatureFlagRedirector;
