import React from 'react';
import PropTypes from 'prop-types';
import eq from 'lodash/eq';
import map from 'lodash/map';

const RadioButtons = ({ name, value, disabled, options, onChange }) => {
  return map(options, option => (
    <label
      key={option.value}
      className={`cx_field--radio-button cx_t--400 ${
        disabled ? 'cx_field--radio-button-disabled' : ''
      }`}
      htmlFor={option.value}
    >
      {option.label}
      <input
        name={name}
        id={option.value}
        className="hidden"
        type="radio"
        value={option.value}
        defaultChecked={eq(value, option.value)}
        disabled={disabled}
        onChange={onChange}
      />
      <span className="cx_field--radio-checkmark"></span>
    </label>
  ));
};

RadioButtons.defaultProps = {
  disabled: false
};

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RadioButtons;
