import React from 'react';
import PropTypes from 'prop-types';

class CoverageAreaSlaPage extends React.Component {
  render() {
    return (
      <div>
        <h2>{this.props.coverageArea}</h2>
      </div>
    );
  }
}

CoverageAreaSlaPage.propTypes = {
  coverageArea: PropTypes.node
};

export default CoverageAreaSlaPage;
