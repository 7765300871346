import React, { useState } from 'react';
import Input from '../src/components/Input/Input';
import PropTypes from 'prop-types';

const CapabilityBundleDiscountFields = props => {
  const { discount_type, discount_amount } = props;

  const [discountType, setDiscountType] = useState(
    discount_type || 'flat_rate'
  );

  const [discountAmount, setDiscountAmount] = useState(discount_amount || 0);

  const [discountErrorMessage, setDiscountErrorMessage] = useState('');
  const [discountErrorVisible, setDiscountErrorVisible] = useState(false);

  const discountAmountLabel = discountType === 'flat_rate' ? '$' : '%';
  const discountAmountPlaceholder =
    discountType === 'flat_rate' ? '$0.00' : '0%';

  const handleDiscountAmountChange = event => {
    let submitButton = document.querySelector('input[name="commit"]');

    if (event.target.value > 0) {
      setDiscountErrorMessage('Discount amount must be negative');
      setDiscountErrorVisible(true);
      submitButton.disabled = true;
    } else {
      setDiscountAmount(event.target.value);
      submitButton.disabled = false;
    }
  };

  const handleDiscountTypeChange = event => {
    setDiscountType(event.target.value);
  };

  const discountAmountInputFieldName =
    discountType === 'flat_rate'
      ? 'capability_bundle[discount_amount]'
      : 'capability_bundle[discount_percentage]';

  return (
    <div>
      <div style={{ display: 'block' }}>
        <label>
          <input
            type="radio"
            name="capability_bundle[discount_type]"
            value="flat_rate"
            checked={discountType === 'flat_rate'}
            onChange={handleDiscountTypeChange}
          />
          Flat Rate
        </label>
      </div>
      <div style={{ display: 'block' }}>
        <label>
          <input
            type="radio"
            name="capability_bundle[discount_type]"
            value="percentage_of_total"
            checked={discountType === 'percentage_of_total'}
            onChange={handleDiscountTypeChange}
          />
          Percentage of Total
        </label>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor="discount-amount" style={{ marginRight: '10px' }}>
          {discountAmountLabel}
        </label>
        <Input
          id="discount-amount"
          name={discountAmountInputFieldName}
          placeholder={discountAmountPlaceholder}
          value={discountAmount}
          min="-10000"
          max="0"
          type="number"
          hasErrors={discountErrorVisible}
          customErrorMessage={discountErrorMessage}
          onChange={handleDiscountAmountChange}
        />
      </div>
    </div>
  );
};

CapabilityBundleDiscountFields.propTypes = {
  discount_amount: PropTypes.number,
  discount_type: PropTypes.string
};

export default CapabilityBundleDiscountFields;
