// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable react/prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import uuidv4 from 'uuid/v4';
import NumberFormat from 'react-number-format';

export default class RatingsReviewsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayStars: true,
      showQuestions: false,
      showCloseButton: true,
      thankYou: false,
      introAnimateWidget: false,
      outroAnimateWidget: false,
      starRating: 0,
      indexToShow: 0,
      qwests: [],
      starRefs: {},
      context: this.props.context || '',
      user_id: this.props.user_id || '',
      guid: this.props.review_guid || uuidv4()
    };

    if (this.props.context === 'from_email') {
      this.state.params = `&user_id=${this.state.user_id}&review_guid=${this.state.guid}`;
    } else {
      this.state.params = `&review_guid=${this.state.guid}`;
      this.instantiateReview();
    }
  }

  componentDidMount = () => {
    if (this.props.star_rating) {
      this.setState({ starRating: this.props.star_rating });
    }
  };

  instantiateReview = () => {
    this.postReview({
      id: this.props.order.id,
      body: {},
      params: '&submitted=false'
    });
  };

  closeRatingWidget = () => {
    setTimeout(() => {
      document.getElementById('order-ratings-widget').style.display = 'none';
    }, 300);
    this.setState({ introAnimateWidget: false, outroAnimateWidget: true });
    if (Boolean(document.getElementById('reveal-public-order-details'))) {
      document.getElementById('reveal-public-order-details').style.display =
        'block';
      document.getElementById('reveal-public-order-details').onclick = () =>
        (document.getElementById('public-order-rating-backdrop').style.display =
          'none');
    }
  };

  handleStarClick = stars => {
    this.setState({ starRating: stars });
    setTimeout(() => {
      this.setState({
        displayStars: false,
        introAnimateWidget: true,
        showQuestions: true,
        qwests: this.questionsToAsk(stars)
      });

      // post star rating, and save for partial review (unless it has been saved already)
      if (this.props.star_rating) {
        return;
      }
      this.postReview({
        id: this.props.order.id,
        body: { star_rating: stars },
        params: '&submitted=false'
      });
    }, 500);
  };

  postReview = opts => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    fetch(
      `/orders/${opts.id}/rating?context=${this.state.context}${opts.params}${this.state.params}`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(opts.body)
      }
    ).catch(
      // eslint-disable-next-line no-console
      err => console.log('err on post', err)
    );
  };

  questionsToAsk = stars => {
    stars = parseInt(stars);
    let qwests = [
      {
        question: "WHAT IS THE REASON WE HAVEN'T MET YOUR EXPECTATIONS?",
        subtext: 'WE WANT TO MAKE IT RIGHT.',
        id: 'expectations'
      },
      {
        question: 'HOW CAN WE CONTACT YOU?',
        // subtext: "please specifiy a phone or email for us to contact",
        id: 'contact'
      },
      {
        question:
          'WHAT COULD WE DO TO MAKE YOUR EXPERIENCE WITH DISPATCH BETTER NEXT TIME?',
        id: 'experience'
      }
    ];

    switch (stars) {
      case 1:
      case 2:
        return [
          qwests.find(x => x.id === 'expectations'),
          qwests.find(x => x.id === 'contact')
        ];
      case 3:
        return [
          qwests.find(x => x.id === 'expectations'),
          qwests.find(x => x.id === 'contact'),
          qwests.find(x => x.id === 'experience')
        ];
      default:
        return [qwests.find(x => x.id === 'experience')];
    }
  };

  renderQuestion = () => (
    <span
      className={
        'review-question-only review-question-only-' + this.state.indexToShow
      }
    >
      {this.state.qwests[this.state.indexToShow].question}
    </span>
  );

  renderSubtext = () => (
    <span style={{ fontSize: 0.8 + 'em', fontWeight: 'bold' }}>
      {this.state.qwests[this.state.indexToShow].subtext}
    </span>
  );

  handleInputChange = e =>
    this.setState({ ['answer_' + this.state.indexToShow]: e.target.value });

  handleContactInputChange = e => {
    let type = '';
    let format = '';

    if (e.target) {
      type = 'email';
      format = e.target.value;
    } else if (e.formattedValue) {
      type = 'phone';
      format = e.formattedValue;
    }

    this.setState({
      ['answer_' + this.state.indexToShow]: {
        ...this.state['answer_' + this.state.indexToShow],
        [type]: format
      }
    });
  };

  renderQuestionResponse = () => {
    return (
      <form action="" className="review-question">
        {this.renderSubtext()}
        {this.state.indexToShow === 1 ? (
          <span className="contact-input-container form-group">
            <label>phone:</label>
            <NumberFormat
              onValueChange={this.handleContactInputChange}
              format="(###) ###-####"
              type="tel"
              mask="_"
              className="review-contact-input review-question-input form-control"
            />
            <label style={{ marginTop: 5 }}>email:</label>
            <input
              onInput={this.handleContactInputChange}
              className="review-contact-input review-question-input form-control"
            />
          </span>
        ) : (
          <textarea
            onInput={this.handleInputChange}
            className="review-question-input form-control"
            rows="1"
          />
        )}
        <button
          className="btn btn-default next-question-button"
          onClick={e => this.nextQuestionButton(e)}
        >
          Next
        </button>
      </form>
    );
  };

  nextQuestionButton = e => {
    e.preventDefault();
    if (this.state.indexToShow + 1 === this.state.qwests.length) {
      this.setState({
        showQuestions: false,
        showCloseButton: false,
        thankYou: true
      });

      setTimeout(() => this.closeRatingWidget(), 1500);

      this.postReview({
        id: this.props.order.id,
        body: {
          star_rating: this.state.starRating,
          responses: this.state.qwests.map((q, i) => ({
            question: q,
            answer: this.state['answer_' + i]
          }))
        },
        params: '&submitted=true'
      });
      // post rating
      // hide review
    }

    if (this.state.indexToShow + 1 < this.state.qwests.length) {
      this.setState({
        indexToShow: this.state.indexToShow + 1
      });
    }
  };

  widgetAnimations = () => {
    let result =
      this.state.introAnimateWidget === false &&
      this.state.outroAnimateWidget === true
        ? 'rating-widget-container animate-shrink-review-container'
        : 'rating-widget-container';
    if (this.props.overlay) {
      return result + ' rating-widget-container-public-order';
    }
    return result;
  };

  render() {
    return (
      <div className={this.widgetAnimations()} id="order-ratings-widget">
        <div className="rating-widget-inner-one">
          {this.state.showCloseButton ? (
            <span
              onClick={() => this.closeRatingWidget()}
              className="btn btn-sm rating-widget-close-button glyphicon glyphicon-remove"
            />
          ) : null}
          {this.state.displayStars ? (
            <span className={'review-question-only review-question-only-og'}>
              HOW WOULD YOU RATE YOUR EXPERIENCE WITH DISPATCH?
            </span>
          ) : this.state.showQuestions ? (
            this.renderQuestion()
          ) : (
            ''
          )}
        </div>

        <div className="rating-widget-inner-two">
          {this.state.displayStars ? (
            <div className="rating-star-container">
              {[1, 2, 3, 4, 5].map(num => (
                <span
                  key={'rating-star-item-' + num}
                  className="rating-star-item-and-num"
                >
                  <a
                    onClick={() => this.handleStarClick(num)}
                    className={
                      'clickable-rating-star-item glyphicon glyphicon-star ' +
                      (this.state.starRating >= num
                        ? 'star-is-active'
                        : 'star-is-inactive')
                    }
                  />
                  <span style={{ fontSize: 1.5 + 'em' }}>{num}</span>
                </span>
              ))}
            </div>
          ) : null}

          {this.state.showQuestions ? this.renderQuestionResponse() : null}
          {this.props.star_rating && this.state.displayStars ? (
            <span
              className="btn btn-sm next-question-button next-button-for-star-rating"
              onClick={() => this.handleStarClick(this.props.star_rating)}
            >
              Next
            </span>
          ) : null}

          {this.state.thankYou ? (
            <div style={{ padding: 10 }}>
              <div id="review-complete-copy">Thanks for the feedback!</div>
              <div id="review-complete-copy-signoff"> - Team Dispatch</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
