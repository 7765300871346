import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import eq from 'lodash/eq';

const EmailEdit = ({
  customEmailText,
  onChangeTextArea,
  maxEmailLength,
  defaultEmailText,
  isEditedEmailText
}) => {
  const textAareaRef = useRef(null);

  useEffect(() => {
    textAareaRef.current.focus();
  }, []);

  const value = isEditedEmailText
    ? isEmpty(customEmailText)
      ? ''
      : eq(customEmailText, defaultEmailText)
      ? defaultEmailText
      : customEmailText
    : isEmpty(customEmailText) || eq(customEmailText, defaultEmailText)
    ? defaultEmailText
    : customEmailText;

  return (
    <textarea
      ref={textAareaRef}
      name="Email edit"
      maxLength={maxEmailLength}
      className="w-full h-[170px] border border-navyLight-300 py-4 px-3 focus:ring-0"
      onChange={onChangeTextArea}
      value={value}
    />
  );
};

EmailEdit.propTypes = {
  customEmailText: PropTypes.string,
  onChangeTextArea: PropTypes.func,
  maxEmailLength: PropTypes.number,
  defaultEmailText: PropTypes.string,
  isEditedEmailText: PropTypes.bool
};

export default EmailEdit;
