import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormRow from '../src/components/FormRow/FormRow';
import HiddenInput from '../src/components/HiddenInput/HiddenInput';
import OrganizationInput from '../src/components/OrganizationInput/OrganizationInput';
import BranchesInput from '../src/components/BranchesInput/BranchesInput';
import Button from '../src/components/Button/Button';
import LoadingSpinner from '../src/components/LoadingSpinner/LoadingSpinner';

const TransferOrganizationBranch = props => {
  const {
    authenticityToken,
    name,
    parentOrgId,
    parentOrgName,
    currentOrgId,
    currentOrgName
  } = props;

  const [orgId, setOrgId] = useState(parentOrgId?.toString());
  const [orgName, setOrgName] = useState(parentOrgName || '');
  const [branchId, setBranchId] = useState(null);
  const [branchName, setBranchName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orgTree, setOrgTree] = useState(null);
  // this changes when the clear button is clicked as an easy way to rerender the organization field
  const [orgInputKey, setOrgInputKey] = useState(0);

  const handleOrgChange = (id, name) => {
    setOrgId(id.toString());
    setOrgName(name);
  };

  const handleBranchChange = e => {
    setBranchId(e.target.value);
    // Gets the text inside the selected <option>
    setBranchName(e.target[e.target.selectedIndex]?.innerText);
  };

  const handleClearClick = () => {
    setOrgInputKey(orgInputKey + 1);
    setOrgId(parentOrgId?.toString());
    setOrgName(parentOrgName);
    setBranchId(null);
    setBranchName(null);
    setOrgTree(null);
  };

  useEffect(() => {
    if (!orgId) return;

    setIsLoading(true);
    fetch(`/organizations/${orgId}/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      credentials: 'include'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setOrgTree(data);
        setIsLoading(false);
      })
      .catch(() => {
        setOrgTree(null);
        setBranchId(null);
        setIsLoading(false);
      });
  }, [orgId]);

  return (
    <>
      <HiddenInput
        name={name ?? 'organization[parent_organization_id]'}
        value={branchId ? branchId : orgId}
      />

      <FormRow>
        <OrganizationInput
          id="organization-search"
          label="Select New Parent Organization"
          value={parentOrgName}
          key={orgInputKey}
          helpText={`${currentOrgName} and any related branches will move underneath this new parent organization. If you want to remove this branch from its parent select the "No Parent" option.`}
          onSelect={handleOrgChange}
          excludeOrgId={currentOrgId} // Exclude the current org so we can't make it a parent of itself
          required={false}
          removeParentOption={true}
        />
      </FormRow>
      {isLoading && (
        <div className="mb-8">
          <LoadingSpinner />
        </div>
      )}
      {orgTree && (
        <FormRow>
          <BranchesInput
            id="branch-id"
            name="multi_stop_order_form[branch]"
            label="Parent Group"
            placeholder="Select a group"
            value=""
            customErrorMessage="Please select a branch"
            onChange={handleBranchChange}
            tree={orgTree}
            disableBranches={true}
            allowBlank={true}
            excludeIds={[currentOrgId]}
          />
        </FormRow>
      )}
      {(parentOrgId?.toString() !== orgId || branchId) &&
        orgId?.toString() !== '0' && (
          <div className="mb-8">
            <div className="alert alert-info p-2 m-0">
              Saving this update will move {currentOrgName} as a branch{' '}
              {branchId && (
                <>
                  in the group <strong>{branchName.trim()}</strong>{' '}
                </>
              )}
              underneath <strong>{orgName.trim()}</strong>.
            </div>
          </div>
        )}
      {orgId !== parentOrgId?.toString() && (
        <Button onClick={handleClearClick}>Clear</Button>
      )}
    </>
  );
};

TransferOrganizationBranch.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  name: PropTypes.string,
  parentOrgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parentOrgName: PropTypes.string,
  currentOrgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentOrgName: PropTypes.string
};

export default TransferOrganizationBranch;
