// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import {
  GoogleMap,
  OverlayView,
  Polygon,
  withGoogleMap
} from 'react-google-maps';
import React from 'react';

const LoadableMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={6}
    defaultCenter={{ lat: 44.9778, lng: -93.265 }}
    defaultOptions={{ scaleControl: true }}
    ref={props.mapRef}
  >
    {props.children}
  </GoogleMap>
));

class CoverageAreaMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageAreas: props.coverageAreas
    };
  }

  componentDidMount() {
    let bounds = new google.maps.LatLngBounds();

    setTimeout(() => {
      this.focusCoverageAreas(this.coverageAreasToFocus());
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusedCoverageAreaId !== this.props.focusedCoverageAreaId) {
      this.focusCoverageAreas(this.coverageAreasToFocus());
    }
  }

  focusCoverageAreas(coverageAreas) {
    let bounds = new google.maps.LatLngBounds();
    coverageAreas.forEach(coverageArea => {
      const boundariesOrEmpty = coverageArea.coverage_area_boundaries || [];
      boundariesOrEmpty.forEach(boundaries => {
        bounds.extend(boundaries);
      });
    });
    this.map.fitBounds(bounds);
  }

  coverageAreasToFocus() {
    if (!this.props.focusedCoverageAreaId) {
      return this.state.coverageAreas;
    }

    const filtered = this.state.coverageAreas.filter(coverageArea => {
      // console.log(coverageArea, this.props.focusedCoverageAreaId);
      return coverageArea.coverage_area_id === this.props.focusedCoverageAreaId;
    });

    return filtered.length > 0 ? filtered : this.state.coverageAreas;
  }

  render() {
    return (
      <LoadableMap
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className="embed-responsive-item" />}
        mapElement={<div style={{ height: `100%` }} />}
        mapRef={map => {
          this.map = map;
        }}
      >
        {this.state.polygonVertexPendingDeletion ? (
          <OverlayView
            position={this.state.polygonVertexPendingDeletion.location}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <button
              className="btn btn-default btn-xs"
              onClick={() => {
                this.state.polygonVertexPendingDeletion.polygon
                  .getPath()
                  .removeAt(this.state.polygonVertexPendingDeletion.vertexId);
                this.setState({ polygonVertexPendingDeletion: null });
              }}
            >
              Delete point
            </button>
          </OverlayView>
        ) : null}

        {this.state.coverageAreas.map(coverageArea => {
          let polygonRef;

          const isEditable =
            this.props.focusedCoverageAreaId ===
              coverageArea.coverage_area_id &&
            this.props.editBoundariesForFocused;

          const polygonOptions = this.props.mapError
            ? {
                strokeWeight: 1,
                fillColor: 'red',
                fillOpacity: 0.2,
                strokeColor: 'red'
              }
            : {
                strokeWeight: 1,
                fillColor: coverageArea.enabled ? 'black' : '#9c9c9f',
                fillOpacity: 0.2,
                strokeColor: coverageArea.enabled ? 'black' : '#9c9c9f'
              };

          return (
            <Polygon
              ref={polygon => (polygonRef = polygon)}
              key={`polygon${coverageArea.coverage_area_id}`}
              editable={isEditable}
              visible={true}
              path={coverageArea.coverage_area_boundaries}
              options={polygonOptions}
              onMouseUp={() => {
                // don't track anything unless we're editing
                if (!isEditable) {
                  return;
                }

                setTimeout(() => {
                  const boundaries = polygonRef
                    .getPath()
                    .getArray()
                    .map(item => {
                      return { lat: item.lat(), lng: item.lng() };
                    });

                  this.props.onCoverageAreaBoundaryChange(boundaries);
                }, 100);
              }}
              onClick={e => {
                if (e.vertex !== undefined) {
                  this.setState({
                    polygonVertexPendingDeletion: {
                      vertexId: e.vertex,
                      location: e.latLng.toJSON(),
                      polygon: polygonRef
                    }
                  });
                } else {
                  // Kick this off asyncronously, otherwise this
                  // interferes with the delete click handler because it
                  // wipes away the data before it is able to be deleted.
                  setTimeout(() => {
                    this.setState({ polygonVertexPendingDeletion: null });
                  }, 20);
                }
              }}
            />
          );
        })}
      </LoadableMap>
    );
  }
}

export default CoverageAreaMap;
