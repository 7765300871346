import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import eq from 'lodash/eq';
import RadioButtons from '../RadioButtons/RadioButtons';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/CheckboxBlock';
import Icon from '../Icon';
import Tooltip from '../Tooltip/Tooltip';
import useAnalytics from '../../hooks/useAnalytics';
import { DELIVERY_FORMAT_OPTIONS } from '../../connect/constants';

const NotificationTypes = ({
  orgIsConnectLowerTier,
  showETA,
  prefix,
  onChangeEtaUpdated,
  onEditTemplate,
  notificationTypes,
  setNotificationTypes,
  deliveryFormat,
  environment,
  isWillCallV2
}) => {
  const { trackEvent } = useAnalytics(environment);

  const NOTIFICATION_TYPES = [
    {
      name: 'delivery_created',
      label: 'Order Created',
      description: 'Order created in the system'
    },
    {
      name: 'will_call_ready',
      label: 'Order Ready',
      description: 'Order is ready for pickup (Will Call only)'
    },
    {
      name: 'out_for_delivery',
      label: 'Out for Delivery',
      description: 'Driver is out to deliver the order'
    },
    {
      name: 'eta_updated',
      label: 'ETA Updated',
      description: 'Estimated time of arrival for the order has changed'
    },
    {
      name: 'delivery_attempted',
      label: 'Delivery Attempted',
      description: 'Driver tried to deliver the order but was unable to'
    },
    {
      name: 'delivery_complete',
      label: 'Order Complete',
      description: 'Order marked as delivered'
    },
    {
      name: 'delivery_cancelled',
      label: 'Order Canceled',
      description: 'Order canceled'
    }
  ].filter(
    notificationType =>
      notificationType.name !== 'will_call_ready' || isWillCallV2
  );

  const [applyToSavedCustomers, setApplyToSavedCustomers] = useState(false);

  useEffect(() => {
    onChangeEtaUpdated(findNotificationType('eta_updated')?.active);
  }, []);

  const findNotificationType = type =>
    find(notificationTypes, { notification_type: type });

  const handleNotificationTypeChange = type => {
    if (findNotificationType(type)) {
      const newNotificationTypes = map(notificationTypes, notificationType => {
        if (eq(notificationType.notification_type, type)) {
          const newValue = !notificationType.active;
          if (eq(type, 'eta_updated')) {
            onChangeEtaUpdated(newValue);
          }

          return {
            ...notificationType,
            active: newValue
          };
        }

        return notificationType;
      });
      setNotificationTypes(newNotificationTypes);
    } else {
      setNotificationTypes([
        ...notificationTypes,
        {
          notification_type: type,
          active: true
        }
      ]);
    }
  };

  return (
    <div id="notification-types" className="mt-10">
      <h4>Notification Types</h4>
      <h5 className="uppercase text-xs mb-5 text-gray-500">
        Global Notifications
      </h5>
      <p className="text-base text-black mb-6">
        Select notifications that you want to enable. When saved, these will
        <b> apply to new (unsaved) customers</b>. To apply to all, select
        &ldquo;Apply to saved customers&rdquo; below.
      </p>
      <div
        id="apply-to-saved-customers-wrapper"
        className="bg-gray-100 py-7 px-6 mb-9"
      >
        <Checkbox
          id="apply-to-saved-customers"
          name={`${prefix}[apply_to_saved_customers]`}
          label="Apply to saved customers"
          checked={applyToSavedCustomers}
          value="true"
          onChange={() => {
            trackEvent('Apply to saved customers clicked');
            setApplyToSavedCustomers(!applyToSavedCustomers);
          }}
          helpText={
            <p>
              If selected, all customer specific customizations will be reset to
              the selections made here
            </p>
          }
        />
      </div>
      <div id="delivery-format" className="flex gap-10 mb-8">
        <p className="text-base text-navy-dark font-semibold">
          Delivery Format:
        </p>
        <RadioButtons
          name={`${prefix}[delivery_format]`}
          value={deliveryFormat}
          fieldSetClassName="flex gap-10 font-normal"
          options={DELIVERY_FORMAT_OPTIONS}
        />
      </div>
      <div id="notification-types-list">
        <div id="notification-types-list-header" className="flex gap-7">
          <p className="uppercase text-gray-500 font-bold text-xs">Active</p>
          <p className="flex-grow-[5.5] uppercase text-gray-500 font-bold text-xs">
            Type
          </p>
          {orgIsConnectLowerTier && (
            <p className="flex-grow">
              <a
                className="text-blue underline"
                href="/connect/settings"
                onClick={() => trackEvent('Upgrade to edit templates clicked')}
              >
                Upgrade to edit templates
              </a>
            </p>
          )}
        </div>
        {map(NOTIFICATION_TYPES, (notificationType, index) => {
          const displayEtaWarning =
            eq(notificationType.name, 'eta_updated') &&
            findNotificationType('eta_updated')?.active &&
            !showETA;

          return (
            <div
              key={notificationType.name}
              id={`notification-type-row-${notificationType.name}`}
              className={`
                border-b border-gray-200 last:border-b-0 border-l-4
                py-5 px-6 grid grid-cols-[2fr,1fr] gap-10 -ml-1 -my-px
                ${
                  displayEtaWarning
                    ? 'rounded-l border-l-orange'
                    : 'border-l-transparent'
                }`}
            >
              <label
                htmlFor={notificationType.name}
                className="flex items-center mb-0"
              >
                {findNotificationType(notificationType.name)?.id && (
                  <input
                    name={`${prefix}[notification_types_attributes][${index}][id]`}
                    value={findNotificationType(notificationType.name).id}
                    type="hidden"
                  />
                )}
                <input
                  name={`${prefix}[notification_types_attributes][${index}][notification_type]`}
                  value={notificationType.name}
                  type="hidden"
                />
                <input
                  name={`${prefix}[notification_types_attributes][${index}][active]`}
                  value={
                    findNotificationType(notificationType.name)?.active ?? false
                  }
                  type="hidden"
                />
                <input
                  type="checkbox"
                  id={notificationType.name}
                  name={notificationType.name}
                  checked={
                    findNotificationType(notificationType.name)?.active ?? ''
                  }
                  onChange={() =>
                    handleNotificationTypeChange(notificationType.name)
                  }
                  className="w-6 h-6"
                />
                <div
                  id="notification-type-alert"
                  className="w-12 flex items-center justify-center"
                >
                  {displayEtaWarning && (
                    <Tooltip
                      text="Unchecking &ldquo;Show estimated time of arrival (ETA)&rdquo; will disable this notification"
                      placement="bottom"
                      className="ml-0"
                      tooltipClassName=" text-[1rem]"
                    >
                      <Icon
                        name="alertTriangle"
                        size={16}
                        color="#ff8300"
                        className="mb-2"
                      />
                    </Tooltip>
                  )}
                </div>
                <div id="notification-type-desc">
                  <p className="font-normal text-black text-sm mb-2">
                    {notificationType.label}
                  </p>
                  <p className="font-normal text-gray-500 text-xs mb-0">
                    {notificationType.description}
                  </p>
                </div>
              </label>
              <div
                id="notification-type-edit-template"
                className="flex items-center"
              >
                <Button
                  style="default"
                  disabled={orgIsConnectLowerTier}
                  onClick={() => {
                    trackEvent('Edit Template clicked');
                    onEditTemplate(notificationType);
                  }}
                >
                  Edit Template
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NotificationTypes.propTypes = {
  orgIsConnectLowerTier: PropTypes.bool,
  onChangeEtaUpdated: PropTypes.func,
  deliveryFormat: PropTypes.string,
  showETA: PropTypes.bool,
  prefix: PropTypes.string,
  notificationTypes: PropTypes.array,
  setNotificationTypes: PropTypes.func,
  onEditTemplate: PropTypes.func,
  environment: PropTypes.string,
  isWillCallV2: PropTypes.bool
};

export default NotificationTypes;
