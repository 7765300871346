import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import eq from 'lodash/eq';
import has from 'lodash/has';
import formatDate from 'date-fns/format';
import EnableConnectWizardControls from './EnableConnectWizardControls';
// eslint-disable-next-line no-unused-vars
import regeneratorRuntime from 'regenerator-runtime';
import { useForm, Controller } from 'react-hook-form';
import RadioButtons from './RadioButtons';
import DatePicker from 'react-datepicker';
import DropDown from './Dropdown';

const EnableConnectWizardStep1 = ({
  organization,
  backDisabled,
  nextDisabled,
  onBack,
  onNext
}) => {
  const subscriptionTiers = [
    {
      value: 'trial',
      label: 'Trial'
    },
    {
      value: 'starter_tier',
      label: 'Starter Tier'
    },
    {
      value: 'professional_tier',
      label: 'Professional Tier'
    }
  ];
  const vehicleCapacityUnits = {
    Ton: 'Tons',
    Skid: 'Skids',
    Box: 'Boxes',
    Pound: 'Pounds',
    Cube: 'Cubes',
    Order: 'Orders',
    Pallet: 'Pallets',
    Unit: 'Units',
    'Sq.Ft.': 'Sq.Ft.'
  };
  const { register, handleSubmit, watch, control, errors } = useForm({
    mode: 'onSubmit',
    revalidateMode: 'onBlur'
  });
  const connectOrg = organization?.connect_organization;
  const subscription = connectOrg?.subscription;

  const handleNext = formValues => {
    onNext(formValues);
  };

  const formatISO = date => {
    if (isNil(date)) return '';

    return formatDate(new Date(date), 'yyyy-MM-dd');
  };

  const isTrial = () => {
    const subscriptionType =
      watch('subscription_type') || subscription?.subscription_type;
    return isNil(subscriptionType) || eq(subscriptionType, 'trial');
  };

  return (
    <>
      <div className="cx_l--width-45 cx_l--margin-auto">
        <div className="cx_t cx_t--l cx_t--600 cx_t--blue cx_l--margin-bottom-10">
          Connect Organization Set Up
        </div>
        <div className="cx_t cx_t--xs cx_l--margin-bottom-26">
          To setup your Connect organization please enter the following
          information below and click next.
        </div>
        <form>
          <div className="cx_l--margin-bottom-32 cx_l--width-80">
            <Controller
              name="subscription_type"
              control={control}
              defaultValue={subscription?.subscription_type ?? 'trial'}
              render={({ onChange, name, value }) => {
                return (
                  <RadioButtons
                    name={name}
                    value={value}
                    disabled={
                      !isNil(subscription) &&
                      !eq(subscription?.subscription_type, 'trial')
                    }
                    options={subscriptionTiers}
                    onChange={onChange}
                  />
                );
              }}
            />
          </div>
          {isTrial() && (
            <div
              className={`cx_field cx_field--date ${
                has(errors, 'trial_start_date') ? 'cx_field--danger' : ''
              }`}
            >
              <label htmlFor="trial_start_date" className="cx_field__label">
                Trial Start Date
              </label>
              <Controller
                name="trial_start_date"
                control={control}
                rules={{ required: 'Trial Start Date is required' }}
                defaultValue={formatISO(subscription?.trial_start_date)}
                render={({ onChange, name, value }) => {
                  return (
                    <DatePicker
                      autoComplete="disabled"
                      selected={
                        isEmpty(value) ? '' : new Date(`${value}T00:00:00`)
                      }
                      dateFormat="M/d/yyyy"
                      className="cx_field__input cx_field__input--i cx_i--calendar cx_l--width-80"
                      name={name}
                      placeholderText="Enter trial start date"
                      minDate={new Date()}
                      maxDate={
                        isEmpty(watch('trial_end_date'))
                          ? null
                          : new Date(`${watch('trial_end_date')}T00:00:00`)
                      }
                      onChange={date => onChange(formatISO(date))}
                    />
                  );
                }}
              />
              {has(errors, 'trial_start_date') && (
                <div className="cx_field__messages">
                  <div className="cx_field__message cx_field__message--danger">
                    {errors.trial_start_date?.message}
                  </div>
                </div>
              )}
            </div>
          )}
          {isTrial() && (
            <div
              className={`cx_field cx_field--date ${
                has(errors, 'trial_end_date') ? 'cx_field--danger' : ''
              }`}
            >
              <label htmlFor="trial_end_date" className="cx_field__label">
                Trial End Date
              </label>
              <Controller
                name="trial_end_date"
                control={control}
                rules={{ required: 'Trial End Date is required' }}
                defaultValue={formatISO(subscription?.trial_end_date)}
                render={({ onChange, name, value }) => {
                  return (
                    <DatePicker
                      autoComplete="disabled"
                      selected={
                        isEmpty(value) ? '' : new Date(`${value}T00:00:00`)
                      }
                      dateFormat="M/d/yyyy"
                      className="cx_field__input cx_field__input--i cx_i--calendar cx_l--width-80"
                      name={name}
                      placeholderText="Enter trial end date"
                      minDate={
                        isEmpty(watch('trial_start_date'))
                          ? new Date()
                          : new Date(`${watch('trial_start_date')}T00:00:00`)
                      }
                      onChange={date => onChange(formatISO(date))}
                    />
                  );
                }}
              />
              {has(errors, 'trial_end_date') && (
                <div className="cx_field__messages">
                  <div className="cx_field__message cx_field__message--danger">
                    {errors.trial_end_date?.message}
                  </div>
                </div>
              )}
            </div>
          )}
          {!isTrial() && (
            <div
              className={`cx_field cx_field--date ${
                has(errors, 'service_start_date') ? 'cx_field--danger' : ''
              }`}
            >
              <label htmlFor="service_start_date" className="cx_field__label">
                Contract Effective Date
              </label>
              <Controller
                name="service_start_date"
                control={control}
                rules={{ required: 'Contract Effective Date is required' }}
                defaultValue={formatISO(subscription?.service_start_date)}
                render={({ onChange, name, value }) => {
                  return (
                    <DatePicker
                      autoComplete="disabled"
                      selected={
                        isEmpty(value) ? '' : new Date(`${value}T00:00:00`)
                      }
                      dateFormat="M/d/yyyy"
                      className="cx_field__input cx_field__input--i cx_i--calendar cx_l--width-80"
                      name={name}
                      placeholderText="Enter contract effective date"
                      minDate={new Date()}
                      onChange={date => onChange(formatISO(date))}
                    />
                  );
                }}
              />
              {has(errors, 'service_start_date') && (
                <div className="cx_field__messages">
                  <div className="cx_field__message cx_field__message--danger">
                    {errors.service_start_date?.message}
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={`cx_field cx_field--text ${
              has(errors, 'number_of_vehicles') ? 'cx_field--danger' : ''
            }`}
          >
            <label htmlFor="number_of_vehicles" className="cx_field__label">
              Amount of Vehicles
            </label>
            <input
              name="number_of_vehicles"
              id="number_of_vehicles"
              ref={register({ required: 'Amount of Vehicles is required' })}
              className="cx_field__input cx_l--width-80"
              type="number"
              placeholder="Enter amount of vehicles"
              defaultValue={subscription?.number_of_vehicles ?? ''}
              disabled={
                !isNil(subscription) &&
                !eq(subscription?.subscription_type, 'trial')
              }
            />
            <div className="cx_field__messages">
              {has(errors, 'number_of_vehicles') && (
                <div className="cx_field__message cx_field__message--danger">
                  {errors.number_of_vehicles?.message}
                </div>
              )}
            </div>
          </div>
          <div
            className={`cx_field cx_field--select ${
              has(errors, 'vehicle_capacity_unit') ? 'cx_field--danger' : ''
            }`}
          >
            <label htmlFor="vehicle_capacity_unit" className="cx_field__label">
              Vehicle Capacity Unit
            </label>
            <Controller
              name="vehicle_capacity_unit"
              control={control}
              rules={{ required: 'Vehicle Capacity Unit is required' }}
              defaultValue={connectOrg?.vehicle_capacity_unit ?? ''}
              render={({ onChange, name, value }) => {
                return (
                  <DropDown
                    id={name}
                    name={name}
                    className="cx_dropdown--field cx_l--width-80"
                    inputValue={value}
                    onChange={onChange}
                    placeholder={
                      <span className="cx_t cx_t--cx cx_t--lightGray">
                        Choose a capacity unit
                      </span>
                    }
                    options={Object.keys(vehicleCapacityUnits).reduce(
                      (acc, curr) => ({
                        ...acc,
                        [curr]: (
                          <div className="cx_l--flex cx_l--flex-align-center">
                            {vehicleCapacityUnits[curr]}
                          </div>
                        )
                      }),
                      {}
                    )}
                  />
                );
              }}
            />
            <div className="cx_field__messages">
              {has(errors, 'vehicle_capacity_unit') && (
                <div className="cx_field__message cx_field__message--danger">
                  {errors.vehicle_capacity_unit?.message}
                </div>
              )}
            </div>
          </div>
          <div className="cx_field cx_field--textarea">
            <label htmlFor="unit_description" className="cx_field__label">
              Unit Description (optional)
            </label>
            <textarea
              name="unit_description"
              id="unit_description"
              ref={register}
              className="cx_field__input"
              placeholder="Write your unit description"
              defaultValue={connectOrg?.unit_description ?? ''}
            />
            <div className="cx_field__messages">
              {has(errors, 'unit_description') && (
                <div className="cx_field__message cx_field__message--danger">
                  {errors.unit_description?.message}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>

      <EnableConnectWizardControls
        backDisabled={backDisabled}
        nextDisabled={nextDisabled}
        onBack={onBack}
        onNext={handleSubmit(handleNext)}
      />
    </>
  );
};

EnableConnectWizardStep1.defaultProps = {
  organization: null,
  backDisabled: false,
  nextDisabled: false
};

EnableConnectWizardStep1.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
    connect_organization: PropTypes.shape({
      id: PropTypes.string,
      vehicle_capacity_unit: PropTypes.string,
      unit_description: PropTypes.string,
      subscription: PropTypes.shape({
        id: PropTypes.string,
        number_of_vehicles: PropTypes.number,
        subscription_type: PropTypes.string,
        trial_start_date: PropTypes.string,
        trial_end_date: PropTypes.string,
        service_start_date: PropTypes.string,
        service_end_date: PropTypes.string
      })
    })
  }),
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default EnableConnectWizardStep1;
