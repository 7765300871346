import React from 'react';
import PropTypes from 'prop-types';

const baseClasses = `
inline-flex items-center justify-center
h-[32px] py-1 px-3 rounded-[8px] border-[1px] cursor-pointer
text-base font-light leading-5
border-c7c7c7
`;

const selectedClasses = `
bg-[#003A5D] text-[#FAF9FA]
`;

const notSelectedClasses = `
text-[#003A5D]
`;

const Chip = ({ isSelected, onClick, label }) => {
  const finalClasses = `
    ${baseClasses} ${isSelected ? selectedClasses : notSelectedClasses}
  `;

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      className={finalClasses}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      {label}
    </div>
  );
};

Chip.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Chip;
