import React from 'react';
import PropTypes from 'prop-types';
import RadioButtons from '../RadioButtons/RadioButtons';

const CourierNotificationSettings = ({ settings }) => {
  const prefix = 'courier_notification_settings';

  return (
    <div>
      <h4>Available Orders</h4>
      <div>
        Choose the available order notifications that you want to receive via
        email.
      </div>
      <RadioButtons
        name={`${prefix}[new_order_email_frequency]`}
        value={(settings && settings.new_order_email_frequency) || 'all'}
        fieldSetClassName="flex flex-col mt-5"
        options={[
          {
            name: 'Anytime there is a new, available order',
            id: 'all',
            value: 'all'
          },
          {
            name: 'One (1) notification a day',
            id: 'one_per_day',
            value: 'one_per_day'
          },
          {
            name: 'Only when an order is assigned to my company',
            id: 'only_my_company',
            value: 'only_my_company'
          },
          {
            name: "Don't notify me about available orders",
            id: 'none',
            value: 'none'
          }
        ]}
      />
    </div>
  );
};

CourierNotificationSettings.propTypes = {
  settings: PropTypes.object
};

export default CourierNotificationSettings;
