/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import eq from 'lodash/eq';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import Modal from '../Modals/Modal';
import Input from '../Input/Input';
import Button from '../Button/Button';
import EmailEdit from './EmailEdit';
import EmailPreview from './EmailPreview';
import SmsPreview from './SmsPreview';
import SmsEdit from './SmsEdit';
import Toasts from '../Toast/Toast';
import successIcon from '../../assets/success.svg';
import errorIcon from '../../assets/error.svg';
import { regex } from '../../utils';
import useAnalytics from '../../hooks/useAnalytics';

const MAX_SMS_LENGTH = 150;
const MAX_EMAIL_LENGTH = 400;

const TEST_NOTIFICATION_STATUS = {
  initial: 'initial',
  succeeded: 'succeeded',
  failed: 'failed'
};

const NotificationsModal = ({
  notificationSettings,
  notificationTypes,
  setNotificationTypes,
  onClose,
  selectedNotificationType,
  path,
  authenticityToken,
  formValues,
  userEmail,
  logo
}) => {
  const { trackEvent } = useAnalytics();
  const [smsOrEmail, setSmsOrEmail] = useState('SMS');
  const [editOrPreview, setEditOrPreview] = useState('edit');
  const [openPhoneNumber, setOpenPhoneNumber] = useState(false);
  const [testPhoneNumber, setTestPhoneNumber] = useState('');
  const [previousNotificationTypes, setPreviousNotificationTypes] = useState([
    ...notificationTypes
  ]);
  const [toasts, setToasts] = useState([]);
  const [testSmsStatus, setTestSmsStatus] = useState(
    TEST_NOTIFICATION_STATUS.initial
  );
  const [testEmailStatus, setTestEmailStatus] = useState(
    TEST_NOTIFICATION_STATUS.initial
  );
  const [isEditedSmsText, setIsEditedSmsText] = useState(false);
  const [isEditedEmailText, setIsEditedEmailText] = useState(false);

  const isEditMode = eq(editOrPreview, 'edit');
  const isSms = eq(smsOrEmail, 'SMS');

  const organizationName =
    formValues?.['organization_notification_settings[recipient_facing_name]']
      ?.value;
  const etaEnabled =
    formValues?.[
      'organization_notification_settings[connect_eta_is_shown_to_recipients]'
    ]?.[1]?.checked;
  const etaBuffer =
    formValues?.[
      'organization_notification_settings[connect_eta_buffer_time_in_minutes]'
    ]?.value;
  const phoneNumber =
    formValues?.[
      'organization_notification_settings[recipient_contact_phone_number]'
    ]?.value;
  const webAddress =
    formValues?.[
      'organization_notification_settings[recipient_facing_web_address]'
    ]?.value;
  const isLogoRemoved = eq(
    formValues?.['organization_notification_settings[remove_logo]']?.value,
    '1'
  );
  const logoAlignment = formValues?.[
    'organization_notification_settings[logo_alignment]'
  ]?.[0]?.checked
    ? formValues?.['organization_notification_settings[logo_alignment]']?.[0]
        ?.value
    : formValues?.['organization_notification_settings[logo_alignment]']?.[1]
        ?.value;

  const EDITABLE_DEFAULT_TEXT = {
    delivery_created: {
      sms: `Your order from ${organizationName} has been created.`,
      email: `Good news! Your order has been created. To view an update or other information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    },
    will_call_ready: {
      sms: `Your order from ${organizationName} is ready for pickup.`,
      email: `Your order is ready for pickup. To view an update or other information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    },
    out_for_delivery: {
      sms: `Your delivery from ${organizationName} is out for delivery.`,
      email: `Your delivery is on the way! Be on the lookout for its arrival. To view an update or other information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    },
    eta_updated: {
      sms: `Your delivery ETA from ${organizationName} has been updated.`,
      email: `Your delivery ETA has been updated. To view an update or other information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    },
    delivery_attempted: {
      sms: `A delivery from ${organizationName} was attempted.`,
      email: `A delivery was attempted. To view an update or other information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    },
    delivery_complete: {
      sms: `Your order from ${organizationName} was completed.`,
      email: `Your order is complete! To view verification of completion or other information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    },
    delivery_cancelled: {
      sms: `Your order from ${organizationName} has been canceled.`,
      email: `Your order was canceled. To view information about the order, click the button below. ${
        organizationName && phoneNumber
          ? `If you have questions or are experiencing issues, please contact ${organizationName} at ${phoneNumber}.`
          : ''
      }`
    }
  };

  const findNotificationType = type =>
    find(notificationTypes, { notification_type: type });

  const notificationTypeToEdit = findNotificationType(
    selectedNotificationType?.name
  );

  const smsText = notificationTypeToEdit?.sms_template ?? '';
  const emailText = notificationTypeToEdit?.email_template ?? '';
  const willBeFilledWithDefaultSmsText =
    isEmpty(smsText) ||
    eq(
      EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]?.sms,
      smsText
    );
  const willBeFilledWithDefaultEmailText =
    isEmpty(emailText) ||
    eq(
      EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]?.email,
      emailText
    );

  useEffect(() => {
    if (
      !isEmpty(selectedNotificationType) &&
      !findNotificationType(selectedNotificationType?.name)
    ) {
      setNotificationTypes(notificationTypes => [
        ...notificationTypes,
        { notification_type: selectedNotificationType.name }
      ]);
    }
  }, [selectedNotificationType?.name]);

  const saveModal = () => {
    const notificationTypesToSave = map(notificationTypes, type => {
      if (eq(type.notification_type, selectedNotificationType?.name)) {
        return {
          ...type,
          ...(isSms
            ? willBeFilledWithDefaultSmsText && { sms_template: '' }
            : willBeFilledWithDefaultEmailText && { email_template: '' })
        };
      }
      return type;
    });
    fetch(path, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken,
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        credentials: 'include'
      },
      body: JSON.stringify({
        organization_notification_settings: {
          notification_types_attributes: notificationTypesToSave
        }
      })
    })
      .then(res => res.json())
      .then(json => {
        if (json.notification_types) {
          setNotificationTypes([...json.notification_types]);
          setPreviousNotificationTypes([...json.notification_types]);
          setToasts([
            {
              id: 'notification-updated-success',
              type: 'success',
              message: {
                title: `Template "${selectedNotificationType?.label}" updated.`
              }
            }
          ]);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsEditedSmsText(false);
        setIsEditedEmailText(false);
        onClose();
      });
  };

  const sendTestSmsAndTestEmail = testType => {
    if (eq(testType, 'SMS') && regex.sms.test(testPhoneNumber)) {
      const params = {
        notification_type: selectedNotificationType?.name,
        sms_recipient: testPhoneNumber,
        organization_name: organizationName,
        eta_enabled: etaEnabled.toString(),
        eta_buffer: parseInt(etaBuffer),
        custom_template: smsText
      };

      fetch('/my_organization_notification_settings/send_test_sms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticityToken,
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          credentials: 'include'
        },
        body: JSON.stringify(params)
      })
        .then(res => {
          if (res.ok) {
            setTestSmsStatus(TEST_NOTIFICATION_STATUS.succeeded);
          } else {
            setTestSmsStatus(TEST_NOTIFICATION_STATUS.failed);
          }
        })
        .catch(error => {
          console.error(error);
          setTestSmsStatus(TEST_NOTIFICATION_STATUS.failed);
        })
        .finally(() => {
          setTimeout(() => {
            setTestSmsStatus(TEST_NOTIFICATION_STATUS.initial);
          }, 5000);
        });
    }

    if (eq(testType, 'email')) {
      const params = {
        notification_type: selectedNotificationType?.name,
        organization_name: organizationName,
        phone_number: phoneNumber,
        web_address: webAddress,
        ...(!isLogoRemoved && { logo: logo }),
        logo_alignment: logoAlignment,
        eta_enabled: etaEnabled.toString(),
        eta_buffer: parseInt(etaBuffer),
        custom_template: emailText
      };

      fetch('/my_organization_notification_settings/send_test_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticityToken,
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          credentials: 'include'
        },
        body: JSON.stringify(params)
      })
        .then(res => {
          if (res.ok) {
            setTestEmailStatus(TEST_NOTIFICATION_STATUS.succeeded);
          } else {
            setTestEmailStatus(TEST_NOTIFICATION_STATUS.failed);
          }
        })
        .catch(error => {
          console.error(error);
          setTestEmailStatus(TEST_NOTIFICATION_STATUS.failed);
        })
        .finally(() => {
          setTimeout(() => {
            setTestEmailStatus(TEST_NOTIFICATION_STATUS.initial);
          }, 5000);
        });
    }
  };

  const handleOnChangeTextArea = (value, isReseting = false) => {
    const newNotificationTypes = map(notificationTypes, type => {
      if (eq(type.notification_type, selectedNotificationType?.name)) {
        return {
          ...type,
          ...(isSms
            ? {
                sms_template: isReseting
                  ? EDITABLE_DEFAULT_TEXT[
                      notificationTypeToEdit?.notification_type
                    ]?.sms
                  : value
              }
            : {
                email_template: isReseting
                  ? EDITABLE_DEFAULT_TEXT[
                      notificationTypeToEdit?.notification_type
                    ]?.email
                  : value
              })
        };
      }
      return type;
    });
    if (isSms) {
      setIsEditedSmsText(true);
    } else {
      setIsEditedEmailText(true);
    }
    setNotificationTypes(newNotificationTypes);
  };

  const handleCancel = () => {
    setNotificationTypes(previousNotificationTypes);
    setIsEditedSmsText(false);
    setIsEditedEmailText(false);
    onClose();
    trackEvent('Notification Template - Cancel Clicked');
  };

  const renderHeader = () => (
    <>
      <h4 className="mb-6 text-xl">{`Edit "${selectedNotificationType?.label}" Template`}</h4>
      <p className="text-sm text-gray-500 mb-3.5">
        Edit notification text below. We will automatically append the required
        info, such as ETA (if enabled) and a link to track orders. Be sure to
        preview your edits before saving.
      </p>
    </>
  );

  const renderTabs = () => (
    <div className="relative">
      <div className="mb-3.5">
        <button
          className="mr-10"
          onClick={e => {
            e.preventDefault();
            setSmsOrEmail('SMS');
            trackEvent('Notification Template - SMS Tab Clicked');
          }}
        >
          <span
            className={`text-sm text-navy  ${
              isSms
                ? 'border-b-2 border-orange font-extrabold'
                : 'font-semibold'
            }`}
          >
            SMS
          </span>
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            setSmsOrEmail('email');
            trackEvent('Notification Template - Email Tab Clicked');
          }}
        >
          <span
            className={`text-sm text-navy font-extrabold ${
              !isSms
                ? 'border-b-2 border-orange font-extrabold'
                : 'font-semibold'
            }`}
          >
            Email
          </span>
        </button>
      </div>
      {renderEditPreview()}
    </div>
  );

  const renderEditPreview = () => (
    <div className="z-40">
      <div className="absolute top-7 left-[80%]">
        <Button
          size="xs"
          style={isEditMode ? 'primary' : 'default'}
          customClass="rounded-l rounded-r-none focus:ring-0 focus-visible:ring"
          onClick={e => {
            e.preventDefault();
            setEditOrPreview('edit');
          }}
        >
          Edit
        </Button>
        <Button
          size="xs"
          style={isEditMode ? 'default' : 'primary'}
          customClass="rounded-r rounded-l-none focus:ring-0 focus-visible:ring"
          onClick={e => {
            e.preventDefault();
            setEditOrPreview('preview');
          }}
        >
          Preview
        </Button>
      </div>
      {isSms ? (
        isEditMode ? (
          <SmsEdit
            isEditedSmsText={isEditedSmsText}
            customSmsText={smsText}
            defaultSmsText={
              EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]
                ?.sms
            }
            onChangeTextArea={({ target: { value } }) =>
              handleOnChangeTextArea(value)
            }
            maxSmsLength={MAX_SMS_LENGTH}
          />
        ) : (
          <SmsPreview
            customSmsText={smsText}
            maxSmsLength={MAX_SMS_LENGTH}
            defaultSmsText={
              EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]
                ?.sms
            }
            etaEnabled={etaEnabled}
            organizationName={organizationName}
            selectedNotificationType={notificationTypeToEdit?.notification_type}
          />
        )
      ) : isEditMode ? (
        <EmailEdit
          isEditedEmailText={isEditedEmailText}
          customEmailText={emailText}
          defaultEmailText={
            EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]
              ?.email
          }
          onChangeTextArea={({ target: { value } }) =>
            handleOnChangeTextArea(value)
          }
          maxEmailLength={MAX_EMAIL_LENGTH}
        />
      ) : (
        <EmailPreview
          notificationSettings={notificationSettings}
          notificationType={selectedNotificationType?.label}
          customEmailText={emailText}
          defaultEmailText={
            EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]
              ?.email
          }
          etaEnabled={etaEnabled}
          organizationName={organizationName}
          phoneNumber={phoneNumber}
          webAddress={webAddress}
          isLogoRemoved={isLogoRemoved}
          logo={logo}
          logoAlignment={logoAlignment}
        />
      )}
      <div className="flex justify-between mb-5 mt-1">
        {renderCharactersLeft()}
        {renderSendSMSandEmailTest()}
      </div>
      {renderResetsAndSaveCancel()}
    </div>
  );

  const renderCharactersLeft = () => (
    <span className="text-gray-500 text-sm">
      {isSms
        ? `${MAX_SMS_LENGTH -
            (willBeFilledWithDefaultSmsText
              ? EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]
                  ?.sms?.length
              : smsText?.length)} character${smsText > 1 ? 's' : ''} left`
        : `${MAX_EMAIL_LENGTH -
            (willBeFilledWithDefaultEmailText
              ? EDITABLE_DEFAULT_TEXT[notificationTypeToEdit?.notification_type]
                  ?.email?.length
              : emailText?.length)} character${emailText > 1 ? 's' : ''} left`}
    </span>
  );

  const renderSendSMSandEmailTest = () => (
    <>
      {isSms ? (
        !eq(testSmsStatus, TEST_NOTIFICATION_STATUS.initial) ? (
          renderTestNotiticationStatus()
        ) : (
          <div className="flex flex-column items-end">
            <span
              role="button"
              tabIndex={0}
              onKeyDown={evt => {
                if (eq(evt.key, 'Enter'))
                  setOpenPhoneNumber(openPhoneNumber => !openPhoneNumber);
              }}
              className="text-navy text-sm font-semibold cursor-pointer"
              onClick={() =>
                setOpenPhoneNumber(openPhoneNumber => !openPhoneNumber)
              }
            >
              Send Test SMS{' '}
              <i
                className={`text-[10px] glyphicon glyphicon-menu-${
                  openPhoneNumber ? 'up' : 'down'
                }`}
              />
            </span>
            {openPhoneNumber && (
              <div className="bg-gray-200 mt-2 pt-2 px-6 flex justify-between items-center rounded-[3px]">
                <label
                  htmlFor="sms-test"
                  className="text-sm mr-6 mt-2 font-normal cursor-pointer"
                >
                  Phone Number:
                </label>
                <Input
                  type="tel"
                  id="sms-test"
                  name="sms_test"
                  mask="999-999-9999"
                  value={testPhoneNumber}
                  alwaysShowMask={false}
                  maskChar={null}
                  formatChars={{
                    '8': '[2-9]', // for first character of phone numbers, can't be 1 or 0 in North America
                    '9': '[0-9]',
                    ',': '[,]'
                  }}
                  pattern={regex.sms}
                  placeholder="555-555-5555"
                  onChange={e => setTestPhoneNumber(e?.target?.value)}
                  className="max-w-[150px] mr-6"
                />
                <span
                  role="button"
                  tabIndex={0}
                  onClick={() => sendTestSmsAndTestEmail('SMS')}
                  onKeyDown={evt => {
                    if (eq(evt.key, 'Enter'))
                      () => sendTestSmsAndTestEmail('SMS');
                  }}
                  className="text-sm text-[#2467F6] cursor-pointer"
                >
                  Send
                </span>
              </div>
            )}
          </div>
        )
      ) : !eq(testEmailStatus, TEST_NOTIFICATION_STATUS.initial) ? (
        renderTestNotiticationStatus()
      ) : (
        <span
          role="button"
          tabIndex={0}
          onClick={() => sendTestSmsAndTestEmail('email')}
          onKeyDown={evt => {
            if (eq(evt.key, 'Enter')) () => sendTestSmsAndTestEmail('email');
          }}
          className="text-sm text-[#2467F6] cursor-pointer"
        >
          Send Test Email
        </span>
      )}
    </>
  );

  const renderTestNotiticationStatus = () => {
    const isSuccess =
      eq(testSmsStatus, TEST_NOTIFICATION_STATUS.succeeded) ||
      eq(testEmailStatus, TEST_NOTIFICATION_STATUS.succeeded);

    return (
      <div className="text-sm flex">
        <img
          src={isSuccess ? successIcon : errorIcon}
          alt={isSuccess ? 'success' : 'fail'}
          className="w-[16px] mr-2 relative top-[-2px]"
        />
        {isSuccess ? (
          <>
            {isSms ? 'SMS' : 'Email'} sent to
            <b className="ml-2">{isSms ? testPhoneNumber : userEmail}</b>
          </>
        ) : (
          <>
            Could not send {isSms ? 'SMS' : 'Email'} to
            <b className="ml-2">{isSms ? testPhoneNumber : userEmail}</b>
          </>
        )}
      </div>
    );
  };

  const renderResetsAndSaveCancel = () => (
    <div className="flex justify-between">
      <Button
        size="sm"
        style="default"
        customClass="focus:ring-0 focus-visible:ring rounded"
        onClick={e => {
          e.preventDefault();
          handleOnChangeTextArea('', true);
        }}
      >
        Reset {isSms ? 'SMS' : 'Email'} to Default
      </Button>
      <div>
        <Button
          size="sm"
          style="default"
          customClass="focus:ring-0 focus-visible:ring rounded mr-3"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          style="primary"
          customClass="focus:ring-0 focus-visible:ring rounded"
          onClick={e => {
            e.preventDefault();
            saveModal();
            trackEvent('Notification Template - Save Clicked');
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Toasts toasts={toasts} onClose={setToasts} />
      <Modal isOpen={!isEmpty(selectedNotificationType)} onClose={handleCancel}>
        <div className="p-6">
          {renderHeader()}
          {renderTabs()}
        </div>
      </Modal>
    </>
  );
};

NotificationsModal.propTypes = {
  notificationSettings: PropTypes.object,
  notificationTypes: PropTypes.array,
  setNotificationTypes: PropTypes.func,
  onClose: PropTypes.func,
  path: PropTypes.string,
  selectedNotificationType: PropTypes.object,
  authenticityToken: PropTypes.string,
  userEmail: PropTypes.string,
  formValues: PropTypes.node,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default NotificationsModal;
