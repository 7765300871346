/* eslint-disable no-console */

import isNil from 'lodash/isNil';
import { analyticsTrack, isAnalyticsEnabled } from '../utils';

const genRandomHex = size =>
  Array.from(Array(size), () =>
    Math.floor(Math.random() * 16).toString(16)
  ).join('');

const useAnalytics = environment => {
  const trackStartTime = new Date();

  const trackEvent = (message, data) => {
    if (!isAnalyticsEnabled) return;

    const defaultTrackingData = {
      guid: genRandomHex(12),
      environment
    };

    let trackableData = defaultTrackingData;

    if (!isNil(data)) {
      trackableData = {
        ...trackableData,
        ...data
      };
    }

    analyticsTrack(message, trackableData);
  };

  return {
    trackEvent,
    trackStartTime
  };
};

export default useAnalytics;
