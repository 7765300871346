import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import createConsumer from '../../../channels/consumer';
import Popover from '../Popover/Popover';
import InitialAvatar from '../InitialAvatar/InitialAvatar';
import medalIcon from '../../assets/medal.svg';
import Modal from '../Modals/Modal';
import Textarea from '../Textarea/Textarea';

const DriverDetails = props => {
  const {
    canAssignDriver,
    canReassign,
    canUnassign,
    driverFeedbackReview,
    isDriverFeedbackVisible,
    orderCanJoin,
    orderId,
    canSetNetwork,
    isDriverAssignmentLocalVisible,
    isInternalUser,
    driverCourierName,
    claimedCourierName,
    courierOrgId,
    couriersInArea,
    isOrderScheduled,
    isBundleQueued,
    isTopDriver,
    isUnassignReasonModalAvailable,
    authenticityToken
  } = props;

  const [driverData, setDriverData] = useState({
    driverId: props.driverId,
    driverProfileImageUrl: props.driverProfileImageUrl,
    driverName: props.driverName,
    isDriverActive: props.isDriverActive,
    driverPhoneNumber: props.driverPhoneNumber,
    isDriverDetailsVisible: props.isDriverDetailsVisible,
    driverVehicleImageUrl: props.driverVehicleImageUrl,
    driverVehicleInformation: props.driverVehicleInformation,
    isOrderDelivered: props.isOrderDelivered,
    orderStatus: props.orderStatus,
    isDriverAssignmentVisible: props.isDriverAssignmentVisible
  });

  const [isAssignCourierPopoverOpen, setIsAssignCourierPopoverOpen] = useState(
    false
  );

  const [showUnassignReasonModal, setShowUnassignReasonModal] = useState(false);

  const [unassignReason, setUnassignReason] = useState('');

  const [unassignResponseMessage, setUnassignResponseMessage] = useState('');

  const clearModal = () => {
    setUnassignReason('');
    setShowUnassignReasonModal(false);
  };

  useEffect(() => {
    const driverDetailsChannel = createConsumer.subscriptions.create(
      {
        channel: 'DriverDetailsChannel',
        order_id: orderId
      },
      {
        received: data => {
          setDriverData(data);
        }
      }
    );

    return () => {
      driverDetailsChannel.unsubscribe();
    };
  }, []);

  const displayAssignCourierPartner = () => {
    return <span className="btn btn-primary">Assign Courier Partner</span>;
  };

  const getDriverAssignmentMessage = () => {
    if (isOrderScheduled) {
      return 'Dispatch will assign a driver closer to the pickup window.';
    } else if (isBundleQueued) {
      return 'You will be assigned a driver shortly. We are working on finding the best route.';
    } else {
      return 'No driver currently assigned.';
    }
  };

  const handleUnassignClick = () => {
    setShowUnassignReasonModal(true);
  };

  const handleUnassignReasonChange = event => {
    setUnassignReason(event.target.value);
  };

  const handleUnassignReasonConfirmButton = () => {
    const hasLetter = /[a-zA-Z]/.test(unassignReason);
    return !hasLetter;
  };

  const handleUnassignReasonSubmit = () => {
    if (unassignReason.trim()) {
      fetch(`/orders/${orderId}/release_driver_from`, {
        method: 'POST',
        body: JSON.stringify({
          unassign_reason: unassignReason
        }),
        headers: {
          'X-CSRF-Token': authenticityToken,
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          setUnassignResponseMessage(data.message);
        });
    }
  };

  return (
    <>
      {driverData.driverId && (
        <>
          <div className="col-sm-6">
            <div className="media">
              <div className="flex flex-row mb-4">
                <div className="media-left">
                  {driverData.isDriverDetailsVisible && (
                    <>
                      <a href={'/drivers/' + driverData.driverId}>
                        <img
                          src={driverData.driverProfileImageUrl}
                          alt="order_driver_user_profile_image"
                          className="thumbnail small-image mb-0"
                        ></img>
                      </a>
                    </>
                  )}
                  {!driverData.isDriverDetailsVisible && (
                    <img
                      src={driverData.driverProfileImageUrl}
                      alt="order_driver_user_profile_image"
                      className="thumbnail small-image mb-0"
                    ></img>
                  )}
                </div>
                <div className="flex flex-column justify-between">
                  <dl>
                    {driverData.isDriverDetailsVisible && (
                      <>
                        <dt>
                          <a href={'/drivers/' + driverData.driverId}>
                            {driverData.driverName}
                            {driverData.isDriverActive ? '' : ' (Inactive)'}
                          </a>
                        </dt>
                      </>
                    )}
                    <dd>
                      <a href={`tel:${driverData.driverPhoneNumber}`}>
                        {driverData.driverPhoneNumber}
                      </a>
                    </dd>
                  </dl>
                  <div className="flex-row justify-between">
                    {!isUnassignReasonModalAvailable &&
                      isDriverAssignmentLocalVisible &&
                      driverData.isDriverAssignmentVisible &&
                      canUnassign && (
                        <a
                          data-confirm="This will remove the driver and release back to the network of nearby drivers. Are you sure?"
                          data-method="post"
                          href={'/orders/' + orderId + '/release_driver_from'}
                          className="btn btn-default"
                          rel="nofollow"
                        >
                          Unassign
                        </a>
                      )}
                    {isUnassignReasonModalAvailable &&
                      isDriverAssignmentLocalVisible &&
                      driverData.isDriverAssignmentVisible &&
                      canUnassign && (
                        <>
                          <button
                            className="btn btn-default"
                            onClick={handleUnassignClick}
                          >
                            Unassign
                          </button>
                          <Modal
                            id="unassign-reason-modal"
                            isOpen={showUnassignReasonModal}
                            onClose={clearModal}
                          >
                            <div className="p-10">
                              <h3 className="cx_t cx_t--s cx_t--blue">
                                Confirm Driver Unassigment
                              </h3>
                              <br />
                              {!unassignResponseMessage && (
                                <>
                                  <div className="cx_t cx_t--xs cx_l--margin-top-32 mb-6">
                                    This will remove the driver and release back
                                    to the network of nearby drivers.
                                    <br />
                                    <br />
                                    Are you sure?
                                    <br />
                                  </div>
                                  <Textarea
                                    id="pickup-notes"
                                    name="multi_stop_order_form[pickup_notes]"
                                    label="Driver Unassigment Reason"
                                    rows={4}
                                    value={unassignReason}
                                    required
                                    showRequiredIndicator
                                    maxLength={250}
                                    style={{ resize: 'none' }}
                                    onChange={handleUnassignReasonChange}
                                    helpText="Reason must include at least one word with alphabetical characters"
                                  />
                                  <div className="cx_l--flex cx_l--flex-align-center cx_l--flex-justify-end cx_l--margin-top-48">
                                    <button
                                      className="btn btn-default mr-6"
                                      onClick={clearModal}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-default"
                                      onClick={handleUnassignReasonSubmit}
                                      disabled={handleUnassignReasonConfirmButton()}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </>
                              )}
                              {unassignResponseMessage && (
                                <>
                                  <div className="cx_t cx_t--xs cx_l--margin-top-32 mb-6">
                                    <p>{unassignResponseMessage}</p>
                                  </div>
                                  <div className="cx_l--flex cx_l--flex-align-center cx_l--flex-justify-end cx_l--margin-top-48">
                                    <a
                                      href={`/orders/${orderId}`}
                                      className="btn btn-default"
                                    >
                                      Close
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </Modal>
                        </>
                      )}
                    {isDriverAssignmentLocalVisible &&
                      driverData.isDriverAssignmentVisible &&
                      canReassign && (
                        <a
                          href={'/orders/' + orderId + '/drivers'}
                          className="btn btn-default"
                        >
                          Reassign
                        </a>
                      )}
                  </div>
                </div>
              </div>
              {isInternalUser && driverCourierName && courierOrgId && (
                <div className="flex flex-row mb-4">
                  <span
                    className="label label-default"
                    style={{ backgroundColor: '#2A282A', fontSize: '14px' }}
                  >
                    Courier Partner
                  </span>
                  <span className="pl-2">
                    <a href={`/admin/organizations/${courierOrgId}`}>
                      {driverCourierName}
                    </a>
                  </span>
                </div>
              )}
              {isInternalUser && isTopDriver && (
                <div className="flex flex-row mb-4">
                  <span
                    className="label label-default"
                    style={{
                      backgroundColor: '#F2C94C',
                      fontSize: '14px',
                      color: '#2A282A',
                      border: '1px solid #2A282A',
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={medalIcon}
                      alt=""
                      style={{ height: '14px', marginRight: '5px' }}
                    />{' '}
                    Top Driver
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="media">
              {driverData.driverVehicleImageUrl && (
                <>
                  <div className="media-left">
                    <img
                      src={driverData.driverVehicleImageUrl}
                      alt="order_driver_user_profile_image"
                      className="thumbnail small-image mb-0"
                    ></img>
                  </div>
                </>
              )}
              <div className="media-body">
                <dl>
                  <dt></dt>
                  <dd>{driverData.driverVehicleInformation}</dd>
                </dl>
              </div>
              {isDriverFeedbackVisible &&
                driverData.isOrderDelivered &&
                driverFeedbackReview && (
                  <>
                    <p>
                      <b>Driver Feedback</b>
                    </p>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <span
                        style={{ paddingRight: 10 + 'px', color: '#f26722' }}
                      >
                        {/* Creates an array containing a number of null elements equal to the number of stars from the review and iterates through the array to return a span element for each null value */}
                        {Array.apply(null, {
                          length: driverFeedbackReview['star_rating']
                        }).map(i => (
                          <span className="glyphicon glyphicon-star" key={i} />
                        ))}
                      </span>
                      <p>
                        {driverFeedbackReview['star_rating']}{' '}
                        <span style={{ fontSize: 0.8 + 'em' }}>
                          {driverFeedbackReview['star_rating'] === 1
                            ? 'STAR'
                            : 'STARS'}
                        </span>
                      </p>
                    </span>
                    {driverFeedbackReview['responses'] &&
                      driverFeedbackReview['responses'].map((r, i) => (
                        <div key={i}>
                          <p>
                            <b>{r['question']}</b>
                          </p>
                          <p>
                            <em>{r['answer']}</em>
                          </p>
                        </div>
                      ))}
                  </>
                )}
              {isDriverFeedbackVisible &&
                driverData.isOrderDelivered &&
                !driverFeedbackReview && (
                  <>
                    <p>
                      <b>Driver Feedback</b>
                    </p>
                    <p>There is no driver feedback for this order.</p>
                  </>
                )}
            </div>
          </div>
        </>
      )}

      {!driverData.driverId && (
        <>
          <div className="pl-6">
            <p className="text-muted italic">{getDriverAssignmentMessage()}</p>
            {isInternalUser && claimedCourierName && (
              <p className="text-muted">Sent to {claimedCourierName}.</p>
            )}
            <div className="flex flex-wrap gap-x-1">
              {isDriverAssignmentLocalVisible &&
                driverData.orderStatus !== 'cancelled' && (
                  <>
                    {canAssignDriver && (
                      <a
                        href={'/orders/' + orderId + '/drivers'}
                        className="btn btn-default"
                      >
                        Assign Driver
                      </a>
                    )}
                    {canSetNetwork &&
                      orderCanJoin &&
                      couriersInArea.length > 0 &&
                      claimedCourierName === null && (
                        <Popover
                          isPopoverOpen={isAssignCourierPopoverOpen}
                          displayContent={displayAssignCourierPartner()}
                          setOpenStatus={status => {
                            setIsAssignCourierPopoverOpen(status);
                          }}
                        >
                          <div>
                            {couriersInArea.map(courier => (
                              <div
                                key={courier.id}
                                className="flex pt-3 pb-3 pl-6 hover:bg-indigo-200"
                              >
                                <a
                                  data-method="post"
                                  href={`/orders/${orderId}/assign_courier?courier_id=${courier.id}`}
                                  className="btn w-full"
                                  rel="nofollow"
                                >
                                  <div className="flex">
                                    <InitialAvatar givenName={courier.name} />
                                    {courier.name}
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>
                        </Popover>
                      )}
                  </>
                )}
              {claimedCourierName !== null &&
                driverData.orderStatus === 'unassigned' && (
                  <a
                    data-method="post"
                    href={'/orders/' + orderId + '/release_courier_claim'}
                    className="btn btn-default"
                    rel="nofollow"
                  >
                    Unassign Courier
                  </a>
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

DriverDetails.propTypes = {
  driverId: PropTypes.number,
  orderId: PropTypes.number,
  isDriverDetailsVisible: PropTypes.bool,
  driverProfileImageUrl: PropTypes.string,
  isDriverActive: PropTypes.bool,
  driverName: PropTypes.string,
  driverPhoneNumber: PropTypes.string,
  isDriverAssignmentVisible: PropTypes.bool,
  isDriverAssignmentLocalVisible: PropTypes.bool,
  canUnassign: PropTypes.bool,
  canReassign: PropTypes.bool,
  driverVehicleImageUrl: PropTypes.string,
  driverVehicleInformation: PropTypes.string,
  orderStatus: PropTypes.string,
  canAssignDriver: PropTypes.bool,
  canSetNetwork: PropTypes.bool,
  orderCanJoin: PropTypes.bool,
  isOrderDelivered: PropTypes.bool,
  driverFeedbackReview: PropTypes.object,
  isInternalUser: PropTypes.bool,
  driverCourierName: PropTypes.string,
  claimedCourierName: PropTypes.string,
  courierOrgId: PropTypes.string,
  isDriverFeedbackVisible: PropTypes.bool,
  couriersInArea: PropTypes.arrayOf(PropTypes.object),
  isOrderScheduled: PropTypes.bool,
  isBundleQueued: PropTypes.bool,
  isTopDriver: PropTypes.bool,
  isUnassignReasonModalAvailable: PropTypes.bool,
  authenticityToken: PropTypes.string
};

export default DriverDetails;
