import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

const CapabilityBundleItemSelect = props => {
  const { available_items, selected_items } = props;
  const vehicleTypeCheckBoxes = document.querySelectorAll(
    'input[type="checkbox"]'
  );
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState(
    vehicleTypeCheckBoxes.length > 0
      ? Array.from(vehicleTypeCheckBoxes.values())
          .filter(checkbox => checkbox.checked)
          .map(checkbox => parseInt(checkbox.value))
      : []
  );
  const [availableItems, setAvailableItems] = useState(
    selected_items.length > 0
      ? available_items
          .filter(item => item?.id !== selected_items[0]?.id)
          .filter(item =>
            item?.vehicle_type_ids?.some(id =>
              selectedVehicleTypes.includes(id)
            )
          )
      : available_items
  );
  const [selectedItems, setSelectedItems] = useState(selected_items);

  vehicleTypeCheckBoxes.forEach(checkbox => {
    checkbox.addEventListener('change', event =>
      debouncedHandleVehicleTypeSelection(event)
    );
  });

  useEffect(() => {
    setAvailableItems(() => {
      let items = available_items.filter(
        item =>
          item.vehicle_type_ids.some(id => selectedVehicleTypes.includes(id)) &&
          !selectedItems.some(selectedItem => selectedItem.id === item.id)
      );
      return items.sort((a, b) => a.name.localeCompare(b.name));
    });
  }, [selectedVehicleTypes]);

  const debouncedHandleVehicleTypeSelection = debounce(
    handleVehicleTypeSelection,
    700
  );

  function handleVehicleTypeSelection(event) {
    if (event.target.checked) {
      setSelectedVehicleTypes(prevSelectedVehicleTypes => {
        let vehicle_types_array = [
          ...prevSelectedVehicleTypes,
          parseInt(event.target.value)
        ];
        return Array.from(new Set(vehicle_types_array));
      });
    } else {
      setSelectedVehicleTypes(prevSelectedVehicleTypes => {
        return prevSelectedVehicleTypes.filter(
          vehicle_type => vehicle_type !== parseInt(event.target.value)
        );
      });
    }
  }

  function handleAvailableItemSelectClick(event) {
    const selectedValue = event.target.value;
    const selectedItem = availableItems.find(item => item.id === selectedValue);

    if (selectedItems.length === 0) {
      setAvailableItems(prevAvailableItems => {
        let newAvailableItems = prevAvailableItems.filter(
          item => item.id !== selectedValue
        );
        newAvailableItems = newAvailableItems.filter(item =>
          item.vehicle_type_ids.some(id => selectedVehicleTypes.includes(id))
        );
        return newAvailableItems.sort((a, b) => a.name.localeCompare(b.name));
      });
    } else {
      setAvailableItems(prevAvailableItems => {
        return prevAvailableItems.filter(item => item.id !== selectedValue);
      });
    }

    setSelectedItems(prevSelectedItems => {
      return [...prevSelectedItems, selectedItem].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
  }

  function handleSelectedItemsClick(event) {
    const selectedValue = event.target.value;
    const selectedItem = selectedItems.find(item => item.id === selectedValue);
    setSelectedItems(prevSelectedItems =>
      prevSelectedItems
        .filter(item => item.id !== selectedValue)
        .sort((a, b) => a.name.localeCompare(b.name))
    );

    let options = event.target.parentElement.options;

    if (!options || options.length <= 1) {
      setAvailableItems(available_items);
    } else {
      setAvailableItems(prevAvailableItems =>
        [...prevAvailableItems, selectedItem].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      );

      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        option.selected = true;
      }
    }
  }

  return (
    <div>
      <div
        style={{ display: 'inline-block', marginRight: '30px', width: '200px' }}
      >
        <h4
          style={{
            display: availableItems.length === 0 ? 'none' : 'inline-block'
          }}
        >
          Available Add-ons
        </h4>
        <select
          name="available_items"
          size={availableItems.length > 0 ? availableItems.length + 1 : 1}
          disabled={availableItems.length === 0}
          style={{
            border: '1px solid black',
            display: availableItems.length === 0 ? 'none' : 'inline-block',
            marginRight: '10px',
            width: '100%',
            overflow: 'auto',
            height: '700px',
            minHeight: '700px'
          }}
          multiple="multiple"
        >
          {availableItems.map((item, index) => {
            return (
              <option
                key={`item-${index}`}
                value={item.id}
                onClick={handleAvailableItemSelectClick}
              >
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div style={{ display: 'inline-block', width: '50%', height: '700px' }}>
        <h4
          style={{
            display: selectedItems.length === 0 ? 'none' : 'inline-block'
          }}
        >
          In this bundle
        </h4>
        <select
          name="capability_bundle[capability_bundle_items_attributes][][capability_id]"
          size={selectedItems.length > 0 ? selectedItems.length + 1 : 1}
          disabled={selectedItems.length === 0}
          style={{
            border: '1px solid black',
            display: selectedItems.length === 0 ? 'none' : 'inline-block',
            width: '100%',
            overflow: 'auto',
            height: '700px',
            minHeight: '700px'
          }}
          multiple="multiple"
        >
          {selectedItems.map((item, index) => {
            return (
              <option
                key={`item-${index}`}
                value={item.id}
                onClick={handleSelectedItemsClick}
                selected={selectedItems.some(
                  selectedItem => selectedItem.id === item.id
                )}
              >
                {`${item.name}`}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

CapabilityBundleItemSelect.propTypes = {
  available_items: PropTypes.arrayOf(PropTypes.object),
  selected_items: PropTypes.arrayOf(PropTypes.object)
};

export default CapabilityBundleItemSelect;
