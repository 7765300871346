import React from 'react';
import PropTypes from 'prop-types';
import eq from 'lodash/eq';
import isEmpty from 'lodash/isEmpty';
import pinImage from '../../../../assets/images/connect-map-pin-empty.png';
import DispatchLogo from '../../../../assets/images/Dispatch_Connect_Horizontal_Logo.png';
import Button from '../Button/Button';

const EmailPreview = ({
  notificationType,
  etaEnabled,
  customEmailText,
  defaultEmailText,
  organizationName,
  phoneNumber,
  webAddress,
  isLogoRemoved,
  logo,
  logoAlignment
}) => {
  const showDefaultPreviewEmailText =
    isEmpty(customEmailText) || eq(customEmailText, defaultEmailText);

  const EMAIL_CONTENT = {
    'Order Created': {
      color: 'bg-[#777777]',
      etaMessage: 'ETA: order is not picked up',
      defaultMessage:
        'Good news! Your order has been created. To view an update or other information about the order, click the button below.'
    },
    'Order Ready': {
      color: 'bg-blue',
      etaMessage: customEmailText ? 'Pickup between 9:00 AM - 3:00 PM' : '',
      defaultMessage: `Your order is ready for pickup${
        !customEmailText && etaEnabled ? ' between 9:00 AM - 3:00 PM' : ''
      }. To view an update or other information about the order, click the button below.`
    },
    'Out for Delivery': {
      color: 'bg-blue',
      etaMessage: 'ETA of Delivery: 1:47 PM - 2:02 PM',
      defaultMessage:
        'Your delivery is on the way! Be on the lookout for its arrival. To view an update or other information about the order, click the button below.'
    },
    'ETA Updated': {
      color: 'bg-blue',
      etaMessage: 'ETA of Delivery: 3:47 PM - 4:02 PM',
      defaultMessage:
        'Your delivery ETA has been updated. To view an update or other information about the order, click the button below.'
    },
    'Delivery Attempted': {
      color: 'bg-blue',
      etaMessage: 'Delivery Attempted: 1:31 PM',
      defaultMessage:
        'A delivery was attempted. To view an update or other information about the order, click the button below.'
    },
    'Order Complete': {
      color: 'bg-green',
      etaMessage: 'Order Completed: 1:31 PM',
      defaultMessage:
        'Your order is complete! To view verification of completion or other information about the order, click the button below.'
    },
    'Order Canceled': {
      color: 'bg-red',
      etaMessage: 'Order Canceled: 1:31 PM',
      defaultMessage:
        'Your order was canceled. To view information about the order,click the button below.'
    }
  };

  const renderHeader = () => (
    <div>
      <div
        className={`text-white text-center rounded-t p-1.5 mb-5 ${EMAIL_CONTENT[notificationType]?.color}`}
      >
        {notificationType}
      </div>
      <div className="px-6">
        {!isLogoRemoved && logo && (
          <div
            className={`flex mb-4 ${
              eq(logoAlignment, 'center') ? 'justify-center' : 'justify-start'
            }`}
          >
            <img
              src={
                eq(typeof logo, 'string') || logo instanceof String
                  ? logo
                  : URL.createObjectURL(logo)
              }
              className="h-auto w-auto max-w-[250px] max-h-[150px]"
              alt={organizationName ?? 'Dispatch'}
            />
          </div>
        )}
        {organizationName && (
          <h3 className="mt-0 text-lg">{organizationName}</h3>
        )}
        {webAddress && (
          <div>
            <a href={webAddress} target="_blank" rel="noreferrer">
              {webAddress}
            </a>
          </div>
        )}
      </div>
    </div>
  );

  const renderContent = () => {
    return (
      <div className="text-gray-800 text-sm">
        {etaEnabled &&
          !isEmpty(EMAIL_CONTENT[notificationType]?.etaMessage) && (
            <div className="pb-4 font-bold">
              {EMAIL_CONTENT[notificationType]?.etaMessage}
            </div>
          )}
        <div>
          {showDefaultPreviewEmailText
            ? EMAIL_CONTENT[notificationType]?.defaultMessage
            : customEmailText}
        </div>
        {eq(notificationType, 'Order Complete') && (
          <>
            <hr />
            <div className="flex flex-column items-center">
              <div className="w-[170px] h-[100px] bg-gray-400 mb-4" />
              <div className="w-[170px] h-[100px] bg-gray-400" />
              <br />
              <div className="text-sm">
                Trouble viewing image?{' '}
                <span className="text-blue">Click Here</span>
                <br />
                Delivery images expires after seven days.
              </div>
            </div>
          </>
        )}
        {showDefaultPreviewEmailText && organizationName && phoneNumber && (
          <>
            <br />
            <div className="text-sm">{`If you have questions or are experiencing issues, please contact ${organizationName}
             at ${phoneNumber}.`}</div>
          </>
        )}
      </div>
    );
  };

  const renderDetails = () => (
    <>
      <div className="flex w-full justify-start text-sm mb-6">
        <div className="mr-4">
          <img src={pinImage} className="h-[25px]" alt="Dispatch" />
        </div>
        <div className="text-gray-800">
          <div className="font-bold">REFERENCE ID</div>
          <div>#12345</div>
          <div>{organizationName}</div>
          <div>{phoneNumber}</div>
          <br />
          <div className="font-bold">
            {notificationType === 'Order Ready' ? 'PICKUP' : 'DELIVERY'} ADDRESS
          </div>
          <div>4118 Oak Drive</div>
          <div>Schroon Lake, NY</div>
          <div>12870</div>
          <br />
          <div className="font-bold">
            {notificationType === 'Order Ready' ? 'PICKUP' : 'DELIVERY'} CONTACT
          </div>
          <div>
            Mr. {notificationType === 'Order Ready' ? 'Pickup' : 'Drop Off'}{' '}
            Company 1 Inc.
          </div>
          <div>123-123-2131</div>
          <br />
          <Button
            size="md"
            style="primary"
            customClass="focus:ring-0 focus-visible:ring rounded"
            onClick={e => {
              e.preventDefault();
            }}
          >
            View Order Details
          </Button>
        </div>
      </div>
    </>
  );

  const renderFooter = () => (
    <div className="text-gray-500 mt-6 flex flex-col items-center">
      <img src={DispatchLogo} className="h-[40px]" alt="Dispatch Connect" />
      <div>Powered by Dispatch</div>
      <div>Dispatch, 1401 W 94th Street, Bloomington, MN 55431, USA</div>
      <div>
        <a
          href="https://support.dispatchit.com/"
          target="_blank"
          className="text-blue"
          rel="noreferrer"
        >
          Support link to KB articles
        </a>
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col items-center bg-[#E5E5E5] max-h-[430px] overflow-x-scroll p-5">
      <div className="max-w-[350px] w-full bg-white">
        {renderHeader()}
        <div className="px-6 pb-6 bg-white">
          <hr />
          {renderContent()}
          <hr />
          {renderDetails()}
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};

EmailPreview.propTypes = {
  notificationType: PropTypes.string,
  etaEnabled: PropTypes.bool,
  customEmailText: PropTypes.string,
  defaultEmailText: PropTypes.string,
  organizationName: PropTypes.string,
  phoneNumber: PropTypes.string,
  webAddress: PropTypes.string,
  isLogoRemoved: PropTypes.bool,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  logoAlignment: PropTypes.string
};

export default EmailPreview;
