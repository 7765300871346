import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon';
import ContractualPricing from '../Graphics/ContractualPricing';

const STATUS_WITH_INVERTED_COLOR = ['Editing'];

const OrderStatus = props => {
  const {
    dedicatedVehicleRequested,
    orderStatusText,
    orderStatusColor,
    orderType,
    neededVehicleType,
    scheduledInFuture,
    orderTimeAvailable,
    recipientFacingName,
    recipientContactPhoneNumber,
    isInternalUser,
    useContractualPricing,
    useV2Pricing
  } = props;

  const isStatusColorInverted = STATUS_WITH_INVERTED_COLOR.includes(
    orderStatusText
  );

  const style = {
    backgroundColor: isStatusColorInverted ? '#fff' : orderStatusColor,
    border: `1px solid ${orderStatusColor}`,
    color: isStatusColorInverted ? orderStatusColor : '#fff'
  };

  return (
    <div className="clearfix mb-8">
      <div className="font-bold pull-left">
        <span className="medium-text">
          <span className="label label-default" style={style}>
            {orderStatusText}
          </span>
        </span>
        {orderType && (
          <span id={`order-type-${orderType}`} className="ml-8">
            {orderType}
          </span>
        )}
        {neededVehicleType && (
          <span id={`vehicle-type-${neededVehicleType}`} className="ml-8">
            {neededVehicleType}
          </span>
        )}
        {dedicatedVehicleRequested && (
          <span className="ml-8">
            Dedicated vehicle
            <Tooltip
              text="Dispatch will make sure the selected vehicle type arrives empty and does not pick up any additional deliveries on its route to drop-off."
              className="ml-1"
            >
              <Icon
                name="help"
                className="inline-block text-brand-primary mb-1"
                size={14}
              />
            </Tooltip>
          </span>
        )}
        {scheduledInFuture && (
          <span className="text-muted font-normal ml-8">
            Scheduled for {orderTimeAvailable}
          </span>
        )}
        {recipientFacingName && recipientContactPhoneNumber && (
          <div className="font-semibold mt-8">
            <div>
              If you have questions or are experiencing issues, please contact
            </div>
            <div>
              {recipientFacingName} at{' '}
              <a href={`tel:${recipientContactPhoneNumber}`}>
                {recipientContactPhoneNumber}
              </a>
              .
            </div>
          </div>
        )}
        {isInternalUser && useV2Pricing && (
          <span title="Order uses V2 pricing" className="ml-8">
            <Icon name="pricingV2" className="inline" size={20} />
          </span>
        )}
        {isInternalUser && useContractualPricing && (
          <span
            title="Order uses contractual pricing"
            className="ml-8 text-color"
          >
            <ContractualPricing className="inline" height={20} />
          </span>
        )}
      </div>
    </div>
  );
};

OrderStatus.propTypes = {
  dedicatedVehicleRequested: PropTypes.bool,
  orderStatusText: PropTypes.string,
  orderStatusColor: PropTypes.string,
  orderType: PropTypes.string,
  neededVehicleType: PropTypes.string,
  scheduledInFuture: PropTypes.bool,
  orderTimeAvailable: PropTypes.string,
  recipientFacingName: PropTypes.string,
  recipientContactPhoneNumber: PropTypes.string,
  isInternalUser: PropTypes.bool,
  useContractualPricing: PropTypes.bool,
  useV2Pricing: PropTypes.bool
};

export default OrderStatus;
