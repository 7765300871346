import React from 'react';
import PropTypes from 'prop-types';

// Note that this is in Graphics instead of Icon because it is rectangular instead of square.
const ContractualPricing = ({
  className = '',
  fill = 'currentColor',
  height = 22,
  width = 31
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12 19.5h-.2l-.4-.1a.5.5 0 0 1-.2-.6l.2-.2L15 15l-1.5-1.5-3.6 3.6-.2.1h-.2l-.4-.1a.5.5 0 0 1-.2-.4v-.2l.2-.1 3.6-3.7-1.5-1.5-3.6 3.6a.6.6 0 0 1-.4.2c-.2 0-.3 0-.4-.2a.5.5 0 0 1 0-.7l3.6-3.6L9 9l-3.6 3.6a.6.6 0 0 1-.4.2l-.4-.2a.6.6 0 0 1-.2-.4.5.5 0 0 1 .2-.4l4.7-4.6 2 2 1 .5 1 .2a2.6 2.6 0 0 0 2.7-2.7l-.2-1-.6-.8-2-2 .3-.3.7-.5a2.4 2.4 0 0 1 1.6 0l.7.5L21 7.6a6 6 0 0 1 2-1l-.5-.5L18 1.6a4.3 4.3 0 0 0-3-1.3 4.4 4.4 0 0 0-3.2 1.3l-.3.3-.3-.3a4.3 4.3 0 0 0-3-1.3A4.4 4.4 0 0 0 5 1.6L1.3 5.4A4.3 4.3 0 0 0 .2 9.5c.1.5.3 1 .6 1.4l1.6-1.6a2 2 0 0 1-.2-1.2 2.2 2.2 0 0 1 .6-1.2L6.6 3l.8-.5a2.4 2.4 0 0 1 1.6 0l.7.5 3.9 3.8v.2l.1.2-.1.4-.4.1h-.3l-.1-.2-3.6-3.4L3 10.3a2.6 2.6 0 0 0-.8 1.9c0 .7.2 1.3.6 1.8.5.5 1 .7 1.7.8 0 .6.3 1.1.7 1.5.4.5 1 .7 1.6.8A2.6 2.6 0 0 0 9 19.4c0 .6.4 1.2.9 1.6a2.7 2.7 0 0 0 2.8.4l.9-.5 5-5v-3l-6.5 6.5H12Z" />
      <path
        d="M24 21.3v.1h1.6V20c.7 0 1.2-.3 1.7-.7.5-.5.7-1.1.7-2 0-.6-.2-1.2-.6-1.7-.4-.5-1-.8-1.8-1l-1.7-.7a.9.9 0 0 1-.4-.8c0-.3 0-.5.3-.7.2-.2.6-.3 1-.3.5 0 .8 0 1 .2.2.2.4.4.5.8h.2l1.2-.5v-.1c-.1-.5-.4-.9-.8-1.2-.3-.3-.8-.5-1.3-.6V9.3H24.1v1.4c-.5 0-1 .3-1.4.7-.5.4-.8 1-.8 1.7 0 .6.2 1.2.6 1.6.4.4 1 .8 2 1.1l1 .4c.4.1.6.3.7.4.2.2.3.5.3.8 0 .4-.1.7-.4.9a2 2 0 0 1-1.1.3c-.5 0-1-.2-1.3-.4-.3-.3-.5-.7-.7-1.2h-.1l-1.2.5h-.1c.2.8.5 1.3 1 1.7.4.4.9.7 1.5.8v1.3Z"
        stroke={fill}
        strokeWidth=".2"
      />
    </svg>
  );
};

ContractualPricing.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ContractualPricing;
