// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */

import React from 'react';
import Autocomplete from 'react-autocomplete';
import poweredByGoogle from '../images/powered_by_google_on_white_hdpi.png';
import stringOffsetReplace from '../lib/stringOffsetReplace';
import { uniqBy, filter } from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

class LocationAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      googleResults: [],
      geocodingResults: [],
      inputValue: props.inputValue,
      cachedPlaces: {},
      savedLocations: props.savedLocations,
      recentLocations: props.recentLocations,
      sessionToken: new google.maps.places.AutocompleteSessionToken()
    };

    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();

    // for some reason the PlacesService requires a "map" instance, we'll just
    // give it a DOM element ¯\_(ツ)_/¯
    const mapStub = $('<div></div>')[0];
    this.placesService = new google.maps.places.PlacesService(mapStub);

    this.placePredictionsCallback = this.placePredictionsCallback.bind(this);
    this.geocoderCallback = this.geocoderCallback.bind(this);
    this.performGeocode = this.performGeocode.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    if (this.state.inputValue > '') {
      this.runQueries(this.state.inputValue);
    }

    // This is here to initialize the autocomplete and pull data from google if
    // the server generated input has an initial place id.  It allows the caller
    // to have a fully initialized version of the input.
    if (this.props.initialPlaceId) {
      if (this.props.$wrapper?.closest('.form-group').hasClass('has-error')) {
        return;
      }
      this.onSelect(this.props.inputValue, this.initialItem());
    }
  }

  initialItem() {
    const found_item = this.state.savedLocations.find(
      item => item.place_id === this.props.initialPlaceId
    );
    if (found_item) {
      return found_item;
    }
    return { place_id: this.props.initialPlaceId };
  }
  items() {
    const allTheItems = [
      // Only show the first 3 items at a time
      ...this.state.savedLocations
        .map(item => {
          return {
            ...item,
            type: 'savedLocation',
            inputValue: item.description
          };
        })
        .slice(0, 3),

      // Only show the first 3 items at a time
      ...this.state.recentLocations
        .map(item => {
          return {
            ...item,
            type: 'recentLocation',
            inputValue: item.description
          };
        })
        .slice(0, 3),

      ...this.state.googleResults.map(item => {
        return { ...item, type: 'googleResult', inputValue: item.description };
      }),

      ...this.state.geocodingResults.map(item => {
        return {
          ...item,
          type: 'geocodingResult',
          description: item.formatted_address,
          inputValue: item.formatted_address
        };
      })
    ];

    return uniqBy(allTheItems, item => {
      return item.place_id;
    });
  }

  placePredictionsCallback(predictions) {
    this.setState({
      googleResults: predictions || [],
      geocodingResults: []
    });

    if (!predictions || predictions.length === 0) {
      this.setState({ loading: true });
      this.geocodingTimeout = setTimeout(this.performGeocode, 500);
    }
  }

  performGeocode() {
    this.geocoder.geocode(
      { address: this.state.inputValue },
      this.geocoderCallback
    );
  }

  geocoderCallback(results) {
    this.setState({ loading: false });
    this.setState({ geocodingResults: results || [] });
  }

  filterSavedLocationsByQuery(locationsList, query) {
    return filter(locationsList, item => {
      if (item.description.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        return true;
      }

      if (
        item.label &&
        item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return true;
      }

      return false;
    });
  }

  filterLocationsByQuery(locationsList, query) {
    return filter(locationsList, item => {
      if (item.description.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        return true;
      }

      return false;
    });
  }

  onChange(e) {
    const inputValue = e.target.value;
    this.setState({ inputValue: inputValue });
    clearTimeout(this.geocodingTimeout);

    if (this.props.onChange) {
      this.props.onChange();
    }

    // handle the case where the input is empty, we want to prefill with
    // recent and saved locations
    if (!inputValue) {
      this.setState({
        googleResults: [],
        savedLocations: this.props.savedLocations,
        recentLocations: this.props.recentLocations
      });
      return;
    }

    this.runQueries(inputValue);
  }

  runQueries(inputValue) {
    this.setState({
      savedLocations: this.filterSavedLocationsByQuery(
        this.props.savedLocations,
        inputValue
      ),
      recentLocations: this.filterLocationsByQuery(
        this.props.recentLocations,
        inputValue
      )
    });

    this.autocompleteService.getPlacePredictions(
      {
        input: inputValue,
        componentRestrictions: this.props.componentRestrictions ?? {
          country: ['US', 'CA', 'MX']
        },
        sessionToken: this.state.sessionToken
      },
      this.placePredictionsCallback
    );
  }

  onSelect(value, item) {
    this.setState({ inputValue: value });

    let valuesToOverride = {};
    if (item.phone_number) {
      valuesToOverride.formatted_phone_number = item.phone_number;
    }
    if (item.default_notes) {
      valuesToOverride.default_notes = item.default_notes;
    }
    if (item.saved_location_label) {
      valuesToOverride.saved_location_label = item.saved_location_label;
    }

    this.placesService.getDetails(
      {
        fields: [
          'address_component',
          'adr_address',
          'formatted_address',
          'geometry',
          'icon',
          'name',
          'photo',
          'place_id',
          'scope',
          'type',
          'url',
          'vicinity',
          'formatted_phone_number',
          'international_phone_number',
          'website'
        ],
        placeId: item.place_id,
        sessionToken: this.state.sessionToken
      },
      result => {
        this.setState({
          sessionToken: new google.maps.places.AutocompleteSessionToken()
        });
        // We merge the results from the places service with any relevant info
        // that was passed with the input field
        const resultWithMergedValues = Object.assign(
          {},
          result,
          valuesToOverride
        );

        this.props.onSelect(this.state.inputValue, resultWithMergedValues);
      }
    );
  }

  renderItem(item, isHighlighted) {
    const highlightedClass = isHighlighted ? 'active' : '';
    return (
      <div
        className={`autocomplete-menu-item autocomplete-menu-selectable ${highlightedClass}`}
        key={item.place_id}
      >
        {this.renderItemContents(item)}
      </div>
    );
  }

  renderItemContents(item) {
    switch (item.type) {
      case 'googleResult':
        return this.renderGoogleItem(item);
        break;
      case 'savedLocation':
        return this.renderSavedLocationItem(item);
        break;
      case 'recentLocation':
        return this.renderRecentLocationItem(item);
        break;
      case 'geocodingResult':
        return this.renderGeocodingItem(item);
        break;
    }
  }

  renderGoogleItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon" />
        <div className="autocomplete-menu-item-contents">
          <span>
            {stringOffsetReplace(
              item.description,
              item.matched_substrings,
              (match, i) => {
                return <strong key={match + i}>{match}</strong>;
              }
            )}
          </span>
        </div>
      </div>
    );
  }

  renderGeocodingItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon" />
        <div className="autocomplete-menu-item-contents">
          <div>{item.description}</div>
        </div>
      </div>
    );
  }

  renderWithHighlights(text, search) {
    const chunks = parse(text, match(text, search));

    return chunks.map((chunk, index) => {
      if (chunk.highlight) {
        return <strong key={`${index}`}>{chunk.text}</strong>;
      }

      return <span key={`${index}`}>{chunk.text}</span>;
    });
  }

  renderSavedLocationItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon">
          <i className="glyphicon glyphicon-bookmark" />
        </div>
        <div className="autocomplete-menu-item-contents">
          {this.renderWithHighlights(item.description, this.state.inputValue)}
          <div>
            <small className="text-muted">
              {this.renderWithHighlights(item.label, this.state.inputValue)}
            </small>
          </div>
        </div>
      </div>
    );
  }

  renderRecentLocationItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon">
          <i className="icon icon-history" />
        </div>
        <div className="autocomplete-menu-item-contents">
          <span>
            {this.renderWithHighlights(item.description, this.state.inputValue)}
          </span>
        </div>
      </div>
    );
  }

  renderSpinner() {
    return (
      <div className="autocomplete-menu-item">
        <div className="autocomplete-menu-item-icon">
          <i className="icon icon-spin1 animate-spin" />
        </div>
      </div>
    );
  }

  renderMenu(items) {
    // Return empty div if there's nothing to see
    if (!items || items.length < 1) {
      return <div />;
    }

    return (
      <div className="autocomplete-menu">
        {this.state.loading ? this.renderSpinner() : null}
        {items}
        <div className="text-center">
          <img
            className="autocomplete-googleimage"
            src={poweredByGoogle}
            alt=""
          />
        </div>
      </div>
    );
  }

  // Overrides the focus event of the input element, so that we can manually
  // trigger a change.  Otherwise the autocomplete shows the default results
  // until user starts typing
  onFocus(e) {
    this.onChange(e);
  }

  onBlur(e) {
    // Run an additional passed-in function if provided
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    // we only want to process this logic if there are no results
    if (this.items() && this.items().length > 0) {
      return;
    }

    // tell the caller that nothing was selected
    this.props.onSelect(this.state.inputValue, undefined);
  }

  render() {
    return (
      <Autocomplete
        getItemValue={item => {
          return item.inputValue;
        }}
        items={this.items()}
        renderItem={this.renderItem}
        inputProps={{
          className: this.props.inputClasses,
          id: this.props.inputId,
          name: this.props.inputName,
          placeholder: this.props.placeholder,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          onInvalid: this.props.onInvalid,
          required: this.props.required
        }}
        // Override the autoComplete attribute to something
        renderInput={props => {
          return (
            <input
              {...props}
              autoComplete={this.props.autoCompleteValue || 'off'}
            />
          );
        }}
        wrapperStyle={{}}
        wrapperProps={{ className: 'autocomplete' }}
        value={this.state.inputValue}
        onChange={this.onChange}
        onSelect={this.onSelect}
        renderMenu={this.renderMenu}
        autoHighlight={true}
        selectOnBlur={true}
      />
    );
  }
}

export default LocationAutocomplete;
