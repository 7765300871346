import React, { useState } from 'react';
import { parseISO, formatISO9075 } from 'date-fns';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';

export default function DriverEngagements({ props }) {
  const [startedAtOverride, setStartedAtOverride] = useState(
    initializeStartedAtOverride()
  );
  const [completedAtOverride, setCompletedAtOverride] = useState(
    initializeCompletedAtOverride()
  );
  const [notes, setNotes] = useState({});
  const [engagedTime, setEngagedTime] = useState(initializeEngagedTimeState());
  const {
    can_edit,
    engagements,
    driver_id,
    current_user_id,
    csrf_token
  } = props;

  function initializeEngagedTimeState() {
    let engagedTimeObject = {};
    props['engagements'].map(engagement => {
      engagedTimeObject[
        `engagedTime-${engagement.id}`
      ] = engagement.engaged_time
        ? Math.round(engagement.engaged_time / 60)
        : 0;
    });
    return engagedTimeObject;
  }

  function initializeStartedAtOverride() {
    let startedAtObject = {};
    props['engagements'].map(engagement => {
      startedAtObject[
        `startAtOverride-${engagement.id}`
      ] = engagement.started_at_override
        ? new Date(engagement.started_at_override)
        : '';
    });
    return startedAtObject;
  }

  function initializeCompletedAtOverride() {
    let completedAtObject = {};
    props['engagements'].map(engagement => {
      completedAtObject[
        `completeAtOverride-${engagement.id}`
      ] = engagement.completed_at_override
        ? new Date(engagement.completed_at_override)
        : '';
    });
    return completedAtObject;
  }

  function getTotalTime() {
    return Object.values(engagedTime).reduce((a, b) => a + b, 0);
  }

  function updateEngagedTime(driver_engagement_id, new_time) {
    setEngagedTime({
      ...engagedTime,
      [`engagedTime-${driver_engagement_id}`]: Math.round(new_time)
    });
  }

  function setPayPeriodFilter(date) {
    let url = new URL(window.location.href);
    url.searchParams.set('payperiod', date.toISOString());
    location.assign(url);
  }

  function getPayPeriodFilter() {
    let urlParams = new URLSearchParams(window.location.search);
    let currentPayPeriod = urlParams.get('payperiod');
    if (currentPayPeriod) {
      return new Date(currentPayPeriod);
    } else {
      let defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate() - 1 * 7);
      return defaultDate;
    }
  }

  function save(
    driver_engagement_id,
    started_at_override,
    completed_at_override,
    notes
  ) {
    let updateBody = {
      driver_engagement_id,
      last_overridden_by: current_user_id,
      started_at_override,
      completed_at_override,
      notes
    };

    fetch(`/drivers/${driver_id}/driver_engagements/`, {
      method: 'PATCH',
      body: JSON.stringify(updateBody),
      headers: {
        'X-CSRF-Token': csrf_token,
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then(response => response.json())
      .then(data =>
        updateEngagedTime(driver_engagement_id, data['new_engaged_time'])
      );
  }

  return (
    <div className="table-responsive table-sortable">
      <table className="table pretty table-hover">
        <thead>
          <tr>
            <th>Order</th>
            <th>Started At</th>
            <th>Started At Override</th>
            <th>Completed At</th>
            <th>Completed At Override</th>
            <th>Calculated Hours</th>
            <th>Engaged Miles</th>
            <th>Notes</th>
            <th>
              <label htmlFor="pay-period-filter">
                Pay Period Filter
                <DatePicker
                  className="form-control"
                  id="pay-period-filter"
                  name="pay-period-filter"
                  selected={getPayPeriodFilter()}
                  placeholderText="Select a pay period"
                  popperPlacement="left"
                  onChange={event => setPayPeriodFilter(event)}
                  minDate={new Date(2022, 5, 1)}
                  maxDate={new Date()}
                  openToDate={new Date()}
                />
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          {engagements.map(engagement => {
            const startedAt = parseISO(engagement.started_at);
            const completedAt = parseISO(engagement.completed_at);

            return (
              <tr key={`row-${engagement.id}`}>
                <td key={`orderID-${engagement.id}`}>
                  <a
                    href={`${window.location.origin}/orders/${engagement.order_id}`}
                  >
                    {engagement.order_id}
                  </a>
                </td>
                <td key={`startedAt-${engagement.id}`}>
                  {engagement.started_at
                    ? formatISO9075(startedAt, { representation: 'complete' })
                    : ''}
                </td>
                <td>
                  <label
                    htmlFor={`startAtOverride-${engagement.id}`}
                    className="visually-hidden"
                  >
                    Started At Override
                  </label>
                  <DatePicker
                    className="form-control"
                    id={`startAtOverride-${engagement.id}`}
                    name={`startAtOverride-${engagement.id}`}
                    readOnly={!can_edit}
                    onChange={event =>
                      setStartedAtOverride({
                        ...startedAtOverride,
                        [`startAtOverride-${engagement.id}`]: event
                      })
                    }
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    selected={
                      startedAtOverride[`startAtOverride-${engagement.id}`]
                    }
                    dateFormat="MM/dd/yyyy HH:mm:ss"
                  />
                </td>
                <td key={`completedAt-${engagement.id}`}>
                  <div>
                    {engagement.completed_at
                      ? formatISO9075(completedAt, {
                          representation: 'complete'
                        })
                      : ''}
                  </div>
                  <div>{engagement.completed_reason}</div>
                </td>
                <td>
                  <label
                    htmlFor={`completeAtOverride-${engagement.id}`}
                    className="visually-hidden"
                  >
                    Completed At Override
                  </label>
                  <DatePicker
                    className="form-control"
                    id={`completeAtOverride-${engagement.id}`}
                    name={`completeAtOverride-${engagement.id}`}
                    readOnly={!can_edit}
                    onChange={event =>
                      setCompletedAtOverride({
                        ...completedAtOverride,
                        [`completeAtOverride-${engagement.id}`]: event
                      })
                    }
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    selected={
                      completedAtOverride[`completeAtOverride-${engagement.id}`]
                    }
                    dateFormat="MM/dd/yyyy HH:mm:ss"
                  />
                </td>
                <td>{engagedTime[`engagedTime-${engagement.id}`]}</td>
                <td>{Math.round(engagement.mileage)}</td>
                <td>
                  <input
                    className="form-control"
                    readOnly={!can_edit}
                    defaultValue={engagement.override_notes || ''}
                    onChange={event =>
                      setNotes({
                        ...notes,
                        [`notes-${engagement.id}`]: event.target.value
                      })
                    }
                  ></input>
                </td>
                <td>
                  <label
                    htmlFor={`save-${engagement.id}`}
                    className="visually-hidden"
                  >
                    Save engagement {engagement.id}
                  </label>
                  <button
                    className="form-control btn-xs btn-primary"
                    id={`save-${engagement.id}`}
                    disabled={!can_edit}
                    onClick={() =>
                      save(
                        engagement.id,
                        startedAtOverride[`startAtOverride-${engagement.id}`],
                        completedAtOverride[
                          `completeAtOverride-${engagement.id}`
                        ],
                        notes[`notes-${engagement.id}`]
                      )
                    }
                  >
                    Save
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Totals</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{getTotalTime()} hours</td>
            <td>
              {engagements
                .map(engagement => Math.round(engagement.mileage))
                .reduce((a, b) => a + b, 0)}{' '}
              miles
            </td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

DriverEngagements.propTypes = {
  props: PropTypes.object,
  can_edit: PropTypes.bool,
  engagements: PropTypes.arrayOf(PropTypes.object),
  driver_id: PropTypes.number,
  current_user_id: PropTypes.number,
  csrf_token: PropTypes.string
};
