import React from 'react';
import PropTypes from 'prop-types';
import {
  FEEDBACK_POOR_RATING,
  FEEDBACK_OKAY_RATING,
  FEEDBACK_GREAT_RATING
} from '../CustomerReview/reviewFeedbackOptions';
import frowningFace from '../../../../assets/images/emoji-frowning-face.png';
import neutralFace from '../../../../assets/images/emoji-neutral-face.png';
import grinningFace from '../../../../assets/images/emoji-grinning-face.png';

const RATINGS = [
  FEEDBACK_POOR_RATING,
  FEEDBACK_OKAY_RATING,
  FEEDBACK_GREAT_RATING
];
const images = {
  [`${FEEDBACK_POOR_RATING}`]: frowningFace,
  [`${FEEDBACK_OKAY_RATING}`]: neutralFace,
  [`${FEEDBACK_GREAT_RATING}`]: grinningFace
};

const RatingSelection = ({ rating, handleRatingChange }) => (
  <div className="flex justify-center">
    {RATINGS.map(emojiRating => (
      <button
        key={emojiRating}
        onClick={() => handleRatingChange(emojiRating)}
        style={{
          opacity: ['', emojiRating].includes(rating) ? 1 : 0.3,
          marginRight: '8px'
        }}
      >
        <img
          src={images[emojiRating]}
          alt={emojiRating}
          style={{ height: '72px', width: '72px' }}
        />
        <div className="text-lg font-semibold capitalize mt-8 text-navy">
          {emojiRating}
        </div>
      </button>
    ))}
  </div>
);

RatingSelection.defaultProps = {
  rating: ''
};

RatingSelection.propTypes = {
  rating: PropTypes.oneOf(['', 'poor', 'great', 'okay']).isRequired,
  handleRatingChange: PropTypes.func.isRequired
};

export default RatingSelection;
