import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OrganizationInput from '../src/components/OrganizationInput/OrganizationInput';
import Button from '../src/components/Button/Button';

const OrganizationReportSearch = ({ search_url }) => {
  const [selectedOrgId, setSelectedOrgId] = useState();

  return (
    <div>
      <OrganizationInput
        id="organization-search"
        label="Select an Organization"
        value=""
        onSelect={item => setSelectedOrgId(item)}
        searchUrl={search_url}
      />

      <Button
        disabled={!selectedOrgId}
        href={selectedOrgId ? `/reports/organizations/${selectedOrgId}` : ''}
        style="primary"
      >
        Get Reports
      </Button>
    </div>
  );
};

OrganizationReportSearch.propTypes = {
  search_url: PropTypes.string
};

export default OrganizationReportSearch;
