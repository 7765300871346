/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import each from 'lodash/each';
import filter from 'lodash/filter';
import eq from 'lodash/eq';
import truncate from 'lodash/truncate';
import includes from 'lodash/includes';
import has from 'lodash/has';
import some from 'lodash/some';
import useUIStore from '../hooks/uiStore';
import EnableConnectWizardControls from './EnableConnectWizardControls';
import UserForm from './UserForm';
import Modal from './Modal';

const EnableConnectWizardStep2 = ({
  authenticityToken,
  organization,
  users,
  backDisabled,
  nextDisabled,
  onAddUser,
  onBack,
  onNext
}) => {
  const { setModal, clearModal } = useUIStore();
  const roles = [
    'connect_web_admin',
    'connect_web',
    'connect_web_read_only',
    'connect_driver'
  ];
  const webRoles = filter(roles, role => !eq(role, 'connect_driver'));
  const [form, setForm] = useState(null);
  const [error, setError] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [userErrors, setUserErrors] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    if (isNil(users)) return;

    const newForm = {};
    each(users, user => {
      newForm[user.id] = { role_memberships: {} };
      each(roles, role => {
        newForm[user.id]['role_memberships'][role] = includes(
          user.connect_roles,
          role
        ).toString();
      });
    });

    setForm(newForm);
  }, [users]);

  const isRoleDisabled = (user, role) => {
    if (isNil(form) || eq(role, 'connect_driver')) return false;

    let hasWebRole = false;
    each(webRoles, webRole => {
      hasWebRole =
        !eq(role, webRole) &&
        eq(form[user.id]['role_memberships'][webRole], 'true');
      if (hasWebRole) return false;
    });

    return hasWebRole;
  };

  const hasWebAdminRole = () => {
    let hasWebAdminRole = false;
    each(form, value => {
      hasWebAdminRole = eq(
        value['role_memberships']['connect_web_admin'],
        'true'
      );
      if (hasWebAdminRole) return false;
    });

    return hasWebAdminRole;
  };

  const allUsersHaveRole = () => {
    let allHaveRole = true;
    each(form, value => {
      allHaveRole = some(roles, role =>
        eq(value['role_memberships'][role], 'true')
      );
      if (!allHaveRole) return false;
    });

    return allHaveRole;
  };

  const handleRoleChange = (user, role) => {
    setError(false);

    const newForm = { ...form };
    const hasRole = eq(form[user.id]['role_memberships'][role], 'true');
    newForm[user.id]['role_memberships'][role] = (!hasRole).toString();
    setForm(newForm);
  };

  const handleNext = () => {
    if (isEmpty(users) || !hasWebAdminRole()) {
      setError(true);
      return;
    }

    if (!allUsersHaveRole()) {
      setModal({ id: 'users-without-connect-role' });
      return;
    }

    handleContinueNext();
  };

  const handleContinueNext = () => {
    onNext({ organization_id: organization.id, users: form });
  };

  const handleCreateUser = () => {
    setError(false);
    setUserErrors({});
    setShowUserForm(true);
  };

  const handleCancel = () => {
    setShowUserForm(false);
  };

  const handleSubmit = formValues => {
    const payload = new FormData();
    payload.append('organization_id', organization?.id);

    each(['given_name', 'family_name', 'email', 'phone_number'], attr => {
      payload.append(attr, formValues[attr]);
    });

    each(roles, role => {
      payload.append(`role_memberships[${role}]`, formValues[role]);
    });

    if (!isNil(formValues.profile_image)) {
      payload.append('profile_image', formValues.profile_image);
    }

    setSubmitDisabled(true);

    fetch('/connect/users', {
      method: 'POST',
      headers: { 'X-CSRF-Token': authenticityToken },
      body: payload
    })
      .then(response => response.json())
      .then(user => {
        if (has(user, 'errors')) {
          console.log(user.errors);
          setUserErrors(user.errors);
          return;
        }

        onAddUser(user);
        setShowUserForm(false);
      })
      .catch(error => console.log(error.message))
      .finally(() => setSubmitDisabled(false));
  };

  const instructions = () => {
    return (
      <>
        <div
          className={`cx_l--width-60 ${
            isEmpty(users) ? 'cx_l--margin-bottom-24' : ''
          }`}
        >
          {isEmpty(users)
            ? 'To add Connect permissions for a user click create user, enter their information and add permissions.'
            : 'To add Connect permissions for a user check the corresponding box for the role you want them to have or create a new user by clicking create user.'}{' '}
          Find permissions for each role in this{' '}
          <a
            className="cx_t--underline cx_t--lightBlue"
            target="_blank"
            rel="noreferrer"
            href="https://support.dispatchit.com/how-to-create-a-new-connect-user-or-driver"
          >
            article.
          </a>
        </div>
        <div>
          <button
            className="cx_button cx_button--alpha"
            onClick={handleCreateUser}
          >
            Create User
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="cx_t cx_t--l cx_t--600 cx_t--blue cx_l--margin-bottom-24">
        User Set Up
      </div>
      {showUserForm && (
        <>
          <div className="cx_l--width-60 cx_l--margin-bottom-24">
            Create a user or pick one out of your list. Be sure to set their
            permissions in Connect. You can find the permission explanations{' '}
            <a
              className="cx_t--underline cx_t--lightBlue"
              target="_blank"
              rel="noreferrer"
              href="https://support.dispatchit.com/how-to-create-a-new-connect-user-or-driver"
            >
              here.
            </a>
          </div>
          <div className="cx_l--width-45">
            <h3 className="cx_t cx_t--s cx_t--blue cx_l--blue cx_l--margin-bottom-12">
              Create User
            </h3>
            <UserForm
              userErrors={userErrors}
              submitDisabled={submitDisabled}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </>
      )}
      {isEmpty(users) && !showUserForm && (
        <>
          {instructions()}
          {error && (
            <span className="cx_t cx_t--xxs cx_t--red cx_l--margin-top-8">
              You must create at least one Web App Administrator.
            </span>
          )}
        </>
      )}
      {!isEmpty(users) && !showUserForm && (
        <>
          <div className="cx_l--flex cx_l--flex-justify-between cx_l--margin-bottom-30">
            {instructions()}
          </div>
          <table className="cx_table cx_table--alpha cx_table--border-collapse">
            <colgroup>
              <col style={{ width: '24%' }} />
              <col style={{ width: '14%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '14%' }} />
              <col style={{ width: '18%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <thead className="cx_table__head">
              <tr className="cx_table__row">
                <th className="cx_table__heading cx_l--padding-left-16">
                  Email
                </th>
                <th className="cx_table__heading">Name</th>
                <th className="cx_table__heading cx_t--text-align-center">
                  Web App Administrator
                </th>
                <th className="cx_table__heading cx_t--text-align-center">
                  Web App User
                </th>
                <th className="cx_table__heading cx_t--text-align-center">
                  Web App Read Only
                </th>
                <th className="cx_table__heading cx_t--text-align-center">
                  Driver
                </th>
              </tr>
            </thead>
            <tbody
              className={`cx_table__body ${
                error ? 'cx_c--border-red' : 'cx_c--border-lightGray'
              }`}
            >
              {map(users, (user, index) => (
                <tr
                  key={user.id}
                  className={`cx_table__row cx_table__row--border-none ${
                    eq(index % 2, 0)
                      ? 'cx_c--background-color-lightestGray'
                      : ''
                  }`}
                >
                  <td className="cx_table__column">
                    <span className="cx_t cx_t--xs">
                      {truncate(user.email, { length: 35 })}
                    </span>
                  </td>
                  <td className="cx_table__column">
                    <span className="cx_t cx_t--xs">
                      {truncate(user.given_name, { length: 15 })}{' '}
                      {truncate(user.family_name, { length: 15 })}
                    </span>
                  </td>
                  {map(roles, role => (
                    <td
                      key={role}
                      className="cx_table__column cx_t--text-align-center cx_l--padding-right-0"
                    >
                      <div className="cx_field cx_field--checkbox cx_field--checkbox-l cx_l--width-auto">
                        <input
                          className="cx_field__input"
                          id={`user-${user.id}-${role}`}
                          name={`user-${user.id}-${role}`}
                          type="checkbox"
                          checked={
                            !isNil(form) &&
                            eq(form[user.id]['role_memberships'][role], 'true')
                          }
                          onChange={() => handleRoleChange(user, role)}
                          disabled={isRoleDisabled(user, role)}
                        />
                        <label
                          className="cx_field__label"
                          htmlFor={`user-${user.id}-${role}`}
                        >
                          {' '}
                        </label>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {error && (
            <span className="cx_t cx_t--xxs cx_t--red cx_l--margin-top-8">
              You must choose at least one Web App Administrator.
            </span>
          )}
        </>
      )}

      {!showUserForm && (
        <EnableConnectWizardControls
          backDisabled={backDisabled}
          nextDisabled={nextDisabled}
          onBack={onBack}
          onNext={handleNext}
        />
      )}

      <Modal id="users-without-connect-role">
        <>
          <button
            className="cx_button cx_i cx_i--m cx_i--blue-close-x cx_l--float-right"
            onClick={clearModal}
          />
          <div className="cx_t cx_t--xs cx_t--600 cx_l--margin-bottom-24 cx_l--margin-right-36">
            You haven&#39;t assigned a connect role to every user in this
            organization, are you sure you want to continue?
          </div>
          <button
            className="cx_button cx_button--alpha"
            onClick={() => {
              clearModal();
              handleContinueNext();
            }}
          >
            Yes
          </button>
        </>
      </Modal>
    </>
  );
};

EnableConnectWizardStep2.defaultProps = {
  organization: null,
  users: null,
  backDisabled: false,
  nextDisabled: false
};

EnableConnectWizardStep2.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  organization: PropTypes.shape({ id: PropTypes.string }),
  users: PropTypes.array,
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  onAddUser: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default EnableConnectWizardStep2;
