import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import eq from 'lodash/eq';

const SmsEdit = ({
  customSmsText,
  onChangeTextArea,
  maxSmsLength,
  defaultSmsText,
  isEditedSmsText
}) => {
  const textAareaRef = useRef(null);

  useEffect(() => {
    textAareaRef.current.focus();
  }, []);

  const value = isEditedSmsText
    ? isEmpty(customSmsText)
      ? ''
      : eq(customSmsText, defaultSmsText)
      ? defaultSmsText
      : customSmsText
    : isEmpty(customSmsText) || eq(customSmsText, defaultSmsText)
    ? defaultSmsText
    : customSmsText;

  return (
    <textarea
      ref={textAareaRef}
      name="SMS edit"
      maxLength={maxSmsLength}
      className="w-full h-[170px] border border-navyLight-300 py-4 px-3 focus:ring-0"
      onChange={onChangeTextArea}
      value={value}
    />
  );
};

SmsEdit.propTypes = {
  customSmsText: PropTypes.string,
  onChangeTextArea: PropTypes.func,
  maxSmsLength: PropTypes.number,
  defaultSmsText: PropTypes.string,
  isEditedSmsText: PropTypes.bool
};

export default SmsEdit;
