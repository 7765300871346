/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from '../store';
import isNil from 'lodash/isNil';
import eq from 'lodash/eq';
import size from 'lodash/size';
import map from 'lodash/map';
import lte from 'lodash/lte';
import useUIStore from '../hooks/uiStore';
import Toasts from './Toasts';
import EnableConnectWizardStep1 from './EnableConnectWizardStep1';
import EnableConnectWizardStep2 from './EnableConnectWizardStep2';
import EnableConnectWizardStep3 from './EnableConnectWizardStep3';

const EnableConnectWizardWrapped = ({ organization, authenticityToken }) => {
  const { addToast } = useUIStore();
  const steps = [
    { value: 1, label: 'Organization Settings' },
    { value: 2, label: 'Create Connect User' },
    { value: 3, label: 'Done!' }
  ];
  const [org, setOrg] = useState({ ...organization });
  const [users, setUsers] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [backDisabled, setBackDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    if (eq(currentStep, 2)) {
      if (isNil(org?.connect_organization) || !isNil(users)) return;

      fetch(`/admin/organizations/${org.id}/connect_wizard/users`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(result => result.json())
        .then(users => setUsers(users))
        .catch(error => console.log(error.message));
    }
  }, [currentStep]);

  const handleBack = () => {
    if (eq(currentStep, 1)) return;

    if (nextDisabled) setNextDisabled(false);

    if (eq(currentStep - 1, 1)) setBackDisabled(true);

    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (eq(currentStep, size(steps))) return;

    if (backDisabled) setBackDisabled(false);

    if (eq(currentStep + 1, size(steps))) setNextDisabled(true);

    setCurrentStep(currentStep + 1);
  };

  const handleBackStep1 = () => {
    setBackDisabled(true);
    window.location.replace(`/admin/organizations/${org.id}`);
  };

  const handleNextStep1 = formValues => {
    setNextDisabled(true);

    fetch(
      `/admin/organizations/${organization.id}/connect_wizard/enable_connect`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues)
      }
    )
      .then(response => response.json())
      .then(response => {
        setOrg(response);
        handleNext();
      })
      .catch(error => console.log(error.message))
      .finally(() => setNextDisabled(false));
  };

  const handleNextStep2 = formValues => {
    setNextDisabled(true);

    fetch(`/admin/organizations/${org.id}/connect_wizard/update_users`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formValues)
    })
      .then(response => response.json())
      .then(response => {
        setUsers(response);
        handleNext();
      })
      .catch(error => console.log(error.message))
      .finally(() => setNextDisabled(false));
  };

  const handleNextStep3 = () => {
    setNextDisabled(true);
    window.location.replace(`/admin/organizations/${org.id}`);
  };

  const handleAddUser = user => {
    setUsers([...users, user]);
    addToast({
      id: 'add-user',
      type: 'success',
      message: { title: `Your email has been sent to ${user.email}` }
    });
  };

  return (
    <Provider store={store}>
      <Toasts />
      <ul className="cx_circle-steps">
        {map(steps, step => (
          <li
            key={step.value}
            className={`cx_circle-step ${
              eq(currentStep, step.value) ? 'cx_circle-step--active' : ''
            }`}
          >
            <div className="cx_circle-step__button">
              {lte(currentStep, step.value) ? step.value : null}
            </div>
            <div className="cx_circle-step__label">{step.label}</div>
          </li>
        ))}
      </ul>

      <div className="cx_l--margin-top-64">
        {eq(currentStep, 1) && (
          <EnableConnectWizardStep1
            organization={org}
            backDisabled={backDisabled}
            nextDisabled={nextDisabled}
            onBack={handleBackStep1}
            onNext={handleNextStep1}
          />
        )}

        {eq(currentStep, 2) && (
          <EnableConnectWizardStep2
            authenticityToken={authenticityToken}
            organization={org}
            users={users}
            backDisabled={backDisabled}
            nextDisabled={nextDisabled}
            onAddUser={handleAddUser}
            onBack={handleBack}
            onNext={handleNextStep2}
          />
        )}

        {eq(currentStep, 3) && (
          <EnableConnectWizardStep3
            users={users}
            backDisabled={backDisabled}
            nextDisabled={nextDisabled}
            onBack={handleBack}
            onNext={handleNextStep3}
          />
        )}
      </div>
    </Provider>
  );
};

const EnableConnectWizard = initialProps => {
  return (
    <Provider store={store}>
      <EnableConnectWizardWrapped {...initialProps} />
    </Provider>
  );
};

const sharedPropTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
    connect_organization: PropTypes.shape({
      id: PropTypes.string,
      vehicle_capacity_unit: PropTypes.string,
      unit_description: PropTypes.string,
      subscription: PropTypes.shape({
        id: PropTypes.string,
        number_of_vehicles: PropTypes.number,
        subscription_type: PropTypes.string,
        trial_start_date: PropTypes.string,
        trial_end_date: PropTypes.string,
        service_start_date: PropTypes.string,
        service_end_date: PropTypes.string
      })
    })
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired
};

EnableConnectWizard.propTypes = sharedPropTypes;
EnableConnectWizardWrapped.propTypes = sharedPropTypes;

export default EnableConnectWizard;
