import React from 'react';
import PropTypes from 'prop-types';
import {
  formattedTime,
  formattedTimeAndDate,
  formattedAddressSingleLine
} from '../../utils';
import { isAfter, differenceInMinutes } from 'date-fns';
import Glyphicon from '../Glyphicon/Glyphicon';
import pickupIcon from '../../assets/icon-pickup-white.svg';
import dropOffIcon from '../../assets/icon-drop-off-white.svg';
import warningIcon from '../../assets/warning.svg';
import driverAssignedIcon from '../../assets/icon-check-thin-white.svg';
import clockIcon from '../../assets/clock.svg';
import Tooltip from '../Tooltip/Tooltip';

const OrderTimeline = props => {
  const {
    cancelledTime,
    deliveryArrivedTime,
    deliveryCompletedTime,
    deliveryDueTime,
    deliveryEstimatedTime,
    shouldDisplayDriver,
    driver,
    dropOffs,
    isDropOffLate,
    isInternalUser,
    isPickupLate,
    orderStatus,
    pickedUpTime,
    pickupAddress,
    pickupArrivedTime,
    pickupEstimatedTime,
    schedule,
    timeAvailable,
    notificationEnhancements,
    useV2Pricing
  } = props;

  const getDriverSegmentClass = () => {
    if (
      ['scheduled', 'claimed', 'unassigned', 'driver_assigned'].includes(
        orderStatus
      )
    ) {
      return '';
    } else {
      return 'segment--in-progress';
    }
  };

  // PICKUP STUFF

  const getPickupSLAWindow = () => {
    return `${formattedTime(new Date(schedule.pickup_window_start_at))}
    - ${formattedTime(new Date(schedule.pickup_window_end_at))}`;
  };

  const isPickupSLAMissed =
    pickupArrivedTime &&
    schedule?.pickup_window_end_at &&
    isAfter(
      new Date(pickupArrivedTime),
      new Date(schedule.pickup_window_end_at)
    );

  const getPickupStatus = () => {
    switch (orderStatus) {
      case 'estimate':
      case 'scheduled':
        return (
          <h3 className="mt-0 mb-2 text-xl inline-block">
            Scheduled for {formattedTimeAndDate(new Date(timeAvailable))}
          </h3>
        );
      case 'unassigned':
      case 'driver_assigned':
      default:
        return (
          <h3 className="mt-0 mb-2 text-xl inline-block">
            Latest pickup by{' '}
            {formattedTimeAndDate(new Date(schedule.pickup_window_end_at))}
          </h3>
        );
      case 'en_route_to_pickup':
        if (pickupEstimatedTime) {
          return (
            <h3 className="mt-0 mb-2 text-xl inline-block">
              Estimated pickup at {formattedTime(new Date(pickupEstimatedTime))}
            </h3>
          );
        } else {
          return (
            <h3 className="mt-0 mb-2 text-xl inline-block">
              Latest pickup by{' '}
              {formattedTime(new Date(schedule.pickup_window_end_at))}
            </h3>
          );
        }
      case 'at_pickup':
        return <h3 className="mt-0 mb-2 text-xl inline-block">At pickup</h3>;
      case 'out_for_delivery':
      case 'en_route_to_drop_off':
      case 'at_drop_off':
      case 'delivered':
        return (
          <div className="text-muted inline-block">
            <>
              Arrived to pickup at {formattedTime(new Date(pickupArrivedTime))}
            </>
          </div>
        );
      case 'cancelled':
        return (
          <h3 className="mt-0 mb-2 text-xl inline-block">
            Canceled at {formattedTime(new Date(cancelledTime))}
          </h3>
        );
    }
  };

  const getPickupTimeRange = () => {
    switch (orderStatus) {
      case 'estimate':
      case 'scheduled':
      case 'unassigned':
      case 'driver_assigned':
      default:
        return useV2Pricing ? '' : `Estimated from ${getPickupSLAWindow()}`;
      case 'en_route_to_pickup':
        if (pickupEstimatedTime) {
          return isPickupLate
            ? 'Running behind'
            : useV2Pricing
            ? ''
            : `Latest arrival ${formattedTime(
                new Date(schedule.pickup_window_end_at)
              )}`;
        } else {
          return useV2Pricing ? '' : `Estimated from ${getPickupSLAWindow()}`;
        }
      case 'at_pickup':
        if (pickupArrivedTime) {
          return `Estimated ${differenceInMinutes(
            new Date(),
            new Date(pickupArrivedTime)
          )} minutes at pickup`;
        } else return '';
      case 'out_for_delivery':
      case 'en_route_to_drop_off':
      case 'at_drop_off':
      case 'delivered':
        if (pickedUpTime && pickupArrivedTime) {
          return `Estimated ${differenceInMinutes(
            new Date(pickedUpTime),
            new Date(pickupArrivedTime)
          )} minutes at pickup`;
        } else return '';
      case 'cancelled':
        return '';
    }
  };

  const getPickupClass = () => {
    if (
      [
        'scheduled',
        'unassigned',
        'claimed',
        'driver_assigned',
        'en_route_to_pickup'
      ].includes(orderStatus)
    ) {
      return 'segment--incomplete';
    } else if (
      [
        'out_for_delivery',
        'en_route_to_drop_off',
        'at_drop_off',
        'delivered'
      ].includes(orderStatus)
    ) {
      return 'segment--in-progress';
    } else {
      return '';
    }
  };

  // DROP-OFF STUFF

  const getDropOffSLAWindow = () => {
    return `${formattedTime(
      new Date(schedule.drop_off_window_start_at)
    )} - ${formattedTime(new Date(schedule.drop_off_window_end_at))}`;
  };

  const isDropOffSLAMissed =
    deliveryCompletedTime &&
    deliveryDueTime &&
    isAfter(new Date(deliveryCompletedTime), new Date(deliveryDueTime));

  const getDropOffStatus = () => {
    switch (orderStatus) {
      case 'estimate':
      case 'scheduled':
      case 'unassigned':
      case 'en_route_to_pickup':
      case 'at_pickup':
      case 'out_for_delivery':
      default:
        return `Drop-off by ${formattedTimeAndDate(new Date(deliveryDueTime))}`;
      case 'en_route_to_drop_off':
        if (deliveryEstimatedTime) {
          return `Estimated drop-off ${formattedTime(
            new Date(deliveryEstimatedTime)
          )}`;
        } else {
          return `Drop-off by ${formattedTimeAndDate(
            new Date(deliveryDueTime)
          )}`;
        }
      case 'at_drop_off':
        return 'At drop-off';
      case 'delivered':
        return `Completed at ${formattedTime(new Date(deliveryCompletedTime))}`;
      case 'cancelled':
        return `Order Canceled`;
    }
  };

  const getDropOffTimeRange = () => {
    switch (orderStatus) {
      case 'estimate':
      case 'scheduled':
      case 'unassigned':
      case 'en_route_to_pickup':
      case 'at_pickup':
      case 'out_for_delivery':
      default:
        return useV2Pricing ? '' : `Estimated from ${getDropOffSLAWindow()}`;
      case 'en_route_to_drop_off':
        if (deliveryEstimatedTime) {
          return isDropOffLate
            ? 'Running behind'
            : useV2Pricing
            ? ''
            : `Latest arrival ${formattedTime(
                new Date(schedule.drop_off_window_end_at)
              )}`;
        } else {
          return useV2Pricing ? '' : `Estimated from ${getDropOffSLAWindow()}`;
        }
      case 'at_drop_off':
        if (deliveryArrivedTime) {
          return `Estimated ${differenceInMinutes(
            new Date(),
            new Date(deliveryArrivedTime)
          )} minutes at drop-off`;
        } else return '';
      case 'delivered':
        if (deliveryArrivedTime) {
          if (dropOffs.length > 1) {
            return `Arrived to last drop-off at ${formattedTime(
              new Date(deliveryArrivedTime)
            )}`;
          } else {
            return `Arrived to drop-off at ${formattedTime(
              new Date(deliveryArrivedTime)
            )}`;
          }
        } else {
          return '';
        }
      case 'cancelled':
        return '';
    }
  };

  const getDropOffClass = () => {
    if (
      [
        'scheduled',
        'unassigned',
        'claimed',
        'driver_assigned',
        'en_route_to_pickup',
        'at_pickup',
        'out_for_delivery',
        'en_route_to_drop_off',
        'cancelled'
      ].includes(orderStatus)
    ) {
      return 'segment--incomplete';
    } else {
      return '';
    }
  };

  return (
    <>
      {shouldDisplayDriver &&
        (driver ? (
          <div className={`segment ${getDriverSegmentClass()}`}>
            <div className="segment__icon">
              <img src={driverAssignedIcon} alt="" />
            </div>
            <div className="segment__content leading-tight mb-8">
              <div className="text-muted">Driver assigned</div>
              <div>{driver}</div>
            </div>
          </div>
        ) : (
          <>
            {orderStatus !== 'cancelled' && (
              <div className={`segment ${getDriverSegmentClass()}`}>
                <div className="segment__icon">
                  <Glyphicon name="option-horizontal" />
                </div>
                <div className="segment__content mb-8">
                  <div className="text-muted mt-3">
                    {notificationEnhancements
                      ? 'order submitted'
                      : 'searching for driver...'}
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      <div className={`segment ${getPickupClass()}`}>
        <div className="segment__icon">
          <img src={pickupIcon} alt="" />
        </div>
        <div className="segment__content mb-6">
          {getPickupStatus()}
          {isInternalUser &&
            (orderStatus === 'delivered' ||
              orderStatus === 'cancelled' ||
              isPickupLate) && (
              <>
                <Tooltip
                  text={`Initial SLA window: ${getPickupSLAWindow()}`}
                  width={280}
                >
                  <img
                    src={clockIcon}
                    alt={`Initial SLA window: ${getPickupSLAWindow()}`}
                    className="inline-block"
                    style={{ verticalAlign: -2 }}
                  />
                </Tooltip>
                {isPickupSLAMissed && (
                  <span className="bg-red-400 text-white text-sm rounded-full px-3 ml-2 inline-block">
                    {differenceInMinutes(
                      new Date(pickupArrivedTime),
                      new Date(schedule.pickup_window_end_at)
                    )}{' '}
                    min late
                  </span>
                )}
              </>
            )}

          {schedule && (
            <div className="text-muted" id="route-pickup-window">
              {isPickupLate && orderStatus === 'en_route_to_pickup' && (
                <Tooltip
                  text="It looks like your driver is running behind schedule. We have updated the estimated arrival times and apologize for any inconvenience."
                  placement="bottom-right"
                  className="ml-0 mr-2"
                  width={280}
                >
                  <img
                    src={warningIcon}
                    alt="Running Late"
                    className="w-6 inline-block"
                    style={{ verticalAlign: -2 }}
                  />
                </Tooltip>
              )}
              {getPickupTimeRange()}
            </div>
          )}

          <ul className="checklist">
            <li
              className={`checklist__item ${
                orderStatus === 'cancelled'
                  ? 'checklist__item--false'
                  : 'checklist__item--invisible'
              }`}
            >
              <div
                className="checklist__item-content"
                id="route-pickup-address"
              >
                <div className="visually-hidden">
                  {(orderStatus === 'delivered' ||
                    orderStatus === 'cancelled') ??
                    orderStatus}
                </div>
                {pickupAddress?.manual_location
                  ? `${parseFloat(pickupAddress.geo_lat)?.toFixed(
                      6
                    )}, ${parseFloat(pickupAddress.geo_lng)?.toFixed(
                      6
                    )} (near ${formattedAddressSingleLine(pickupAddress)})`
                  : formattedAddressSingleLine(pickupAddress)}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={`segment ${getDropOffClass()}`}>
        <div className="segment__icon">
          <img src={dropOffIcon} alt="" />
        </div>
        <div className="segment__content">
          <h3 className="mt-0 mb-2 text-xl inline-block">
            {getDropOffStatus()}
          </h3>
          {isInternalUser &&
            (orderStatus === 'delivered' ||
              orderStatus === 'cancelled' ||
              isDropOffLate) && (
              <>
                <Tooltip
                  text={`Initial SLA window: ${getDropOffSLAWindow()}`}
                  width={280}
                >
                  <img
                    src={clockIcon}
                    alt={`Initial SLA window: ${getDropOffSLAWindow()}`}
                    className="inline-block"
                    style={{ verticalAlign: -2 }}
                  />
                </Tooltip>
                {isDropOffSLAMissed && (
                  <span className="bg-red-400 text-white text-sm rounded-full px-3 ml-2 inline-block">
                    {differenceInMinutes(
                      new Date(deliveryCompletedTime),
                      new Date(deliveryDueTime)
                    )}{' '}
                    min late
                  </span>
                )}
              </>
            )}

          {schedule && (
            <div className="text-muted" id="route-drop-off-window">
              {isDropOffLate && orderStatus === 'en_route_to_drop_off' && (
                <Tooltip
                  text="It looks like your driver is running behind schedule. We have updated the estimated arrival times and apologize for any inconvenience."
                  placement="bottom-right"
                  className="ml-0 mr-2"
                  width={280}
                >
                  <img
                    src={warningIcon}
                    alt="Running Late"
                    className="w-6 inline-block"
                    style={{ verticalAlign: -2 }}
                  />
                </Tooltip>
              )}
              {getDropOffTimeRange()}
            </div>
          )}

          <ul className="checklist">
            {dropOffs.map(dropOff => (
              <li
                key={dropOff.id}
                className={`checklist__item ${
                  dropOff.status === 'cancelled'
                    ? 'checklist__item--false'
                    : dropOff.status === 'delivered'
                    ? 'checklist__item--true'
                    : ''
                } ${
                  dropOffs.length < 2 && dropOff.status !== 'cancelled'
                    ? 'checklist__item--invisible'
                    : ''
                }`}
              >
                <div className="visually-hidden">
                  {(dropOff.status === 'delivered' ||
                    dropOff.status === 'cancelled') ??
                    dropOff.status}
                </div>
                <div
                  className="checklist__item-content"
                  id="route-drop-off-address"
                >
                  {orderStatus === 'estimate'
                    ? `${dropOff.address.description}`
                    : dropOff.address?.manual_location
                    ? `${parseFloat(dropOff.address.geo_lat)?.toFixed(
                        6
                      )}, ${parseFloat(dropOff.address.geo_lng)?.toFixed(
                        6
                      )} (near ${formattedAddressSingleLine(dropOff.address)})`
                    : formattedAddressSingleLine(dropOff.address)}
                </div>
              </li>
            ))}
          </ul>
          {orderStatus === 'estimate' && dropOffs.length > 1 && (
            <p className="mt-8 mb-0 text-muted small">
              To optimize efficiency, drivers may deliver these in any order. If
              you need specific drop-off times, you can add details to the drop
              off notes or create separate orders.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

OrderTimeline.defaultProps = {
  shouldDisplayDriver: false
};

OrderTimeline.propTypes = {
  cancelledTime: PropTypes.string,
  deliveryArrivedTime: PropTypes.string,
  deliveryCompletedTime: PropTypes.string,
  deliveryDueTime: PropTypes.string,
  deliveryEstimatedTime: PropTypes.string,
  driver: PropTypes.string,
  dropOffs: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.object,
      status: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  isDropOffLate: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  isPickupLate: PropTypes.bool,
  orderStatus: PropTypes.oneOf([
    'estimate',
    'scheduled',
    'unassigned',
    'driver_assigned',
    'en_route_to_pickup',
    'at_pickup',
    'out_for_delivery',
    'en_route_to_drop_off',
    'at_drop_off',
    'delivered',
    'cancelled',
    'claimed'
  ]),
  pickedUpTime: PropTypes.string,
  pickupAddress: PropTypes.object,
  pickupArrivedTime: PropTypes.string,
  pickupEstimatedTime: PropTypes.string,
  schedule: PropTypes.shape({
    drive_time_max: PropTypes.number,
    drive_time_min: PropTypes.number,
    drop_off_window_end_at: PropTypes.string,
    drop_off_window_start_at: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pickup_load_time_minutes: PropTypes.number,
    pickup_window_end_at: PropTypes.string,
    pickup_window_start_at: PropTypes.string,
    service_type: PropTypes.string
  }),
  shouldDisplayDriver: PropTypes.bool,
  timeAvailable: PropTypes.string,
  notificationEnhancements: PropTypes.bool,
  useV2Pricing: PropTypes.bool
};

export default OrderTimeline;
