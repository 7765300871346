import React, { useState } from 'react';
import useUpdateEffect from '../src/hooks/useUpdateEffect';
import PropTypes from 'prop-types';
import FormRow from '../src/components/FormRow/FormRow';
import HiddenInput from '../src/components/HiddenInput/HiddenInput';
import OrganizationInput from '../src/components/OrganizationInput/OrganizationInput';
import BranchesInput from '../src/components/BranchesInput/BranchesInput';
import LoadingSpinner from '../src/components/LoadingSpinner/LoadingSpinner';

const FilterOrdersByBranch = props => {
  const {
    authenticityToken,
    isInternalUser,
    orgId,
    orgName,
    branchId,
    tree
  } = props;

  const [orgIdVal, setOrgIdVal] = useState(orgId?.toString());
  const [branchIdVal, setBranchIdVal] = useState(branchId?.toString() || '');
  const [orgTree, setOrgTree] = useState(tree);
  const [isLoading, setIsLoading] = useState(false);

  const handleOrgChange = id => {
    setOrgIdVal(id.toString());
  };

  const handleBranchChange = e => {
    setBranchIdVal(e.target.value);
  };

  useUpdateEffect(() => {
    if (!orgIdVal) return;

    setIsLoading(true);
    fetch(`/organizations/${orgIdVal}/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      credentials: 'include'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setOrgTree(data);
        setIsLoading(false);
      })
      .catch(() => {
        setOrgTree(null);
        setBranchIdVal(null);
        setIsLoading(false);
      });
  }, [orgIdVal]);

  return (
    <>
      <HiddenInput
        name="organization_filter"
        value={isInternalUser ? orgIdVal : branchIdVal}
      />
      {isInternalUser && (
        <FormRow>
          <OrganizationInput
            id="organization-search"
            label="Filter by organization"
            value={orgName}
            onSelect={handleOrgChange}
          />
        </FormRow>
      )}
      {isLoading && (
        <div className="mb-8">
          <LoadingSpinner />
        </div>
      )}
      {orgTree && (
        <FormRow>
          <BranchesInput
            className="col col-sm-8"
            id="sub_organization_filter"
            name="sub_organization_filter"
            label="Filter by branch"
            placeholder="Select a branch..."
            tree={orgTree}
            value={branchIdVal || ''}
            onChange={handleBranchChange}
          />
        </FormRow>
      )}
    </>
  );
};

FilterOrdersByBranch.propTypes = {
  authenticityToken: PropTypes.string,
  branchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInternalUser: PropTypes.bool,
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orgName: PropTypes.string,
  tree: PropTypes.object
};

export default FilterOrdersByBranch;
