import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../src/components/Modals/Modal';

function PricingCsvCustomModal({
  isOpen,
  onClose,
  header,
  text,
  errors,
  primaryBtnText = 'OK',
  primaryBtnOnClick,
  secondaryBtnText,
  secondaryBtnOnClick,
  children
}) {
  return (
    <Modal id="custom-modal" isOpen={isOpen} onClose={onClose}>
      <div className="p-10">
        <h3 className="cx_t cx_t--s cx_t--blue">{header}</h3>
        <br />
        <div className="cx_t cx_t--xs cx_l--margin-top-32 leading-relaxed">
          {text && (
            <div>
              {text}
              <br />
              <br />
            </div>
          )}
          {errors && (
            <ul className="list-disc pl-10">
              {[errors].flat().map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
          {children && <div>{children}</div>}
        </div>
        <div className="cx_l--flex cx_l--flex-align-center cx_l--flex-justify-end cx_l--margin-top-48">
          {secondaryBtnText && secondaryBtnOnClick && (
            <button
              onClick={secondaryBtnOnClick}
              className="cx_button cx_button--beta"
            >
              {secondaryBtnText}
            </button>
          )}
          <button
            onClick={primaryBtnOnClick}
            className={`cx_button cx_button--alpha ${
              secondaryBtnText && secondaryBtnOnClick ? 'ml-4' : ''
            }`}
          >
            {primaryBtnText}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PricingCsvCustomModal;

PricingCsvCustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  primaryBtnText: PropTypes.string,
  primaryBtnOnClick: PropTypes.func.isRequired,
  secondaryBtnText: PropTypes.string,
  secondaryBtnOnClick: PropTypes.func,
  children: PropTypes.node
};
