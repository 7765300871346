import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import createConsumer from '../../../channels/consumer';
import OrderStatus from '../OrderStatus/OrderStatus';
import OrderTimeline from '../OrderTimeline/OrderTimeline';
import GoogleMap from '../GoogleMap/GoogleMap';

const OrderWrapper = props => {
  const {
    channelName,
    dedicatedVehicleRequested,
    deliveryId,
    googleApiKey,
    isInternalUser,
    neededVehicleType,
    orderId,
    orderType,
    pickupAddress,
    pins,
    recipientContactPhoneNumber,
    recipientFacingName,
    shouldDisplayDriver,
    showHeader,
    showMap,
    showTraffic,
    notificationEnhancements,
    useContractualPricing,
    useV2Pricing
  } = props;

  const [orderData, setOrderData] = useState({
    cancelledTime: props.cancelledTime,
    deliveryArrivedTime: props.deliveryArrivedTime,
    deliveryCompletedTime: props.deliveryCompletedTime,
    deliveryDueTime: props.deliveryDueTime,
    deliveryEstimatedTime: props.deliveryEstimatedTime,
    driver: props.driver,
    dropOffs: props.dropOffs,
    isDropOffLate: props.isDropOffLate,
    isPickupLate: props.isPickupLate,
    orderStatus: props.orderStatus,
    orderStatusColor: props.orderStatusColor,
    orderStatusText: props.orderStatusText,
    orderTimeAvailable: props.orderTimeAvailable,
    pickedUpTime: props.pickedUpTime,
    pickupArrivedTime: props.pickupArrivedTime,
    pickupEstimatedTime: props.pickupEstimatedTime,
    schedule: props.schedule,
    scheduledInFuture: props.scheduledInFuture,
    timeAvailable: props.timeAvailable,
    useV2Pricing: props.useV2Pricing
  });

  useEffect(() => {
    const channel = createConsumer.subscriptions.create(
      {
        channel: channelName ?? 'OrderChannel',
        id: deliveryId ?? orderId
      },
      {
        received: data => {
          setOrderData({
            cancelledTime: data.cancelled_time,
            deliveryArrivedTime: data.delivery_arrived_time,
            deliveryCompletedTime: data.delivery_completed_time,
            deliveryDueTime: data.delivery_due_time,
            deliveryEstimatedTime: data.delivery_estimated_time,
            driver: data.driver,
            dropOffs: data.drop_offs,
            isDropOffLate: data.is_drop_off_late,
            isPickupLate: data.is_pickup_late,
            orderStatus: data.order_status,
            orderStatusColor: data.order_status_color,
            orderStatusText: data.order_status_text,
            orderTimeAvailable: data.order_time_available,
            pickedUpTime: data.picked_up_time,
            pickupArrivedTime: data.pickup_arrived_time,
            pickupEstimatedTime: data.pickup_estimated_time,
            schedule: data.schedule,
            scheduledInFuture: data.scheduled_in_future,
            timeAvailable: data.time_available
          });
        }
      }
    );

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const getDirectionPins = () => {
    if (orderType !== 'ASAP') return;
    if (!pins) return;

    const pickupPin = pins.find(pin => pin.type === 'pickup');
    const dropOffPin = pins.find(pin => pin.type === 'dropOff');
    const driverPin = pins.find(pin => pin.type === 'driver');

    if (!pickupPin || !dropOffPin || !driverPin) return;

    if (orderData.orderStatus === 'en_route_to_pickup') {
      return [driverPin.key, pickupPin.key, dropOffPin.key];
    } else if (
      ['at_pickup', 'out_for_delivery', 'en_route_to_drop_off'].includes(
        orderData.orderStatus
      )
    ) {
      return [driverPin.key, dropOffPin.key];
    } else return null;
  };

  return (
    <>
      {showHeader && (
        <OrderStatus
          dedicatedVehicleRequested={dedicatedVehicleRequested}
          neededVehicleType={neededVehicleType}
          orderStatusColor={orderData.orderStatusColor}
          orderStatusText={orderData.orderStatusText}
          orderTimeAvailable={orderData.orderTimeAvailable}
          orderType={orderType}
          recipientContactPhoneNumber={recipientContactPhoneNumber}
          recipientFacingName={recipientFacingName}
          scheduledInFuture={orderData.scheduledInFuture}
          isInternalUser={isInternalUser}
          useContractualPricing={useContractualPricing}
          useV2Pricing={useV2Pricing}
        />
      )}

      <div className="panel panel-default mb-0">
        <div className="panel-body">
          <div className="row">
            <div className={(showMap ? 'col-md-6' : '') + ' p-8 p-lg-12'}>
              <OrderTimeline
                cancelledTime={orderData.cancelledTime}
                deliveryArrivedTime={orderData.deliveryArrivedTime}
                deliveryCompletedTime={orderData.deliveryCompletedTime}
                deliveryDueTime={orderData.deliveryDueTime}
                deliveryEstimatedTime={orderData.deliveryEstimatedTime}
                driver={orderData.driver}
                dropOffs={orderData.dropOffs}
                isDropOffLate={orderData.isDropOffLate}
                isInternalUser={isInternalUser}
                isPickupLate={orderData.isPickupLate}
                orderStatus={orderData.orderStatus}
                pickedUpTime={orderData.pickedUpTime}
                pickupAddress={pickupAddress}
                pickupArrivedTime={orderData.pickupArrivedTime}
                pickupEstimatedTime={orderData.pickupEstimatedTime}
                schedule={orderData.schedule}
                shouldDisplayDriver={shouldDisplayDriver}
                timeAvailable={orderData.timeAvailable}
                notificationEnhancements={notificationEnhancements}
                useV2Pricing={useV2Pricing}
              />
            </div>
            {showMap && (
              <div className="col-md-6">
                <div className="embed-responsive embed-responsive-16by9">
                  <div className="embed-responsive-item">
                    <GoogleMap
                      googleApiKey={googleApiKey}
                      pins={pins}
                      showTraffic={showTraffic}
                      directionPins={getDirectionPins()}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

OrderWrapper.propTypes = {
  cancelledTime: PropTypes.string,
  channelName: PropTypes.string,
  dedicatedVehicleRequested: PropTypes.bool,
  deliveryArrivedTime: PropTypes.string,
  deliveryCompletedTime: PropTypes.string,
  deliveryDueTime: PropTypes.string,
  deliveryEstimatedTime: PropTypes.string,
  deliveryId: PropTypes.number,
  driver: PropTypes.string,
  dropOffs: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.object,
      status: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  googleApiKey: PropTypes.string,
  isDropOffLate: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  isPickupLate: PropTypes.bool,
  neededVehicleType: PropTypes.string,
  orderId: PropTypes.number,
  orderStatus: PropTypes.oneOf([
    'estimate',
    'scheduled',
    'unassigned',
    'driver_assigned',
    'en_route_to_pickup',
    'at_pickup',
    'out_for_delivery',
    'en_route_to_drop_off',
    'at_drop_off',
    'delivered',
    'cancelled',
    'claimed'
  ]),
  orderStatusColor: PropTypes.string,
  orderStatusText: PropTypes.string,
  orderTimeAvailable: PropTypes.string,
  orderType: PropTypes.string,
  pickedUpTime: PropTypes.string,
  pickupAddress: PropTypes.object,
  pickupArrivedTime: PropTypes.string,
  pickupEstimatedTime: PropTypes.string,
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      lat: PropTypes.any,
      lng: PropTypes.any,
      type: PropTypes.string
    })
  ),
  recipientContactPhoneNumber: PropTypes.string,
  recipientFacingName: PropTypes.string,
  schedule: PropTypes.shape({
    drive_time_max: PropTypes.number,
    drive_time_min: PropTypes.number,
    drop_off_window_end_at: PropTypes.string,
    drop_off_window_start_at: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pickup_load_time_minutes: PropTypes.number,
    pickup_window_end_at: PropTypes.string,
    pickup_window_start_at: PropTypes.string,
    service_type: PropTypes.string
  }),
  scheduledInFuture: PropTypes.bool,
  shouldDisplayDriver: PropTypes.bool,
  showHeader: PropTypes.bool,
  showMap: PropTypes.bool,
  showTraffic: PropTypes.bool,
  timeAvailable: PropTypes.string,
  notificationEnhancements: PropTypes.bool,
  useContractualPricing: PropTypes.bool,
  useV2Pricing: PropTypes.bool
};

export default OrderWrapper;
