import React from 'react';
import Chip from '../../components/Chip/Chip';
import PropTypes from 'prop-types';

const FeedbackSelection = ({
  subHeaderText,
  selectedFeedbacks,
  setSelectedFeedbacks,
  feedbackOptions
}) => {
  const toggleFeedback = feedback => {
    setSelectedFeedbacks(prevSelected =>
      prevSelected.includes(feedback)
        ? prevSelected.filter(item => item !== feedback)
        : [...prevSelected, feedback]
    );
  };

  return (
    <div className="flex justify-center mt-6">
      <div className="w-[320px] border-t-[1px] border-b-[1px] border-navy pt-3 pb-12">
        <div className="font-sans text-base font-bold text-center text-navy mt-6">
          {subHeaderText}
        </div>
        <div className="text-base text-center text-navy mb-6">
          (Check all that apply)
        </div>
        <div className="flex flex-col items-center">
          <div className="flex flex-wrap gap-4 justify-center">
            {feedbackOptions.map((feedback, index) => (
              <Chip
                key={index}
                label={feedback}
                isSelected={selectedFeedbacks.includes(feedback)}
                onClick={() => toggleFeedback(feedback)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

FeedbackSelection.propTypes = {
  subHeaderText: PropTypes.string.isRequired,
  feedbackOptions: PropTypes.array.isRequired,
  selectedFeedbacks: PropTypes.array.isRequired,
  setSelectedFeedbacks: PropTypes.func.isRequired
};

export default FeedbackSelection;
