import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../src/components/Button/Button';
import MarketplacePricingTable from './MarketplacePricingTable';
import Icon from '../src/components/Icon';

const MarketplaceMultipliersTable = props => {
  const { currentRates, marketsWithWarnings, pricingData } = props;

  const [showTable, setShowTable] = useState({});

  const togglePricingTable = key => {
    setShowTable(prevState => ({
      ...prevState,
      [key]: showTable[key] ? !showTable[key] : true
    }));
  };

  return (
    <div>
      <table className="table pretty table-hover">
        <thead>
          <tr>
            {[
              'Market',
              'Customer Multiplier',
              'Driver Multiplier',
              <span
                key="view-button"
                className="glyphicon glyphicon-modal-window"
              ></span>
            ].map((key, index) => {
              return (
                <th style={{ borderBottom: '3px solid #ddd' }} key={index}>
                  {key}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.keys(pricingData).map((key, index) => {
            return (
              <Fragment key={index}>
                <tr style={{ maxWidth: '100%' }}>
                  <td
                    className="py-3"
                    style={{ borderBottom: '1px solid #ddd' }}
                  >
                    {marketsWithWarnings?.some(
                      market => market.customer_multiplier[0] === key
                    ) ? (
                      <div className="flex gap-5 items-center">
                        <Icon name="alertCircle" size={20} fill="none" />
                        <div>{key}</div>
                      </div>
                    ) : (
                      key
                    )}
                  </td>
                  <td
                    className="py-3"
                    style={{ borderBottom: '1px solid #ddd' }}
                  >
                    {pricingData[key]['Customer Multiplier']}
                  </td>
                  <td
                    className="py-3"
                    style={{ borderBottom: '1px solid #ddd' }}
                  >
                    {pricingData[key]['Driver Multiplier']}
                  </td>
                  <td
                    className="py-3"
                    style={{ borderBottom: '1px solid #ddd' }}
                  >
                    <Button
                      style="default"
                      size="sm"
                      onClick={() => togglePricingTable(key)}
                    >
                      View
                    </Button>
                  </td>
                </tr>
                <tr className={showTable[key] ? '' : 'hidden'}>
                  <td colSpan={4}>
                    <div style={{ overflowX: 'scroll', display: 'block' }}>
                      <MarketplacePricingTable
                        key={key}
                        customerMultiplier={
                          pricingData[key]['Customer Multiplier']
                        }
                        driverMultiplier={pricingData[key]['Driver Multiplier']}
                        pricingData={currentRates}
                      />
                    </div>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

MarketplaceMultipliersTable.propTypes = {
  currentRates: PropTypes.object,
  marketsWithWarnings: PropTypes.arrayOf(PropTypes.object),
  pricingData: PropTypes.object
};

export default MarketplaceMultipliersTable;
